import React, { useState, useEffect } from 'react'
import profile from '../images/profile.png'
import user from '../images/usershield.svg'
import { Link, useNavigate } from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import { Cookies } from 'react-cookie';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import g20 from '../images/g20.png'
import meity from '../images/meity.png'
import * as utils from '../Util'
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from '@mui/material';
import config from '../config.json'

import axios from 'axios';

const cookies = new Cookies();

const BASE_URL = config.SERVER_URL;


export default function NavbarUser(props) {

  const [fullname, setFullname] = useState("")
  const [userrole, setUserrole] = useState("")

  const [opensnack, setopensnack] = useState(false)
  const [snackmsg, setsnackmsg] = useState('')

  const [backdropopen, setbackdropopen] = useState(false)
  const [opensubmitdialog, setopensubmitdialog] = useState(false)



  useEffect(() => {
    let t1 = sessionStorage.getItem("sessionid")

    //console.log("t1 from navbar", t1)

    if (t1 != undefined) {

      var name = utils.getFullNameFromToken(t1)

      setFullname(name)

      var role = utils.getRoleNameFromToken(t1)

      setUserrole(role)
    }
  }, [])

  const handleCloseSnack = (event, reason) => {

    if (reason === 'clickaway') {
      return;
    }

    setopensnack(false);

  }

  const navigate = useNavigate();

  const handleClick = () => {
    setopensubmitdialog(true)
  }

  const handleCancelButtonModal = () => {
    setopensubmitdialog(false)
  }

  const handleSubmitbuttonModal = (e) => {
    e.preventDefault();

    setbackdropopen(true)


    axios.post(
      BASE_URL + "/logoutUser",
      {}, { withCredentials: true }
    )
      .then(response => {
        if (response.status == 200) {

          setbackdropopen(false)

          setsnackmsg("Logged Out Successfully")
          setopensnack(true)


          cookies.remove("sessionid")
          sessionStorage.removeItem("sessionid")
          navigate('/')
        } else {
          setbackdropopen(false)
          setsnackmsg("Something Went Wrong")
          setopensnack(true)


        }



      })
      .catch(error => {
        setbackdropopen(false)
        setsnackmsg("Something Went Wrong")
        setopensnack(true)

      });
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg ">

        <div className="container-fluid">

          <a className="navbar-brand ms-5" href="#">{props.title}</a>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar-nav" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto pe-3">
              <li className="nav-item active">
                {/* <Link className="nav-link " aria-current="page" to="/">Homee</Link> */}
                <a className="navbar-brand ms-2" href="#">Welcome: {fullname}</a>
              </li>
              <li className="nav-item active">
                {/* <Link className="nav-link " aria-current="page" to="/">Homee</Link> */}
                <a className="navbar-brand ms-2" href="#">|</a>
              </li>
              <li className="nav-item">
                {/* <Link className="nav-link " to="/about">Aboutt</Link> */}
                <a className="navbar-brand ms-2" href="#">{userrole}</a>
              </li>
              <li className="nav-item dropdown " data-bs-toggle="dropdown" id="navbarDropdown">
                <a className="nav-link" href="#" data-toggle="dropdown" aria-labelledby="navbarDropdown" aria-expanded="false">
                  <img src={user} style={{ width: '30px', alignItems: "center" }}></img>
                  {/* <i class="fas fa-user-shield"></i> */}
                  {/* <FontAwesomeIcon icon="fas fa-user-shield" /> */}

                </a>
                <div className="center dropdown-menu dropdown-menu-lg dropdown-menu-end" >
                  <div className="center1">
                    {/* <img src={profile} style={{ width: "200px" }} alt="profile" /> */}
                    <Avatar
                      src={profile}
                      sx={{ width: 100, height: 100 }}
                      variant="circle"
                      style={{ alignItems: 'center' }} />
                  </div>
                  {/* <a href="/collector_profile/" className="dropdown-item " style={{ alignItems: "center" }}>
                                        <FontAwesomeIcon icon={faUserEdit} /> Update Profile
                                    </a> */}
                  <a href="/logout" className="dropdown-item" onClick={handleClick}>
                    <PowerSettingsNewIcon /> Logout
                  </a>

                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <nav className="user navbar navbar-expand-lg  text-center p-3">
        <div className="container-fluid ">
          <a href="https://www.meity.gov.in/" target="_blank">
            <img className='responsive ' style={{ marginRight: 5, cursor: 'pointer' }} src={meity} alt="meity logo"></img>
          </a>

          <div className="collapse navbar-collapse " id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/userdashboard"><strong>Dashboard</strong></Link>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" aria-expanded="false" >
                  <strong>Design Management</strong>
                </a>
                <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                  <li><Link className="dropdown-item" to="/uploadmotif">Upload Design</Link></li>
                  <li><Link className="dropdown-item" to="/viewusermotifs">View Uploaded Design Status</Link></li>
                  {/* <li><Link className="dropdown-item" to="">Search Design</Link></li> */}
                  {/* <li><Link className="dropdown-item" to="/viewmapBeneficiary">Map Beneficiary</Link></li> */}
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" aria-expanded="false" >
                  <strong>Motif Management</strong>
                </a>
                <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                  <li><Link className="dropdown-item" to="/uploadmotifnew">Upload Motif</Link></li>
                  <li><Link className="dropdown-item" to="/viewusermotifsnew">View Uploaded Motif Status</Link></li>
                  {/* <li><Link className="dropdown-item" to="">Search Motif</Link></li> */}
                  {/* <li><Link className="dropdown-item" to="/viewmapBeneficiary">Map Beneficiary</Link></li> */}
                </ul>
              </li>
              {/* <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" aria-expanded="false">
                  User and Role Management
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><Link className="dropdown-item" to="">User Management (Sub Admin)</Link></li>
                  <li><Link className="dropdown-item" to="">User Management (Weaver/ Artisan)</Link></li>

                </ul>
              </li> */}

              {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" aria-expanded="false">
                                    Mapping
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item" to="/batchcourseMap">Map Batches and Courses</Link></li>
                                    <li><Link className="dropdown-item" to="/batchsessioncenterMap">Map Center Session and Batch</Link></li>
                                </ul>
                            </li> */}
              {/* <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" aria-expanded="false">
                  CMS
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><Link className="dropdown-item" to="">Gallery Management</Link></li>
                  <li><Link className="dropdown-item" to="">News/ Notice Management</Link></li>

                </ul>
              </li> */}
            </ul>
          </div>
          <a href="https://g20.mygov.in/" target="_blank">
            <img className='responsive ' style={{ marginLeft: 5 }} src={g20} alt="g20 logo"></img>
          </a>
        </div>
      </nav>

      <Dialog open={opensubmitdialog}>
        <DialogTitle>Alert!!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to Logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitbuttonModal}>Yes</Button>
          <Button onClick={handleCancelButtonModal}>Cancel</Button>
        </DialogActions>
        <Backdrop
          sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropopen}
        //onClick={handlebackdropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      {/* Submit Confirmation dialog end */}


      <Snackbar
        open={opensnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message={snackmsg}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      //action={action}
      />
      
    </>
  )
}
