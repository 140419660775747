import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import config from '../../config.json'
import NavBarSuperAdmin from '../../NavBar/NavBarSuperAdmin'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import { decrypter } from "../../Util"
import regex from "../../regex.json"

import Banner1 from '../../images/banner1.jpg'

import axios from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
    DataGrid, GridToolbar,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { Grid } from "@mui/material";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import TextField from "@mui/material/TextField";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { makeStyles } from "@mui/styles";



const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;
const SECRET = config.SECRET;

const style2 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
}

const useStyle = makeStyles({
    root: {
        "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center",
            fontWeight: 'bold'
        },
        "& .MuiDataGrid-cellContent": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center"
        },
        "& .MuiTypography-string": {
            textOverflow: "ellipsis",
            whiteSpace: "break-spaces",
            lineHeight: 2,
            textAlign: "center"
        },
        boxShadow: 2,
        '& .MuiTablePagination-selectLabel': {
            marginTop: 'auto'
        },
        '& .MuiTablePagination-displayedRows': {
            marginTop: 'auto'
        }
    }
});

export default function ManageStates() {

    const navigate = useNavigate()
    // let cookiedata = decrypter(cookies.get("sessionid"))


    // let userdata = cookiedata.userdata
    // let jwt = cookiedata.token

    // let role = cookiedata.userdata.user.roles.map(op => op.role_name)

    // let userrole = role[0]

    // let KEY = 'Bearer ' + jwt;

    var count = 0;

    const classes = useStyle()

    const [states, setstates] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [opensnack, setopensnack] = useState(false);
    const [snackmsg, setsnackmsg] = useState();
    const [addstatemodal, setaddstatemodal] = useState(false)
    const [backdrop, setbackdrop] = useState(false)
    const [editstatemodal, seteditstatemodal] = useState(false)
    const [opendeletedialog, setopendeletedialog] = useState(false)
    const [opensessiondialog, setopensessiondialog] = useState(false)
    const [refreshdata, setrefreshdata] = useState(0)

    const [stateid, setstateid] = useState("")
    const [statecode, setstatecode] = useState("")
    const [errstatecode, seterrstatecode] = useState("")
    const [errstatecodeb, seterrstatecodeb] = useState(false)

    const [statename, setstatename] = useState("")
    const [errstatename, seterrstatename] = useState("")
    const [errstatenameb, seterrstatenameb] = useState(false)

    const [statechangetrigger, setstatechangetrigger] = useState(false)
    const [codechangetrigger, setcodechangetrigger] = useState(false)

    const [sc, setsc] = useState("")
    const [sn, setsn] = useState("")





    const columns = [
        {
            field: 'state_id', headerName: 'ID', flex: 0.5, align: 'center', headerAlign: 'center'
        },
        {
            field: 'state_code', headerName: 'State Code', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'state_name', headerName: 'State Name', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: "Action",
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleEditClick(event, cellValues);
                            }}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleDeleteClick(event, cellValues);
                            }}
                        >
                            <DeleteIcon></DeleteIcon>
                        </IconButton>
                        {/* <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleFormEditOpen(event, cellValues.row);
                            }}
                        >
                            <FileOpenIcon></FileOpenIcon>
                        </IconButton> */}
                    </>
                );
            }, flex: 1, align: 'center', headerAlign: 'center'
        }

    ]

    useEffect(() => {

        axios.post(BASE_URL + '/getAllStates', {}, { withCredentials: true })
            .then(function (responses) {

                ////console.log("States", responses.data.states)
                //setunmapped(responses.data.Beneficiaries)
                setstates(responses.data.states)

            })
            .catch(function (errors) {

            })

        setrefreshdata(0)

    }, [refreshdata])


    useEffect(() => {

        setstatechangetrigger(true)

    }, [statename])

    useEffect(() => {

        setcodechangetrigger(true)

    }, [statecode])



    const handleAddState = (e) => {
        setstatecode("")
        seterrstatecode("")
        seterrstatecodeb(false)

        setstatename("")
        seterrstatename("")
        seterrstatenameb(false)

        setaddstatemodal(true)

    }

    const handleEditClick = (event, cellValues) => {

        setstateid(cellValues.row.state_id)
        setstatecode(cellValues.row.state_code)
        setstatename(cellValues.row.state_name)

        setsc(cellValues.row.state_code)
        setsn(cellValues.row.state_name)



        seteditstatemodal(true)


    }

    const handleDeleteClick = (event, cellValues) => {
        setopendeletedialog(true)
        setstateid(cellValues.row.state_id);

    }

    const handleCloseSnack = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setopensnack(false);

    }

    const handleaddstatemodal = () => {
        setaddstatemodal(false)
    }

    const handleeditstatemodal = () => {
        seteditstatemodal(false)
    }

    const handledeletedialogclose = () => {
        setopendeletedialog(false)
    }

    const handleOKButton = () => {
        navigate('/')
        cookies.remove("sessionid")
    }

    const handleaddstatebuttonclick = (e) => {

        e.preventDefault();
        if (validation()) {

            // const headers = {
            //     Authorization: KEY
            // };


            setbackdrop(true)

            const params = new URLSearchParams();
            params.append("state_name", statename)
            params.append("state_code", statecode)

            axios
                .post(BASE_URL + "/duplicateState",
                    params,
                    { withCredentials: true })
                .then(function (response) {
                    ////console.log(response.data);
                    if (response.status == 200) {


                        ////console.log("hi")


                        if (response.data.statedup > 0 || response.data.codedup > 0) {

                            setbackdrop(false)


                            if (response.data.statedup > 0) {

                                seterrstatename("State Name Already Exists")
                                seterrstatenameb(true)


                            }

                            if (response.data.codedup > 0) {

                                seterrstatecode("State Code Already Exists")
                                seterrstatecodeb(true)


                            }

                        } else {

                            axios
                                .post(BASE_URL + "/addState",
                                    {
                                        state_code: statecode,
                                        state_name: statename
                                    },
                                    { withCredentials: true })
                                .then(function (response) {
                                    ////console.log(response);
                                    if (response.data.status === "ok" && response.status == 200) {
                                        setbackdrop(false)
                                        setsnackmsg("State Added Successfully")
                                        setopensnack(true);
                                        setaddstatemodal(false)

                                        // setTimeout(function () {
                                        //     window.location.reload(false);
                                        // }, 3000);

                                        setrefreshdata(count + 1)

                                    }
                                    else {
                                        setbackdrop(false)

                                        setsnackmsg("State Addition Failed")
                                        setopensnack(true);
                                    }
                                })
                                .catch(function (errors) {



                                    if (errors.response.status == 401 || errors.response.status == 403) {


                                        setaddstatemodal(false)

                                        setopensessiondialog(true)



                                    } else {

                                        setbackdrop(false)
                                        setsnackmsg("State Addition Failed")
                                        setopensnack(true);

                                    }

                                });





                        }




                    }
                    else {

                    }
                })
                .catch(function (errors) {

                    if (errors.response.status == 401 || errors.response.status == 403) {

                        setaddstatemodal(false)
                        setopensessiondialog(true)

                    } else {

                        setbackdrop(false)
                        setsnackmsg("State Addition Failed")
                        setopensnack(true);

                    }


                });


        }



    }

    const handleYesButton = () => {

        // const headers = {
        //     Authorization: KEY
        // };


        axios
            .post(BASE_URL + "/deleteState", { state_id: stateid }, { withCredentials: true })
            .then(function (response) {

                ////console.log(response);
                if (response.data.status === "ok" && response.status == 200) {
                    //setbackdrop(false)
                    setsnackmsg("State Deleted Successfully")
                    setopensnack(true);
                    setopendeletedialog(false)
                    //setaddproofmodal(false)

                    // setTimeout(function () {
                    //     window.location.reload(false);
                    // }, 3000);
                    setrefreshdata(count + 1)

                }
                else {
                    //setbackdrop(false)


                    setsnackmsg("State Delete Failed")
                    setopensnack(true);
                }
            })
            .catch(function (errors) {
                //setbackdrop(false)

                if (errors.response.status == 401) {

                    setaddstatemodal(false)
                    setopensessiondialog(true)

                } else {

                    setsnackmsg("State Delete Failed")
                    setopensnack(true);

                }



            });

    }

    const handleNoButton = () => {
        setopendeletedialog(false)
    }

    const handleeditstatebuttonclick = (e) => {
        e.preventDefault();

        if (validation()) {

            // const headers = {
            //     Authorization: KEY
            // };


            setbackdrop(true)




            axios
                .post(BASE_URL + "/editState",
                    {
                        state_id: stateid,
                        state_code: statecode,
                        state_name: statename
                    },
                    { withCredentials: true })
                .then(function (response) {
                    ////console.log(response);
                    if (response.data.status === "ok" && response.status == 200) {
                        setbackdrop(false)
                        setsnackmsg("State Edited Successfully")
                        setopensnack(true);
                        seteditstatemodal(false)

                        // setTimeout(function () {
                        //     window.location.reload(false);
                        // }, 3000);

                        setrefreshdata(count + 1)

                    }
                    else {
                        setbackdrop(false)

                        setsnackmsg("State Edit Failed")
                        setopensnack(true);
                    }
                })
                .catch(function (errors) {
                    if (errors.response.status == 401) {

                        setaddstatemodal(false)
                        setopensessiondialog(true)

                    } else {

                        setbackdrop(false)
                        setsnackmsg("State Edit Failed")
                        setopensnack(true);

                    }
                });






        }
    }

    function validation() {
        let isvalid = true;

        if (statecode === "") {
            seterrstatecode("State Code Cannot be Empty")
            seterrstatecodeb(true)
            isvalid = false;
        } else if (!statecode.match(regex.letterandspace)) {
            seterrstatecode("Please Enter Valid State Code")
            seterrstatecodeb(true)
            isvalid = false;

        } else {
            seterrstatecode("")
            seterrstatecodeb(false)
        }

        if (statename === "") {
            seterrstatename("State Name Cannot be Empty")
            seterrstatenameb(true)
            isvalid = false
        } else if (!statename.match(regex.letterandspace)) {
            seterrstatename("Please Enter Valid State Name")
            seterrstatenameb(true)
            isvalid = false
        } else {
            seterrstatename("")
            seterrstatenameb(false)

        }

        return isvalid;
    }


    return (
        <>
            <div>
                <NavBarSuperAdmin
                    title={TITLE}

                />
            </div>
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={Banner1} className="d-block w-100" alt="Banner1" />
                            </div>
                        </div>
                        {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button> */}
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="row mx-5 mb-5">
                <div className="container">
                    <h1>
                        <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<AddIcon></AddIcon>} onClick={handleAddState} >Add New State</Button>
                        <div className="abc text-black text-center col 10 p-1 mt-1">
                            <strong><h2> Manage States </h2></strong>
                        </div>
                    </h1>
                    <Box
                        justifyContent='center'
                    //</div>sx={{ height: 700, width: '100%' }}
                    >
                        <DataGrid

                            className={classes.root}
                            sx={{
                                boxShadow: 2,
                                '& .MuiTablePagination-selectLabel': {
                                    marginTop: 'auto'
                                },
                                '& .MuiTablePagination-displayedRows': {
                                    marginTop: 'auto'
                                }
                            }}
                            rows={states}
                            getRowId={(r) => r.state_id}
                            columns={columns}
                            components={{
                                Toolbar: GridToolbar,
                                //Pagination: CustomPagination
                            }}
                            disableSelectionOnClick
                            autoHeight
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            pagination
                            rowsPerPageOptions={[10, 25, 50, 100]}

                        //checkboxSelection={false}
                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setSelectedRows(newSelectionModel);
                        // }}

                        />
                    </Box>
                    <Snackbar
                        open={opensnack}
                        autoHideDuration={6000}
                        onClose={handleCloseSnack}
                        message={snackmsg}
                    //action={action}
                    />
                    {/* add state modal */}
                    <Modal
                        open={addstatemodal}
                        onClose={handleaddstatemodal}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        <Box sx={style2}>
                            <Grid container spacing={1}>

                                <Grid item xs={4}>

                                    <Card sx={{ width: 500, alignItems: 'center', boxShadow: 10, borderRadius: 3, padding: 2 }}>
                                        <h1>
                                            <div className="abc text-black text-center col 10 p-1 mt-1">
                                                <strong><h2> Add New State </h2></strong>
                                            </div>
                                        </h1>
                                        <div>
                                            <TextField
                                                error={errstatecodeb}
                                                helperText={errstatecode}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="outlined-name"
                                                label="Enter State Code"
                                                //defaultValue={userdata.state_name}
                                                value={statecode}
                                                onChange={(e) => setstatecode(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                error={errstatenameb}
                                                helperText={errstatename}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="outlined-name"
                                                label="Enter State Name"
                                                //defaultValue={userdata.state_name}
                                                value={statename}
                                                onChange={(e) => setstatename(e.target.value)}
                                            />
                                        </div>
                                        <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
                                            <Button variant="contained" onClick={handleaddstatebuttonclick}>
                                                Submit
                                            </Button>
                                        </Grid>

                                        <Backdrop
                                            sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                            open={backdrop}
                                        //onClick={handlebackdropClose}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>

                                    </Card>

                                </Grid>

                            </Grid>

                        </Box>

                    </Modal>
                    {/* add state modal end */}
                    {/* edit state modal */}
                    <Modal
                        open={editstatemodal}
                        onClose={handleeditstatemodal}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description">

                        <Box sx={style2}>
                            <Grid container spacing={1}>

                                <Grid item xs={4}>

                                    <Card sx={{ width: 500, alignItems: 'center', boxShadow: 10, borderRadius: 3, padding: 2 }}>
                                        <h1>
                                            <div className="abc text-black text-center col 10 p-1 mt-1">
                                                <strong><h2> Edit State </h2></strong>
                                            </div>
                                        </h1>
                                        <div>
                                            <TextField

                                                margin="normal"
                                                disabled
                                                fullWidth
                                                id="outlined-name"
                                                label="State ID"
                                                //defaultValue={userdata.state_name}
                                                value={stateid}
                                            //onChange={(e) => setproofname(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                error={errstatecodeb}
                                                helperText={errstatecode}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="outlined-name"
                                                label="State Code"
                                                //defaultValue={userdata.state_name}
                                                value={statecode}
                                                onChange={(e) => setstatecode(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                error={errstatenameb}
                                                helperText={errstatename}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="outlined-name"
                                                label="State Name"
                                                //defaultValue={userdata.state_name}
                                                value={statename}
                                                onChange={(e) => setstatename(e.target.value)}
                                            />
                                        </div>
                                        <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
                                            <Button variant="contained" onClick={handleeditstatebuttonclick}>
                                                Submit
                                            </Button>
                                        </Grid>

                                        <Backdrop
                                            sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                            open={backdrop}
                                        //onClick={handlebackdropClose}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>

                                    </Card>

                                </Grid>

                            </Grid>

                        </Box>


                    </Modal>
                    {/* edit state modal end */}
                    {/* delete dialog */}
                    <Dialog
                        open={opendeletedialog}
                        onClose={handledeletedialogclose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"ARE YOU SURE YOU WANT TO DELETE?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Once deleted it cannot be undone
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleYesButton}>YES</Button>
                            <Button onClick={handleNoButton} autoFocus>
                                NO
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* delete dialog end */}
                    {/* session expiration dialog */}
                    <Dialog
                        open={opensessiondialog}

                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Session Expired!!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Session has expired..Please Login Again to Continue
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleOKButton}>OK</Button>
                        </DialogActions>
                    </Dialog>
                    {/* session expiration dialog end */}

                </div>
            </div>

        </>
    )
}
