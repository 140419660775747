import React, { useState, useEffect, Fragment, useRef } from "react";
import LoginBanner from "./images/login.jpg";
import NavBarHome from "./NavBar/NavBarHome";
import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import config from "./config.json";
import {
    Routes,
    Route,
    useNavigate,
    Navigate,
    useParams,
} from "react-router-dom";
import { Cookies } from "react-cookie";
import HomePage from "./Home/HomePage";
import Feedback from "./Home/Feedback";
import News from "./Home/News";
import Objective from "./Home/Objective";
import Register from "./Home/Register";
import SADashboard from "./Super Admin/SADashboard";
import UserDashboard from "./User/UserDashboard";
import SADashboard2 from "./Super Admin/SADashboard2";
import regex from "./regex.json";

//import makeStyles from '@mui/styles/makeStyles';

import { decrypter } from "./Util";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import { Grid, Snackbar } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { VisibilityOff, Visibility, Close } from "@mui/icons-material";


import UploadMotif from "./User/UploadMotif";
import ManageProofs from "./Super Admin/Master Management/ManageProofs";
import ManageStates from "./Super Admin/Master Management/ManageStates";
import ManageDistrict from "./Super Admin/Master Management/ManageDistrict";
import ViewUserMotifs from "./User/ViewUserMotifs";
import SideBarSuperAdmin from "./SideBar/SideBarSuperAdmin";
import SubDashboard from "./Sub Admin/SubDashboard";

import GlobalStyles from "@mui/material/GlobalStyles";
// Material UI
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ViewMotifsSubAdmin from "./Sub Admin/Motifs/ViewMotifsSubAdmin";
import ApprovedMotifsSubAdmin from "./Sub Admin/Motifs/ApprovedMotifsSubAdmin";
import RejectedMotifsSubAdmin from "./Sub Admin/Motifs/RejectedMotifsSubAdmin";
import ManageProductCategory from "./Super Admin/Master Management/ManageProductCategory";
import ManageProductSubcategory from "./Super Admin/Master Management/ManageProductSubcategory";
import UploadMotifNew from "./User/UploadMotifNew";
import ViewUserMotifsNew from "./User/ViewUserMotifsNew";
import ViewDesignSubAdmin from "./Sub Admin/Designs/ViewDesignSubAdmin";
import ApprovedDesignsSubAdmin from "./Sub Admin/Designs/ApprovedDesignsSubAdmin";
import RejectedDesignsSubAdmin from "./Sub Admin/Designs/RejectedDesignsSubAdmin";
import MeityDashboard from "./Meity/MeityDashboard";
import ViewMotifMeity from "./Meity/ViewMotifMeity";
import ViewDesignMeity from "./Meity/ViewDesignMeity";

import RefreshIcon from "@mui/icons-material/Refresh";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ManageUser from "./Super Admin/UserManagement/ManageUser";
import ManageEmployee from "./Super Admin/UserManagement/ManageEmployee";
import ViewDesignSuperAdmin from "./Super Admin/Motifs/ViewDesignSuperAdmin";
import ViewMotifSuperAdmin from "./Super Admin/Motifs/ViewMotifSuperAdmin";

import * as utils from './Util'
import { el } from "date-fns/locale";
import { useIdleTimer } from 'react-idle-timer'
import IdleTimeoutManager from "./IdleTimeoutManager ";
import { forwardRef } from "react";


const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const SECRET = config.SECRET;
const TITLE = config.TITLE;
var CryptoJS = require("crypto-js");

const THEME = createTheme({
    typography: {
        fontFamily: `'Ubuntu', sans-serif`,

        fontWeightRegular: 400,
    },
});

export default function Login() {
    const navigate = useNavigate();

    const [value, setValue] = useState("1");
    const [alertopen, setalertopen] = useState(false);
    const [alertmsg, setalertmsg] = useState();
    const [showPassword, setshowPassword] = useState(false);

    const [showPassword2, setshowPassword2] = useState(false);

    const [emailemp, setemailemp] = useState("");
    const [errmailempb, seterrmailempb] = useState(false);
    const [errmailemp, seterrmailemp] = useState();

    const [passwordemp, setpasswordemp] = useState("");
    const [errpassempb, seterrpassempb] = useState(false);
    const [errpassemp, seterrpassemp] = useState();

    const [emailuser, setemailuser] = useState("");
    const [errmailuserb, seterrmailuserb] = useState(false);
    const [errmailuser, seterrmailuser] = useState();

    const [passworduser, setpassworduser] = useState("");
    const [errpassuserb, seterrpassuserb] = useState(false);
    const [errpassuser, seterrpassuser] = useState();



    const [errcaptchaempb, seterrcaptchaempb] = useState(false);
    const [errcaptchaemp, seterrcaptchaemp] = useState();

    const [backdropemp, setbackdropemp] = useState(false);

    const canvasRef = useRef(null);

    const [openforgotdialog, setopenforgotdialog] = useState(false);
    const [errforgotemailb, seterrforgotemailb] = useState(false);
    const [errforgotemail, seterrforgotemail] = useState("");
    const [forgotemail, setforgotemail] = useState("");
    const [forgotbackdrop, setforgotbackdrop] = useState(false);

    const [backdropdialog, setbackdropdialog] = useState(false);
    const [opensuccessdialog, setopensuccessdialog] = useState(false);

    const [successdialogtitle, setsuccessdialogtitle] = useState("");
    const [successdialogcontent, setsuccessdialogcontent] = useState("");

    const [captchaimage, setcaptchaimage] = useState('')
    const [captchajwt, setcaptchajwt] = useState('')

    const [getcaptchaTextemp, setgetcaptchaTextemp] = useState('')

    const inputRef = useRef(null);



    useEffect(() => {

        utils.PrintLog("abc", "def")

        genCaptcha()

        // Set up an interval to call genCaptcha every 1 minute (60000 milliseconds)
        const intervalId = setInterval(() => {
            genCaptcha();
        }, 60000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [])




    const genCaptcha = () => {

        axios
            .post(
                BASE_URL + "/genCaptcha",
                {},
                { withCredentials: true }
            )
            .then(function (response) {
                setcaptchaimage(`data:image/png;base64,${response.data.Base64}`)
                setcaptchajwt(response.data.CaptchaId)
            })
            .catch(function (error) {

            });
    }

    const reloadButton = () => {
        genCaptcha()
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleloginempClick = () => {
        if (validation()) {
            genCaptcha()
            setbackdropemp(true);
            const md5 = require("md5");

            const headers = {
                Authorization: captchajwt,
            };

            axios
                .post(
                    BASE_URL + "/authenticate",
                    {
                        username: emailemp,
                        password: passwordemp,
                        captcha: getcaptchaTextemp
                    },
                    {
                        headers: headers,
                        withCredentials: true
                    }
                )
                .then(function (response) {
                    //console.log("response ", response)
                    if (response.status == 200) {
                        const headers = {
                            Token: response.data.jwtToken,
                        };

                        axios
                            .post(
                                BASE_URL + "/validateEmployeeLoginToken",
                                {},
                                {
                                    headers: headers,
                                    withCredentials: true,
                                }
                            )
                            .then(function (responses) {

                                //console.log("resp on validate token", responses)

                                if (responses.status == 200) {
                                    sessionStorage.setItem('sessionid', responses.headers.authtoken)

                                    //console.log("url", responses.data.url)
                                    navigate(responses.data.url)

                                } else {
                                    setbackdropemp(false);
                                    setalertmsg("Login Failed");
                                    setalertopen(true);
                                    handleAlertClose();
                                }



                            })
                            .catch(function (errors) {

                                //console.log("error at validate token", errors)

                                if (
                                    errors.response.status == 401 ||
                                    errors.response.status == 403
                                ) {
                                    setbackdropemp(false);
                                    setalertmsg("Login Failed");
                                    setalertopen(true);
                                    handleAlertClose();
                                } else if (errors.response.status == 404) {
                                    setbackdropemp(false);
                                    setalertmsg(errors.response.data.status);
                                    setalertopen(true);
                                    handleAlertClose();
                                }
                                else {
                                    setbackdropemp(false);
                                    setalertmsg("Login Failed");
                                    setalertopen(true);
                                    handleAlertClose();
                                }
                            });
                    } else if (response.status == 202) {
                        setbackdropemp(false);
                        setalertmsg(response.data.loginToken);
                        setalertopen(true);
                        handleAlertClose();
                    }

                    utils.PrintLog("/authenticate", response.data)
                })
                .catch(function (error) {
                    setbackdropemp(false);
                    setalertmsg("Invalid Login Credentials");
                    setalertopen(true);
                    handleAlertClose();
                });
        }
    };

    function validation() {
        let isvalid = true;

        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/


        if (emailemp === "") {
            seterrmailempb(true);
            seterrmailemp("Please Enter Email");
            isvalid = false;
        } else if (!emailemp.match(regex.emailpattern)) {
            seterrmailempb(true);
            seterrmailemp("Please Enter Valid Email");
            isvalid = false;
        } else {
            seterrmailempb(false);
            seterrmailemp("");
        }

        if (passwordemp === "") {
            seterrpassempb(true);
            seterrpassemp("Please Enter Password");
            isvalid = false;
        } else if (passwordRegex.test(passwordemp) === false) {
            seterrpassempb(true);
            seterrpassemp("Please Enter Valid Password");
            isvalid = false;
        }
        else {
            seterrpassempb(false);
            seterrpassemp("");
        }



        return isvalid;
    }

    const handleAlertClose = () => {
        setTimeout(function () {
            setalertopen(false);
        }, 3000);
    };

    const handleClickShowPassword = () => {
        if (showPassword === false) {
            setshowPassword(true);
        } else {
            setshowPassword(false);
        }
    };

    const handleClickShowPassword2 = () => {
        if (showPassword2 === false) {
            setshowPassword2(true);
        } else {
            setshowPassword2(false);
        }
    };

    const handleForgotPassword = () => {
        setforgotemail("");
        setopenforgotdialog(true);
    };

    const handleforgotdialogclose = () => {
        setopenforgotdialog(false);
    };

    const handleForgotSubmit = () => {
        if (emailValidation()) {
            setforgotbackdrop(true);

            const params1 = new URLSearchParams();
            params1.append("requestEmail", forgotemail);

            axios
                .post(BASE_URL + "/auth/resetPasswordRequestEmp", params1, {})
                .then(function (response) {
                    ////console.log("response ", response)
                    ////console.log("status ", response)
                    setforgotbackdrop(false);

                    if (response.status == 200) {
                        ////console.log("in 200")

                        if (response.data === "Mail Send") {
                            setopenforgotdialog(false);
                            setsuccessdialogtitle("Email Sent");
                            setsuccessdialogcontent(
                                "Password reset link has been sent to the provided E-mail"
                            );
                            setopensuccessdialog(true);
                        } else if (response.data === "Mail Not Send") {
                            setopenforgotdialog(false);
                            setsuccessdialogtitle("Email not Sent");
                            setsuccessdialogcontent(
                                "Something Went Wrong.. Please try again later"
                            );
                            setopensuccessdialog(true);
                        } else if (response.data === "User Not Found") {
                            setopenforgotdialog(false);
                            setsuccessdialogtitle("User not Found");
                            setsuccessdialogcontent(
                                "No user was found against the provided E-mail Id"
                            );
                            setopensuccessdialog(true);
                        }
                    } else {
                        ////console.log("in else")
                        setopenforgotdialog(false);
                        setsuccessdialogtitle("Email not Sent");
                        setsuccessdialogcontent(
                            "Something Went Wrong.. Please try again later"
                        );
                        setopensuccessdialog(true);
                    }
                })
                .catch(function (error) {
                    setopenforgotdialog(false);
                    setsuccessdialogtitle("Email not Sent");
                    setsuccessdialogcontent(
                        "Something Went Wrong.. Please try again later"
                    );
                    setopensuccessdialog(true);

                    setforgotbackdrop(false);
                });
        }
    };

    const handlesuccessdialogclose = () => {
        setopensuccessdialog(false);
    };
    const handleSuccessSubmit = () => {
        setopensuccessdialog(false);
    };

    const handleForgotCancel = () => {
        setopenforgotdialog(false);
    };

    function emailValidation() {
        let isValid = true;

        if (forgotemail === "") {
            seterrforgotemailb(true);
            seterrforgotemail("Email Id cannot be blank");
            isValid = false;
        } else if (!forgotemail.match(regex.emailpattern)) {
            seterrforgotemailb(true);
            seterrforgotemail("Please Enter Valid Email Id");
            isValid = false;
        } else {
            seterrforgotemailb(false);
            seterrforgotemail("");
        }

        return isValid;
    }

    const handlePreventDefault = (event) => {
        event.preventDefault();
    };



    return (
        <>
            <NavBarHome title={TITLE}></NavBarHome>
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div
                        id="carouselExampleIndicators"
                        className="carousel slide carousel-fade"
                        data-bs-ride="carousel"
                    >
                        <div className="carousel-indicators">
                            <button
                                type="button"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide-to="0"
                                className="active"
                                aria-current="true"
                                aria-label="Slide 1"
                            ></button>
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={LoginBanner} className="d-block w-100" alt="image1" />
                            </div>
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="prev"
                        >
                            <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                            ></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="next"
                        >
                            <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                            ></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="row mx-5 mb-5 mt-5 ">
                <div className="container col-md-4  ">
                    <Box
                        sx={{
                            marginTop: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            boxShadow: 5,
                            marginTop: 1,
                            background: "#fafafa",
                        }}
                    >
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <TabList
                                    onChange={handleChange}
                                    centered
                                    aria-label="lab API tabs example"
                                >
                                    <Tab label="Employee Login" value="1" />
                                </TabList>
                                <Collapse in={alertopen}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        <strong>{alertmsg}</strong>
                                    </Alert>
                                </Collapse>
                            </Box>
                            <TabPanel value="1">
                                <Box
                                    justifyContent="center"
                                    sx={{ height: "100%", width: "100%" }}
                                >
                                    <form noValidate autoComplete="off">

                                        {/* Dummy hidden fields to trick the browser */}

                                        <input type="text" name='abcd' id="abc" autoComplete='new-email' tabIndex='-1' style={{ display: 'none' }} />
                                        <input type="password" name='defg' id="def" autoComplete='new-password' tabIndex='-1' style={{ display: 'none' }} />
                                        {/* Dummy hidden fields to trick the browser */}
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <div>
                                                    <TextField
                                                        error={errmailempb}
                                                        helperText={errmailemp}
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        type="text"
                                                        autoComplete="cc-number"
                                                        name="email-input"
                                                        id="email-input"

                                                        label="Email"
                                                        //defaultValue={userdata.state_name}
                                                        value={emailemp}

                                                        onChange={(e) => setemailemp(e.target.value)}
                                                    ></TextField>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div>
                                                    <TextField
                                                        error={errpassempb}
                                                        helperText={errpassemp}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        label="Password"
                                                        autoComplete="cc-number"
                                                        name="noAutoFill"
                                                        //defaultValue={userdata.state_name}
                                                        value={passwordemp}
                                                        type={showPassword ? "text" : "password"}
                                                        onChange={(e) => setpasswordemp(e.target.value)}


                                                    ></TextField>
                                                </div>
                                            </Grid>
                                            <Grid container justifyContent='right' >
                                                <Button variant="text" onClick={handleForgotPassword} >Forgot Password?</Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div
                                                    style={{
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        display: "flex",
                                                    }}
                                                >
                                                    {/* <TextField
                            //className={classes.root}
                            margin="normal"
                            disabled
                            id="outlined-name"
                            //defaultValue={userdata.state_name}
                            value={captchaTextemp}
                            sx={{
                              "& .MuiOutlinedInput-input.MuiOutlinedInput-input.Mui-disabled": {
                                backgroundColor: 'black',
                                WebkitTextFillColor: 'white'
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={reloadButton}
                                    //onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    <RefreshIcon></RefreshIcon>
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          >
                          </TextField> */}
                                                    <img src={captchaimage} alt="CAPTCHA" />
                                                    <IconButton
                                                        onClick={reloadButton}
                                                    //onMouseDown={handleMouseDownPassword}
                                                    >
                                                        <RefreshIcon></RefreshIcon>
                                                    </IconButton>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div
                                                    style={{
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        display: "grid",
                                                    }}
                                                >
                                                    <TextField
                                                        //className={classes.root}
                                                        error={errcaptchaempb}
                                                        helperText={errcaptchaemp}
                                                        margin="normal"
                                                        id="outlined-name"
                                                        label="Enter Captcha"
                                                        autoComplete="off"
                                                        //defaultValue={userdata.state_name}
                                                        value={getcaptchaTextemp}
                                                        onChange={(e) => setgetcaptchaTextemp(e.target.value)}
                                                    ></TextField>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </form >
                                    <Grid container justifyContent="center" sx={{ mt: 3 }}>
                                        <Button variant="contained" onClick={handleloginempClick}>
                                            Login As Employee
                                        </Button>
                                    </Grid>
                                </Box>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </div>
                <Backdrop
                    sx={{ color: "#ffffff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdropemp}
                //onClick={handlebackdropClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {/* Forgot password dialog*/}
                <Dialog
                    open={openforgotdialog}
                    onClose={handleforgotdialogclose}
                    sx={{
                        "& .MuiDialog-paper": {
                            width: "500px",
                        },
                    }}
                >
                    <DialogTitle>Please Enter Your Registered Email ID</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div>
                                    <TextField
                                        error={errforgotemailb}
                                        helperText={errforgotemail}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="outlined-select-district"
                                        label="Enter Email"

                                        value={forgotemail}
                                        onChange={
                                            (e) => setforgotemail(e.target.value)
                                            //setdistrict(centers.filter(op => op.center_dist_id === district))
                                        }
                                        autoComplete="cc-number"

                                        name="unique-password-field"
                                    //onChange={handleChange}
                                    ></TextField>
                                </div>
                            </Grid>
                        </Grid>
                        <Backdrop
                            sx={{
                                color: "#ffffff",
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={backdropdialog}
                        //onClick={handlebackdropClose}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleForgotSubmit}>Submit</Button>
                        <Button onClick={handleForgotCancel}>Cancel</Button>
                    </DialogActions>
                    <Backdrop
                        sx={{
                            color: "#ffffff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={forgotbackdrop}
                    //onClick={handlebackdropClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Dialog>
                {/* Forgot password dialog*/}
                {/* Success password dialog*/}
                <Dialog
                    open={opensuccessdialog}
                    onClose={handlesuccessdialogclose}
                    sx={{
                        "& .MuiDialog-paper": {
                            width: "500px",
                        },
                    }}
                >
                    <DialogTitle>{successdialogtitle}</DialogTitle>
                    <DialogContent>{successdialogcontent}</DialogContent>
                    <DialogActions>
                        <Button onClick={handleSuccessSubmit}>OK</Button>
                    </DialogActions>
                </Dialog>
                {/* Success password dialog*/}
            </div >
        </>
    );
}
