import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import config from '../config.json'
import NavBarSuperAdmin from '../NavBar/NavBarSuperAdmin'
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";

import { decrypter } from "../Util"

import Banner1 from '../images/banner1.jpg';
import Banner2 from '../images/banner2.jpg';
import Banner3 from '../images/banner3.jpg';
import tick from '../images/verified.png'
import upload from '../images/upload.png'
import load from '../images/loading.png'


import CountUp from "react-countup";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Grid, Box } from "@mui/material";
import { CardActionArea } from '@mui/material';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import axios from 'axios';
import SideBarSuperAdmin from "../SideBar/SideBarSuperAdmin";
import ManageProofs from "./Master Management/ManageProofs";

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;
const SECRET = config.SECRET;

var CryptoJS = require("crypto-js");
export default function SADashboard2() {

    const navigate = useNavigate()
    let userdata = decrypter(cookies.get("userdata"))
    let jwt = cookies.get("token")

    let role = userdata.user.roles.map(op => op.role_name)

    let userrole = role[0]

    let KEY = 'Bearer ' + jwt;





    const cardStyle = {
        background: "#e8d4e0"
    }


    const [approvedesign, setapprovedesign] = useState()
    const [rejectdesign, setrejectdesign] = useState()
    const [submitdesign, setsubmitdesign] = useState()
    const [totaldesign, settotaldesign] = useState()

    const [approvemotif, setapprovemotif] = useState()
    const [rejectmotif, setrejectmotif] = useState()
    const [submitmotif, setsubmitmotif] = useState()
    const [totalmotif, settotalmotif] = useState()

    const [isSidebar, setIsSidebar] = useState(true);

    useEffect(() => {

        const headers = {
            Authorization: KEY
        };



        const getdesignstats = axios.post(BASE_URL + '/designStatsAdmin', {}, { headers: headers })
        const getmotifstats = axios.post(BASE_URL + '/motifStatsAdmin', {}, { headers: headers })


        Promise.all([getdesignstats, getmotifstats])
            .then(function (responses) {

                //setunmapped(responses[0].data.Beneficiaries)
                //////console.log("design", responses[0].data.Status)

                responses[0].data.Status.map((op) => { op.status === 'Submitted' ? setsubmitdesign(op.count) : (op.status === 'Approved' ? setapprovedesign(op.count) : (op.status === 'Rejected' ? setrejectdesign(op.count) : settotaldesign(op.count))) }
                    //{op.status === 'Submitted' ? setsubmitdesign(op.count) : setsubmitdesign(0)}
                )

                responses[1].data.Status.map((op) => { op.status === 'Submitted' ? setsubmitmotif(op.count) : (op.status === 'Approved' ? setapprovemotif(op.count) : (op.status === 'Rejected' ? setrejectmotif(op.count) : settotalmotif(op.count))) }
                    //{op.status === 'Submitted' ? setsubmitdesign(op.count) : setsubmitdesign(0)}
                )



            }).catch(function (errors) {

            });

    }, [])


    if (cookies.get("loginstatus") != undefined) {

        var bytes = CryptoJS.AES.decrypt(cookies.get("loginstatus"), SECRET);
        var status = bytes.toString(CryptoJS.enc.Utf8);

    }


    //userdata.user.roles.map(op => )
    return (
        <>
            {/* <div>
                <NavBarSuperAdmin
                    title={TITLE}
                    name={userdata.memp.empName}
                    role={userrole}

                />
            </div> */}

            <Box display="flex" justifyContent="space-between" alignItems="center">
                <SideBarSuperAdmin></SideBarSuperAdmin>
                <Box
                    component="form"
                    padding={4}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container spacing={1} sx={{ mt: 2 }}>
                        <Grid item xs={4} >
                            <div className="card radius-10 " style={cardStyle}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text" style={{ fontWeight: 'bold' }}>Total Designs Uploaded</p>
                                            <h4 className="my-1 text" style={{ color: 'black' }}><CountUp end={totaldesign} /></h4>

                                        </div>
                                        <div className="widgets-icons-2 rounded-circle ms-auto">
                                            <img src={upload} className="d-block w-32" alt="image2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} >
                            <div className="card radius-10 " style={cardStyle}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text" style={{ fontWeight: 'bold' }} >Total Designs Pending for Approval</p>
                                            <h4 className="my-1 text " style={{ color: 'black' }} ><CountUp end={submitdesign} /></h4>

                                        </div>
                                        <div className="widgets-icons-2 rounded-circle ms-auto">
                                            <img src={load} className="d-block w-32" alt="image2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} >
                            <div className="card radius-10 " style={cardStyle}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text" style={{ fontWeight: 'bold' }} >Total Designs Approved</p>
                                            <h4 className="my-1 text" style={{ color: 'black' }}><CountUp end={approvedesign} /></h4>

                                        </div>
                                        <div className="widgets-icons-2 rounded-circle ms-auto">
                                            <img src={tick} className="d-block w-32" alt="image2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={4} >
                            <div className="card radius-10 " style={cardStyle}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text" style={{ fontWeight: 'bold' }}>Total Motifs Uploaded</p>
                                            <h4 className="my-1 text" style={{ color: 'black' }} ><CountUp end={totalmotif} /></h4>

                                        </div>
                                        <div className="widgets-icons-2 rounded-circle ms-auto">
                                            <img src={upload} className="d-block w-32" alt="image2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} >
                            <div className="card radius-10 " style={cardStyle}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text" style={{ fontWeight: 'bold' }}>Total Motifs Pending for Approval</p>
                                            <h4 className="my-1 text" style={{ color: 'black' }}><CountUp end={submitmotif} /></h4>

                                        </div>
                                        <div className="widgets-icons-2 rounded-circle ms-auto">
                                            <img src={load} className="d-block w-32" alt="image2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} >
                            <div className="card radius-10 " style={cardStyle}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text" style={{ fontWeight: 'bold' }}>Total Motifs Approved</p>
                                            <h4 className="my-1 text" style={{ color: 'black' }}><CountUp end={approvemotif} /></h4>

                                        </div>
                                        <div className="widgets-icons-2 rounded-circle ms-auto">
                                            <img src={tick} className="d-block w-32" alt="image2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Box>





        </>
    )
}
