import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import config from '../../config.json'
import NavBarSuperAdmin from '../../NavBar/NavBarSuperAdmin'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import { decrypter } from "../../Util"
import regex from "../../regex.json"

import Banner1 from '../../images/banner1.jpg'

import axios from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
    DataGrid, GridToolbar,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { Grid } from "@mui/material";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import TextField from "@mui/material/TextField";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { makeStyles } from "@mui/styles";



const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;
const SECRET = config.SECRET;

const style2 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
}

const useStyle = makeStyles({
    root: {
        "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center",
            fontWeight: 'bold'
        },
        "& .MuiDataGrid-cellContent": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center"
        },
        "& .MuiTypography-string": {
            textOverflow: "ellipsis",
            whiteSpace: "break-spaces",
            lineHeight: 2,
            textAlign: "center"
        },
        boxShadow: 2,
        '& .MuiTablePagination-selectLabel': {
            marginTop: 'auto'
        },
        '& .MuiTablePagination-displayedRows': {
            marginTop: 'auto'
        }
    }
});

export default function ManageProofs() {

    const navigate = useNavigate()
    // let userdata = decrypter(cookies.get("userdata"))
    // let jwt = cookies.get("token")

    // let KEY = 'Bearer ' + jwt;

    // let role = userdata.user.roles.map(op => op.role_name)

    // let userrole = role[0]

    var count = 0;

    const classes = useStyle()

    const [pageSize, setPageSize] = useState(10);
    const [opensnack, setopensnack] = useState(false);
    const [snackmsg, setsnackmsg] = useState();
    const [addproofmodal, setaddproofmodal] = useState(false);
    const [backdrop, setbackdrop] = useState(false)
    const [editproofmodal, seteditproofmodal] = useState(false)
    const [opendeletedialog, setopendeletedialog] = useState(false)
    const [opensessiondialog, setopensessiondialog] = useState(false)
    const [refreshdata, setrefreshdata] = useState(0)


    const [proofs, setproofs] = useState([])
    const [editvalues, seteditvalues] = useState([])

    const [proofid, setproofid] = useState("")
    const [proofname, setproofname] = useState("");
    const [errproofname, seterrproofname] = useState();
    const [errproofnameb, seterrproofnameb] = useState(false);





    const columns = [
        {
            field: 'proof_type_id', headerName: 'ID', flex: 0.5, align: 'center', headerAlign: 'center'
        },
        {
            field: 'proof_type_name', headerName: 'Proof Type', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: "Action",
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleEditClick(event, cellValues);
                            }}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleDeleteClick(event, cellValues);
                            }}
                        >
                            <DeleteIcon></DeleteIcon>
                        </IconButton>
                        {/* <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleFormEditOpen(event, cellValues.row);
                            }}
                        >
                            <FileOpenIcon></FileOpenIcon>
                        </IconButton> */}
                    </>
                );
            }, flex: 1, align: 'center', headerAlign: 'center'
        }

    ]

    useEffect(() => {

        // const headers = {
        //     Authorization: KEY,
      
        //   };

        axios.post(BASE_URL + '/getAllProofTypes', {}, { withCredentials: true })
            .then(function (responses) {

                ////console.log("Proofs", responses.data.ProofTypes)
                //setunmapped(responses.data.Beneficiaries)
                setproofs(responses.data.ProofTypes)

            })
            .catch(function (errors) {

            })

        setrefreshdata(0)

    }, [refreshdata])


    const handleDeleteClick = (event, cellValues) => {

        setopendeletedialog(true)

        setproofid(cellValues.row.proof_type_id);

    }

    const handleEditClick = (event, cellValues) => {

        //seteditvalues(cellValues.row)
        setproofid(cellValues.row.proof_type_id)
        setproofname(cellValues.row.proof_type_name)
        seteditproofmodal(true)
        seterrproofname("")
        seterrproofnameb(false)
        

    }

    const handleAddProof = () => {

        setproofname("")
        seterrproofname("")
        seterrproofnameb(false)

        setaddproofmodal(true)

    }

    const handleCloseDialog = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setopensnack(false);
    };

    const handleaddproofmodal = () => {
        setaddproofmodal(false)
    }

    const handleeditproofmodal = () => {
        seteditproofmodal(false)
    }

    const handledeletedialogclose = () => {
        setopendeletedialog(false)
    }

    const handleYesButton = () => {

        // const headers = {
        //     Authorization: KEY
        // };


        axios
            .post(BASE_URL + "/deleteProofType", { proof_type_id: proofid }, { withCredentials: true })
            .then(function (response) {
                ////console.log(response);
                if (response.data.status === "ok" && response.status == 200) {
                    //setbackdrop(false)
                    setsnackmsg("Proof Type Deleted Successfully")
                    setopensnack(true);
                    setopendeletedialog(false)
                    //setaddproofmodal(false)

                    // setTimeout(function () {
                    //     window.location.reload(false);
                    // }, 3000);

                    setrefreshdata(count + 1)

                }
                else {
                    //setbackdrop(false)

                    setsnackmsg("Proof Type Delete Failed")
                    setopensnack(true);
                }
            })
            .catch(function (errors) {
                //setbackdrop(false)
                if (errors.response.status == 401) {

                    setaddproofmodal(false)
                    setopensessiondialog(true)

                } else {

                    setsnackmsg("Proof Type Delete Failed")
                    setopensnack(true);

                }

                setsnackmsg("Proof Type Delete Failed")
                setopensnack(true);
            });

    }

    const handleNoButton = () => {
        setopendeletedialog(false)
    }

    const handleOKButton = () => {
        navigate('/')
        cookies.remove("userdata")
        cookies.remove("loginstatus")
        cookies.remove("token")
    }


    const handleaddproofbuttonclick = (e) => {
        e.preventDefault();

        ////console.log("validation", validation())

        if (validation()) {


            // const headers = {
            //     Authorization: KEY
            // };



            setbackdrop(true)

            
            const params = new URLSearchParams();
            params.append("proof_name", proofname)

            axios
                .post(BASE_URL + "/proofDuplicate",
                    params,
                    { withCredentials: true })
                .then(function (response) {
                    ////console.log(response.data);
                    if (response.status == 200) {

                        if (response.data > 0) {
                            seterrproofnameb(true)
                            seterrproofname("Proof Name Already Exists")
                            setbackdrop(false)

                        } else {

                            axios
                                .post(BASE_URL + "/addProofType", { proof_type_name: proofname }, { withCredentials: true })
                                .then(function (response) {
                                    ////console.log(response);
                                    if (response.data.status === "ok" && response.status == 200) {
                                        setbackdrop(false)
                                        setsnackmsg("Proof Added Successfully")
                                        setopensnack(true);
                                        setaddproofmodal(false)

                                        // setTimeout(function () {
                                        //     window.location.reload(false);
                                        // }, 3000);
                                        setrefreshdata(count + 1)

                                    }
                                    else {
                                        setbackdrop(false)

                                        setsnackmsg("Proof Addition Failed")
                                        setopensnack(true);
                                    }
                                })
                                .catch(function (errors) {

                                    if (errors.response.status == 401) {

                                        setaddproofmodal(false)
                                        setopensessiondialog(true)

                                    } else {

                                        setbackdrop(false)
                                        setsnackmsg("Proof Addition Failed")
                                        setopensnack(true);

                                    }


                                });

                        }




                    }
                    else {

                    }
                })
                .catch(function (errors) {

                    if (errors.response.status == 401 || errors.response.status == 403) {

                        setaddproofmodal(false)
                        setopensessiondialog(true)

                    } else {

                        setbackdrop(false)
                        setsnackmsg("Proof Addition Failed")
                        setopensnack(true);

                    }


                });


        }




    }

    const handleeditproofbuttonclick = (e) => {
        e.preventDefault();



        if (validation()) {




            // const headers = {
            //     Authorization: KEY
            // };



            setbackdrop(true)

            const params = new URLSearchParams();
            params.append("proof_name", proofname)

            axios
                .post(BASE_URL + "/proofDuplicate",
                    params,
                    { withCredentials: true })
                .then(function (response) {
                    ////console.log(response.data);
                    if (response.status == 200) {

                        if (response.data > 0) {
                            seterrproofnameb(true)
                            seterrproofname("Proof Name Already Exists")
                            setbackdrop(false)

                        } else {

                            axios
                                .post(BASE_URL + "/editProofType",
                                    {
                                        proof_type_id: proofid,
                                        proof_type_name: proofname
                                    },
                                    { withCredentials: true })
                                .then(function (response) {
                                    ////console.log(response);
                                    if (response.data.status === "ok" && response.status == 200) {
                                        setbackdrop(false)
                                        setsnackmsg("Proof Edited Successfully")
                                        setopensnack(true);
                                        seteditproofmodal(false)

                                        // setTimeout(function () {
                                        //     window.location.reload(false);
                                        // }, 3000);
                                        setrefreshdata(count + 1)

                                    }
                                    else {
                                        setbackdrop(false)

                                        setsnackmsg("Proof Edit Failed")
                                        setopensnack(true);
                                    }
                                })
                                .catch(function (errors) {

                                    if (errors.response.status == 401 || errors.response.status == 403) {

                                        setaddproofmodal(false)
                                        setopensessiondialog(true)

                                    } else {

                                        setbackdrop(false)
                                        setsnackmsg("Proof Edit Failed")
                                        setopensnack(true);

                                    }


                                });



                        }




                    }
                    else {

                    }
                })
                .catch(function (errors) {

                    if (errors.response.status == 401 || errors.response.status == 403) {

                        setaddproofmodal(false)
                        setopensessiondialog(true)

                    } else {

                        setbackdrop(false)
                        setsnackmsg("Proof Addition Failed")
                        setopensnack(true);

                    }


                });


        }
    }

    function validation() {

        let isvalid = true;



        if (proofname === "") {
            seterrproofnameb(true)
            seterrproofname("Proof Name Cannot be Empty")
            isvalid = false;
        } else if (!proofname.match(regex.letterandspace)) {

            seterrproofnameb(true)
            seterrproofname("Please Enter Valid Proof Name")
            isvalid = false;
        } else {
            seterrproofnameb(false)
            seterrproofname("")


        }

        return isvalid;
    }


    return (
        <>
            <div>
                <NavBarSuperAdmin
                    title={TITLE}

                />
            </div>
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={Banner1} className="d-block w-100" alt="Banner1" />
                            </div>
                        </div>
                        {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button> */}
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="row mx-5 mb-5">
                <div className="container">
                    <h1>
                        <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<AddIcon></AddIcon>} onClick={handleAddProof} >Add Proof Type</Button>
                        <div className="abc text-black text-center col 10 p-1 mt-1">
                            <strong><h2> Manage Proofs </h2></strong>
                        </div>
                    </h1>
                    <Box
                        justifyContent='center'
                    //</div>sx={{ height: 700, width: '100%' }}
                    >
                        <DataGrid
                            className={classes.root}
                            sx={{
                                boxShadow: 2,
                                '& .MuiTablePagination-selectLabel': {
                                    marginTop: 'auto'
                                },
                                '& .MuiTablePagination-displayedRows': {
                                    marginTop: 'auto'
                                }
                            }}
                            rows={proofs}
                            getRowId={(r) => r.proof_type_id}
                            columns={columns}
                            components={{
                                Toolbar: GridToolbar,
                                //Pagination: CustomPagination
                            }}
                            disableSelectionOnClick
                            autoHeight
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            pagination
                            rowsPerPageOptions={[10, 25, 50, 100]}

                        //checkboxSelection={false}
                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setSelectedRows(newSelectionModel);
                        // }}

                        />
                    </Box>
                    <Snackbar
                        open={opensnack}
                        autoHideDuration={6000}
                        onClose={handleCloseDialog}
                        message={snackmsg}
                    //action={action}
                    />
                    {/* add proof modal */}
                    <Modal
                        open={addproofmodal}
                        onClose={handleaddproofmodal}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        <Box sx={style2}>
                            <Grid container spacing={1}>

                                <Grid item xs={4}>

                                    <Card sx={{ width: 500, alignItems: 'center', boxShadow: 10, borderRadius: 3, padding: 2 }}>
                                        <h1>
                                            <div className="abc text-black text-center col 10 p-1 mt-1">
                                                <strong><h2> Add Proof Type </h2></strong>
                                            </div>
                                        </h1>
                                        <div>
                                            <TextField
                                                error={errproofnameb}
                                                helperText={errproofname}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="outlined-name"
                                                label="Enter Proof Name"
                                                //defaultValue={userdata.state_name}
                                                value={proofname}
                                                onChange={(e) => setproofname(e.target.value)}
                                            />
                                        </div>
                                        <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
                                            <Button variant="contained" onClick={handleaddproofbuttonclick}>
                                                Submit
                                            </Button>
                                        </Grid>

                                        <Backdrop
                                            sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                            open={backdrop}
                                        //onClick={handlebackdropClose}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>

                                    </Card>

                                </Grid>

                            </Grid>

                        </Box>

                    </Modal>
                    {/* add proof modal end */}
                    {/* edit proof modal */}
                    <Modal
                        open={editproofmodal}
                        onClose={handleeditproofmodal}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description">

                        <Box sx={style2}>
                            <Grid container spacing={1}>

                                <Grid item xs={4}>

                                    <Card sx={{ width: 500, alignItems: 'center', boxShadow: 10, borderRadius: 3, padding: 2 }}>
                                        <h1>
                                            <div className="abc text-black text-center col 10 p-1 mt-1">
                                                <strong><h2> Edit Proof Type </h2></strong>
                                            </div>
                                        </h1>
                                        <div>
                                            <TextField

                                                margin="normal"
                                                disabled
                                                fullWidth
                                                id="outlined-name"
                                                label="Proof ID"
                                                //defaultValue={userdata.state_name}
                                                value={proofid}
                                            //onChange={(e) => setproofname(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                error={errproofnameb}
                                                helperText={errproofname}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="outlined-name"
                                                label="Proof Name"
                                                //defaultValue={userdata.state_name}
                                                value={proofname}
                                                onChange={(e) => setproofname(e.target.value)}
                                            />
                                        </div>
                                        <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
                                            <Button variant="contained" onClick={handleeditproofbuttonclick}>
                                                Submit
                                            </Button>
                                        </Grid>

                                        <Backdrop
                                            sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                            open={backdrop}
                                        //onClick={handlebackdropClose}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>

                                    </Card>

                                </Grid>

                            </Grid>

                        </Box>


                    </Modal>
                    {/* edit proof modal end */}
                    {/* delete dialog */}
                    <Dialog
                        open={opendeletedialog}
                        onClose={handledeletedialogclose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"ARE YOU SURE YOU WANT TO DELETE?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Once deleted it cannot be undone
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleYesButton}>YES</Button>
                            <Button onClick={handleNoButton} autoFocus>
                                NO
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* delete dialog end */}
                    {/* session expiration dialog */}
                    <Dialog
                        open={opensessiondialog}

                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Session Expired!!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Session has expired..Please Login Again to Continue
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleOKButton}>OK</Button>
                        </DialogActions>
                    </Dialog>
                    {/* session expiration dialog end */}


                </div>
            </div>
        </>
    )
}
