import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
import GoToTop from './GoToTop';
import Footer from './Home/Footer'
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Cookies } from 'react-cookie';

import config from './config.json'
import { Backdrop, CircularProgress, Snackbar } from "@mui/material";


const cookies = new Cookies();

const BASE_URL = config.SERVER_URL;




const root = ReactDOM.createRoot(document.getElementById('root'));
// document.addEventListener('contextmenu', (e) => {
//   e.preventDefault();
// });
root.render(
  <React.StrictMode>
    <ProSidebarProvider>
      <CookiesProvider>
        <BrowserRouter>
          <GoToTop />
          <App />
          <Footer />
        </BrowserRouter>
      </CookiesProvider>
    </ProSidebarProvider>


  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(////console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
