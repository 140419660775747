import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import config from '../../config.json'

import { Link, Navigate, useNavigate } from 'react-router-dom'

import { decrypter } from "../../Util"

import { useTheme } from '@mui/material/styles';
import Banner1 from '../../images/banner1.jpg'

import axios from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
    DataGrid, GridToolbar,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { Grid } from "@mui/material";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import TextField from "@mui/material/TextField";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import { Tooltip } from '@mui/material';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import makeStyles from '@mui/styles/makeStyles';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import moment from 'moment';
import regex from "../../regex.json"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import InfoIcon from '@mui/icons-material/Info';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import Skeleton from '@mui/material/Skeleton';
import ImageZoom from "react-image-zooom";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from '@mui/material/InputAdornment';

import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';



const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;
const SECRET = config.SECRET;

const style2 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    maxWidth: '100%',
    maxHeight: '100vh',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    overflowX: 'auto',
    margin: 'auto'
}

const style3 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    maxWidth: '100%',
    maxHeight: '90%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    overflowX: 'auto',
    margin: 'auto'
}

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '350px',
    maxHeight: '350px',
    objectFit: 'contain'
});

const useStyle = makeStyles({
    root: {
        "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center",
            fontWeight: 'bold'
        },
        "& .MuiDataGrid-cellContent": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center"
        },
        "& .MuiTypography-string": {
            textOverflow: "ellipsis",
            whiteSpace: "break-spaces",
            lineHeight: 2,
            textAlign: "center"
        },
        boxShadow: 2,
        '& .MuiTablePagination-selectLabel': {
            marginTop: 'auto'
        },
        '& .MuiTablePagination-displayedRows': {
            marginTop: 'auto'
        }
    }
});

const useStylesCard = makeStyles({
    root: {

    },
    media: {
        "&:hover": {
            transform: "scale(3.5)"
        }
    }
});

export default function ImageListDesignSubAdmin(props) {

    const navigate = useNavigate()
    // let cookiedata = decrypter(cookies.get("sessionid"))


    // let userdata = cookiedata.userdata
    // let jwt = cookiedata.token

    // let role = cookiedata.userdata.user.roles.map(op => op.role_name)

    // let userrole = role[0]

    // let KEY = 'Bearer ' + jwt;


    const [viewformModal, setviewformModal] = useState(false)
    const [viewimgModal, setviewimgModal] = useState(false)
    const [viewoption, setviewoption] = useState([])

    const [loading, setloading] = useState(true)
    const [pageSize, setPageSize] = useState(10);

    const [motifid, setmotifid] = useState('')
    const [cat, setcat] = useState('')
    const [subcat, setsubcat] = useState([])
    const [height, setheight] = useState('')
    const [width, setwidth] = useState('')
    const [hooks, sethooks] = useState('')
    const [ppi, setppi] = useState('')
    const [epi, setepi] = useState('')
    const [color, setcolor] = useState('')
    const [dentinch, setdentinch] = useState('')
    const [dentorderid, setdentorderid] = useState('')
    const [dentorder, setdentorder] = useState('')
    const [weaves, setweaves] = useState('')
    const [uploadedby, setuploadedby] = useState('')
    const [uploadedbyid, setuploadedbyid] = useState('')
    const [filename, setfilename] = useState('')
    const [status, setstatus] = useState('')
    const [remarks, setremarks] = useState('')
    const [viewMovementModal, setviewMovementModal] = useState(false)
    const [movementarr, setmovementarr] = useState([])

    const [us, setus] = useState('')
    const [zoom, setzoom] = useState(false)


    const [openapprovedialog, setopenapprovedialog] = useState(false)
    const [rejectreason, setrejectreason] = useState('')
    const [errrejectreasonb, seterrrejectreasonb] = useState(false)
    const [errrejectreason, seterrrejectreason] = useState('')
    const [openrejectmodal, setopenrejectmodal] = useState(false)
    const [opensnack, setopensnack] = useState(false)
    const [snackmsg, setsnackmsg] = useState(false)

    const [backdrop, setbackdrop] = useState(false);

    const [img, setimg] = useState()

    const [catid, setcatid] = useState('')
    const [subcatid, setsubcatid] = useState([])
    const [center, setcenter] = useState('')
    const [user, setuser] = useState('')

    const [page, setpage] = useState(1)

    const [opensessiondialog, setopensessiondialog] = useState(false)

    const [categories, setcategories] = useState([])
    const [subcategories, setsubcategories] = useState([])
    const [dentingorders, setdentingorders] = useState([])


    const [edit, setedit] = useState(false)

    const [errcatid, seterrcatid] = useState('')
    const [errcatidb, seterrcatidb] = useState(false)

    const [errdentorderid, seterrdentorderid] = useState('')
    const [errdentorderidb, seterrdentorderidb] = useState(false)

    const [errhooks, seterrhooks] = useState('')
    const [errhooksb, seterrhooksb] = useState(false)

    const [errweaveid, seterrweaveid] = useState('')
    const [errformcontrolweaveb, seterrformcontrolweaveb] = useState(false)
    const [errformhelperweaveb, seterrformhelperweaveb] = useState(false)

    const [errheight, seterrheight] = useState('')
    const [errheightb, seterrheightb] = useState(false)

    const [errwidth, seterrwidth] = useState('')
    const [errwidthb, seterrwidthb] = useState(false)


    const [designid, setdesignid] = useState('')
    const [stateid, setstateid] = useState('')
    const [districtid, setdistrictid] = useState('')
    const [centerid, setcenterid] = useState('')
    const [filenameedit, setfilenameedit] = useState('')
    const [uploaddate, setuploaddate] = useState('')

    const [catedit, setcatedit] = useState('')
    const [subcatedit, setsubcatedit] = useState([])
    const [heightedit, setheightedit] = useState('')
    const [widthedit, setwidthedit] = useState('')
    const [hooksedit, sethooksedit] = useState('')
    const [dentorderidedit, setdentorderidedit] = useState('')
    const [dentorderedit, setdentorderedit] = useState('')

    const [subcatpk, setsubcatpk] = useState([])



    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },

        }
    };



    const [openeditmodal, setopeneditmodal] = useState(false)


    useEffect(() => {

        const headers = {
            Authorization: KEY
        };

        const getcategory = axios.post(BASE_URL + '/getAllCategory',{}, { withCredentials: true })
        const getsubcategory = axios.post(BASE_URL + '/getAllSubCategory',{}, { withCredentials: true })
        //const getweave = axios.post(BASE_URL + '/getAllWeave', {}, { headers: headers })
        //const getdesigntype = axios.post(BASE_URL + '/getAllDesignType', {}, { headers: headers })
        const getdentingorder = axios.post(BASE_URL + '/getAllDraftingOrder',{}, { withCredentials: true })

        Promise.all([getcategory, getsubcategory, getdentingorder])
            .then(function (responses) {

                //setunmapped(responses[0].data.Beneficiaries)
                setcategories(responses[0].data.Category)
                setsubcategories(responses[1].data.SubCategory)
                setdentingorders(responses[2].data.DraftingOrder)
                ////console.log("draft",)
            }).catch(function (errors) {

            });




    }, [])

    {/**column resizing */ }

    const breakpoints = {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
    }

    const getColumns = (width) => {
        if (width < breakpoints.sm) {
            return 1
        } else if (width < breakpoints.md) {
            return 2
        } else if (width < breakpoints.lg) {
            return 3
        } else if (width < breakpoints.xl) {
            return 4
        } else {
            return 5
        }
    }

    const [columns, setColumns] = useState(getColumns(window.innerWidth))
    const updateDimensions = () => {
        setColumns(getColumns(window.innerWidth))
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);


    {/**column resizing */ }

    const handleOKButton = () => {
        navigate('/')
        cookies.remove("sessionid")
    }



    const handleviewclick = (event, option) => {



        ////console.log("op", event)
        ////console.log("sysid", option.sysDesignId)
        // setfilename(option.filename)



        const headers = {
            Authorization: KEY,

        };

        setbackdrop(true)
        //setviewformModal(true)

        const params1 = new URLSearchParams();
        params1.append("id", option.sysDesignId);

        axios
            .post(BASE_URL + "/design/subadmin/getDesignDetailsbyId",
                params1,
                { withCredentials: true })
            .then(function (response) {
                ////console.log(response);
                if (response.status == 200) {
                    setbackdrop(false)
                    setdesignid(response.data.sysDesignId)
                    setcat(response.data.category.category_name)
                    setcatid(response.data.category.category_id)
                    ////console.log("catid:", response.data.category.category_id)
                    //setsubcat(option.subCategory.subcategory_name)
                    setheight(response.data.height)
                    setheightedit(response.data.height)
                    setwidth(response.data.width)
                    setwidthedit(response.data.width)
                    sethooks(response.data.hooks)
                    sethooksedit((response.data.hooks).toString())

                    setdentorder(response.data.draftingOrder.drafting_order_name)
                    setdentorderedit(response.data.draftingOrder.drafting_order_name)
                    setdentorderid(response.data.draftingOrder.drafting_order_id)
                    setdentorderidedit(response.data.draftingOrder.drafting_order_id)
                    setuploadedby(response.data.user.full_name)
                    setuploadedbyid(response.data.uploadedBy)
                    setfilename(response.data.filename)
                    //setstatus(option.approvalStatus)
                    setremarks(response.data.remarks)

                    setstateid(response.data.stateId)
                    setdistrictid(response.data.districtId)
                    setcenterid(response.data.centerId)
                    setfilenameedit(response.data.filename)
                    setuploaddate(response.data.uploadDate)

                    //setstatus(response.data.approvalStatus)

                    ////console.log("filename:", response.data.approvalStatus)

                    let subs = []
                    let subsid = []

                    let subpk = []

                    response.data.designCategoryMapViewsList.map((op) => subs.push(op.subCategory.subcategory_name))
                    response.data.designCategoryMapViewsList.map((op) => subsid.push(op.subCategory.subcategory_id))
                    response.data.designCategoryMapViewsList.map((op) => subpk.push(op.designCategoryMapId))


                    setsubcat(subs.join(", "))
                    setsubcatid(subsid)
                    setsubcatpk(subpk)

                    setmotifid(response.data.sysDesignId)



                    setbackdrop(false)

                    setviewformModal(true)


                }
                else {
                    setbackdrop(false)
                    setopensnack(true);
                    setsnackmsg("Something Went Wrong")
                }
            })
            .catch(function (errors) {
                ////console.log("error", errors)
                if (errors.response.status == 401 || errors.response.status == 403) {


                    setbackdrop(false)

                    setopensessiondialog(true)



                } else {

                    setbackdrop(false)
                    setopensnack(true);
                    setsnackmsg("Something Went Wrong")

                }

            });


    }

    const handleviewimgModalClose = () => {

        setviewimgModal(false)
    }

    const handleviewformModalClose = () => {

        setviewformModal(false)
    }

    const handleApprove = () => {

        setopenapprovedialog(true)

    }


    const handleReject = () => {
        setopenrejectmodal(true)
        setrejectreason("")
        seterrrejectreason("")
        seterrrejectreasonb(false)

    }

    const handleImageclick = (option) => {
        setviewimgModal(true)
        ////console.log("clicked", option)
        setimg(option)

    }

    function validation2() {
        let isvalid = true;

        if (rejectreason === "") {
            seterrrejectreasonb(true);
            seterrrejectreason("Please Enter Reject Reason");
            isvalid = false;
        } else if (!rejectreason.match(regex.letterandspace)) {
            seterrrejectreasonb(true);
            seterrrejectreason("Please Enter Valid Reject Reason");
            isvalid = false;
        } else {
            seterrrejectreasonb(false);
            seterrrejectreason("");
        }

        return isvalid;
    }

    const handleRejectButtonModal = () => {

        const headers = {
            Authorization: KEY,

        };

        setbackdrop(true)

        if (validation2()) {
            const params1 = new URLSearchParams();
            params1.append("id", motifid);
            //params1.append("approve_rejected_by", userdata.memp.empId)
            params1.append("remarks", rejectreason)

            axios.post(BASE_URL + '/design/subadmin/rejectdesign', params1, { withCredentials: true })
                .then(function (responses) {

                    ////console.log(responses);
                    if (responses.data.status === "OK" && responses.status == 200) {

                        setbackdrop(false)
                        setviewformModal(false)
                        setopenrejectmodal(false)
                        setopensnack(true)
                        setsnackmsg("Design Rejected")
                        //setrefresh(1)
                        props.onCallBack();



                    }
                    else {

                    }

                })
                .catch(function (errors) {

                })
        }



    }

    const handlerejectmodalclose = () => {

        setopenrejectmodal(false)

    }

    const handleapprovemodalclose = () => {
        setopenapprovedialog(false)

    }
    const handleApproveButtonModal = () => {
        const headers = {
            Authorization: KEY,

        };

        setbackdrop(true)
        const params1 = new URLSearchParams();
        params1.append("id", motifid);
        //params1.append("approve_rejected_by", userdata.memp.empId)

        axios.post(BASE_URL + '/design/subadmin/approvedesign', params1, { withCredentials: true })
            .then(function (responses) {

                ////console.log(responses);
                if (responses.data.status === "OK" && responses.status == 200) {

                    setbackdrop(false)
                    setviewformModal(false)
                    setopenapprovedialog(false)
                    setopensnack(true)
                    setsnackmsg("Design Approved")
                    //setrefresh(1)
                    setbackdrop(false)
                    props.onCallBack();


                }
                else {

                }

            })
            .catch(function (errors) {

            })

    }
    const handleApproveCancelButtonModal = () => {
        setopenapprovedialog(false)
    }

    const handleCancelButtonModal = () => {
        setopenrejectmodal(false)
    }

    const handleCloseSnack = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setopensnack(false);

    }

    function validation() {
        let isvalid = true;


        if (catid === "") {

            seterrcatidb(true)
            seterrcatid("Category Cannot be Empty")
            isvalid = false


        } else {
            seterrcatidb(false)
            seterrcatid("")
        }

        if (subcatid.length == 0) {
            seterrformcontrolweaveb(true)
            seterrformhelperweaveb(true)
            seterrweaveid("Products Cannot be Empty")
            isvalid = false


        } else {
            seterrformcontrolweaveb(false)
            seterrformhelperweaveb(false)
            seterrweaveid("")
        }

        if (heightedit === "") {
            seterrheightb(true)
            seterrheight("Height Cannot be Empty")
            isvalid = false

        } else {
            seterrheightb(false)
            seterrheight("")
        }

        if (widthedit === "") {
            seterrwidthb(true)
            seterrwidth("Width Cannot be Empty")
            isvalid = false

        } else {
            seterrwidthb(false)
            seterrwidth("")
        }

        ////console.log("hooks valid", hooksedit)

        if (hooksedit === "") {
            seterrhooksb(true)
            seterrhooks("Hooks Cannot be Empty")
            isvalid = false

        } else if (!hooksedit.match(regex.number)) {
            seterrhooksb(true)
            seterrhooks("Please Enter Valid Hooks")
            isvalid = false
        } else {
            seterrhooksb(false)
            seterrhooks("")
        }

        if (dentorderidedit === "") {
            seterrdentorderidb(true)
            seterrdentorderid("Please Enter Drafting Order")
            isvalid = false;
        } else {
            seterrdentorderidb(false)
            seterrdentorderid("")
        }


        return isvalid;
    }

    const handleEdit = () => {

        setopeneditmodal(true)
        seterrcatidb(false)
        seterrcatid("")
        seterrformcontrolweaveb(false)
        seterrformhelperweaveb(false)
        seterrweaveid("")
        seterrheightb(false)
        seterrheight("")
        seterrwidthb(false)
        seterrwidth("")
        seterrhooksb(false)
        seterrhooks("")
        seterrdentorderidb(false)
        seterrdentorderid("")

    }

    const handleeditmodalclose = () => {
        setopeneditmodal(false)
    }

    const handleEditCancelButtonModal = () => {
        setopeneditmodal(false)
    }

    const handleEdittButtonModal = () => {





        if (validation()) {

            const headers = {
                Authorization: KEY,
            };

            setbackdrop(true)
            const uploadjson = {
                sysDesignId: designid,
                categoryId: catid,
                hooks: hooksedit,
                draftingOrderId: dentorderidedit,
            }

            const subcatobj = {

            }

            let subcatobjs = []

            // subcatid.map((op) => subcatobjs.push({
            //     designCategoryMapId:
            //         sysDesignId: designid,
            //     subcategoryId: op,
            //     updatedBy: uploadedbyid,
            //     updatedTime: uploaddate
            // }))

            for (let i = 0; i < subcatid.length; i++) {
                subcatobjs.push({
                    designCategoryMapId: subcatpk[i],
                    sysDesignId: designid,
                    subcategoryId: subcatid[i]
                })
            }

            // const subcatids = {
            //     subcatIds: subcatid
            // }

            const subcatids = {
                subcatIds: subcatobjs
            }

            ////console.log("uploadjson---: ", JSON.stringify(uploadjson))
            ////console.log("subcats---: ", JSON.stringify(subcatids))

            const data = new FormData();
            data.append("categorymaps", new Blob([JSON.stringify(subcatids)], { type: 'application/json' }));
            data.append("designupload", new Blob([JSON.stringify(uploadjson)], { type: 'application/json' }))

            axios
                .post(BASE_URL + "/design/uploadDesignEdit",
                    data,
                    { withCredentials: true })
                .then(function (response) {
                    ////console.log(response);
                    if (response.data.status === "OK" && response.status == 200) {
                        setbackdrop(false)
                        //setbackdropstats(false)
                        setopensnack(true);
                        setsnackmsg("Details Edited Successfully")
                        setopeneditmodal(false)
                        setviewformModal(false)
                        props.onCallBack()






                    }
                    else {
                        setbackdrop(false)
                        setopensnack(true);
                        setsnackmsg("Details Edit Failed")
                    }
                })
                .catch(function (errors) {



                    if (errors.response.status == 401 || errors.response.status == 403) {


                        setbackdrop(false)

                        setopensessiondialog(true)



                    } else {

                        setbackdrop(false)
                        setopensnack(true);
                        setsnackmsg("Details Edit Failed")

                    }

                });


        }







    }

    return (
        <>
            <ImageList variant="standard" cols={columns} gap={10} rowHeight={300} sx={{
                padding: '15px',
                borderStyle: 'solid', borderColor: 'rgb(95, 38, 73)',

            }}>
                {props.data.map((item) => (
                    <ImageListItem key={item.sysDesignId}
                        style={{ borderStyle: 'solid', borderRadius: '10px', borderWidth: '2px' }}
                    >

                        <img
                            src={BASE_URL + "/design/" + "images" + "/" + item.filename}
                            //srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={"image"}
                            loading="lazy"
                            style={{ borderRadius: '10px', height: '100%', width: '100%', objectFit: "contain" }}
                            onClick={(event) => {
                                //handleViewOpen(event, cellValues);
                                handleImageclick(item.filename);

                            }}
                        />

                        <ImageListItemBar
                            title={item.user.full_name}
                            subtitle={""}
                            style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                            actionIcon={
                                <Tooltip title='Info'>
                                    <IconButton
                                        sx={{ color: 'rgb(255, 255, 255)' }}
                                        aria-label={`info about ${item.title}`}
                                        onClick={(e) => handleviewclick(e, item)}
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            {/**view modal */}
            <Modal
                open={viewformModal}
                onClose={handleviewformModalClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style2}>
                    <div style={{ alignItems: 'end', justifyItems: 'end', display: 'grid', marginBottom: '-40px' }}>
                        <Tooltip title='Close'>
                            <IconButton
                                sx={{
                                    color: 'rgb(0, 0, 0)'
                                }}
                                onClick={(event) => {
                                    handleviewformModalClose(event)


                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>

                    </div>

                    <Card sx={{ width: 'auto', height: 'auto', alignItems: 'center', justifyItems: 'center', boxShadow: 10, borderRadius: 3, padding: 2, display: 'grid', backgroundColor: "#f5f5f5" }}>

                        <CardMedia

                            component="img"
                            sx={{ width: '350px', height: '350px', padding: 2, objectFit: 'contain' }}
                            image={BASE_URL + "/design/" + "images" + "/" + filename}
                            alt="image"

                        />
                        <Divider sx={{ bgcolor: "secondary.light" }} orientation="horizontal" flexItem />

                        <Box sx={{ display: 'contents' }}>
                            <CardContent >

                                <Box
                                    component="form"
                                    sx={{
                                        marginTop: 4,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",

                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid container spacing={1} >

                                        <Grid item xs={6} md={3}  >

                                            <Typography variant="subtitle1" fontWeight="bold">
                                                Category:
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} md={3}>

                                            <Typography variant="subtitle1" >
                                                {cat}
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} md={3} >

                                            <Typography fontWeight="bold" variant="subtitle1">
                                                Products:
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} md={3}>

                                            <Typography variant="subtitle1" >
                                                {subcat}
                                            </Typography>

                                        </Grid>

                                        {/**-----------------------row 1 --------------------*/}
                                        <Grid item xs={6} md={3} >

                                            <Typography fontWeight="bold" variant="subtitle1">
                                                Width:
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} md={3}>

                                            <Typography variant="subtitle1" >
                                                {width} pixels
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} md={3} >

                                            <Typography fontWeight="bold" variant="subtitle1">
                                                Height:
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} md={3}>

                                            <Typography variant="subtitle1" >
                                                {height} pixels
                                            </Typography>

                                        </Grid>

                                        {/**-----------------------row 2 --------------------*/}
                                        <Grid item xs={6} md={3} >

                                            <Typography fontWeight="bold" variant="subtitle1">
                                                Hooks:
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} md={3}>

                                            <Typography variant="subtitle1" >
                                                {hooks}
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} md={3} sx={{ display: 'flex', alignItems: 'center' }} >

                                            <Typography fontWeight="bold" variant="subtitle1">
                                                Drafting Order:
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <Typography variant="subtitle1" >
                                                {dentorder}
                                            </Typography>




                                        </Grid>
                                        {/**-----------------------row 3 --------------------*/}
                                        <Grid item xs={6} md={3} >

                                            <Typography fontWeight="bold" variant="subtitle1">
                                                Uploaded By:
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} md={3}>

                                            <Typography variant="subtitle1" >
                                                {uploadedby}
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                    {props.button ?
                                        <Grid container spacing={1}>
                                            <Grid item xs={6} md={3}>

                                                <Button sx={{ margin: 2 }} variant="outlined" color="success" onClick={handleApprove} >Approve</Button>

                                            </Grid>
                                            <Grid item xs={6} md={3}>

                                                <Button sx={{ margin: 2 }} variant="outlined" color="error" onClick={handleReject}>Reject</Button>

                                            </Grid>
                                            <Grid item xs={6} md={3}>

                                                <Button sx={{ margin: 2 }} variant="outlined" color="primary" onClick={handleEdit}>Edit</Button>

                                            </Grid>
                                        </Grid> : <></>}
                                </Box>

                            </CardContent>

                        </Box>

                    </Card>


                </Box>
            </Modal>
            {/** imageview modal */}

            <Modal
                open={viewimgModal}
                onClose={handleviewimgModalClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style3}>
                    <div style={{ alignItems: 'end', justifyItems: 'end', display: 'grid' }}>
                        <Tooltip title='Close'>
                            <IconButton
                                sx={{
                                    color: 'rgb(255, 255, 255)'
                                }}
                                onClick={(event) => {
                                    handleviewimgModalClose(event)


                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>

                    <div >
                        <ImageZoom

                            src={BASE_URL + "/design/" + "images" + "/" + img}
                            zoom="200"
                            width="100%"
                            height="100%"
                            alt="Loading"

                        />
                    </div>



                </Box>
            </Modal>
            {/* Reject Motif dialog */}
            <Dialog open={openrejectmodal} onClose={handlerejectmodalclose}>
                <DialogTitle>Reject Design</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to Reject this Design ?
                    </DialogContentText>
                    <div>
                        <TextField
                            error={errrejectreasonb}
                            helperText={errrejectreason}
                            margin="normal"
                            required
                            fullWidth
                            id="outlined-name"
                            label="Enter Reject Reason"
                            //defaultValue={userdata.state_name}
                            value={rejectreason}
                            onChange={(e) => setrejectreason(e.target.value)}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRejectButtonModal}>Reject</Button>
                    <Button onClick={handleCancelButtonModal}>Cancel</Button>
                </DialogActions>
                <Backdrop
                    sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdrop}
                //onClick={handlebackdropClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

            </Dialog>
            {/* Reject Motif dialog end */}

            {/* Approve Motif dialog */}
            <Dialog open={openapprovedialog} onClose={handleapprovemodalclose}>
                <DialogTitle>Approve Design</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to Approve this Design?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleApproveButtonModal}>Approve</Button>
                    <Button onClick={handleApproveCancelButtonModal}>Cancel</Button>
                </DialogActions>
                <Backdrop
                    sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdrop}
                //onClick={handlebackdropClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>


            </Dialog>
            {/* Approve Motif dialog end */}

            {/* Edit Motif dialog */}
            <Dialog open={openeditmodal} onClose={handleeditmodalclose}>
                <DialogTitle>Edit Details</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={6}>
                            <div>
                                <TextField

                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-select-district"
                                    label="Design ID"
                                    value={designid}
                                    disabled
                                // InputProps={{
                                //   readOnly: true,
                                // }}
                                //onChange={handleChange}
                                >
                                </TextField>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div>
                                <TextField
                                    error={errcatidb}
                                    helperText={errcatid}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-select-district"
                                    select
                                    label="Select Design Category"
                                    value={catid}
                                    onChange={
                                        (e) =>
                                            setcatid(e.target.value)
                                        // setsubcategories(subcategories.filter(op => op.))
                                        //setdistrict(centers.filter(op => op.center_dist_id === district))

                                    }
                                // InputProps={{
                                //   readOnly: true,
                                // }}
                                //onChange={handleChange}
                                >
                                    {categories.map((option) => (
                                        <MenuItem
                                            key={option.category_id}
                                            value={option.category_id}
                                        >
                                            {option.category_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div>

                                <FormControl sx={{ width: '100%', marginTop: 2 }} error={errformcontrolweaveb}>
                                    <InputLabel id="demo-multiple-name-label"> Products<span style={{ color: 'red' }}> *</span></InputLabel>
                                    <Select
                                        required
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        fullWidth
                                        multiple
                                        value={subcatid}
                                        onChange={(e) =>
                                            setsubcatid(e.target.value)
                                        }
                                        //label={<OutlinedInput label="Name" />}
                                        input={<OutlinedInput label="Name" />}
                                        MenuProps={{
                                            sx: {
                                                "&& .Mui-selected": {
                                                    backgroundColor: "#9cbcf0"
                                                },
                                                "&& .Mui-selected:hover": {
                                                    backgroundColor: "#9cbcf0"
                                                }
                                            },
                                            PaperProps: {
                                                style: {
                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                    width: 250,
                                                },

                                            }
                                        }}
                                    >
                                        {subcategories.map((option) => (
                                            <MenuItem

                                                key={option.subcategory_id}
                                                value={option.subcategory_id}
                                            >
                                                {option.subcategory_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error={errformhelperweaveb}>{errweaveid}</FormHelperText>
                                </FormControl>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div>
                                <TextField
                                    error={errwidthb}
                                    helperText={errwidth}
                                    margin="normal"
                                    required
                                    fullWidth
                                    disabled
                                    id="outlined-select-district"
                                    label="Width"
                                    value={widthedit}
                                    onChange={(e) =>
                                        setwidthedit(e.target.value)
                                        //setdistrict(centers.filter(op => op.center_dist_id === district))

                                    }
                                // InputProps={{
                                //   readOnly: true,
                                // }}
                                //onChange={handleChange}
                                >
                                </TextField>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div>
                                <TextField
                                    error={errheightb}
                                    helperText={errheight}
                                    margin="normal"
                                    required
                                    fullWidth
                                    disabled
                                    id="outlined-select-district"
                                    label="Height"
                                    value={heightedit}
                                    onChange={(e) =>
                                        setheightedit(e.target.value)
                                        //setdistrict(centers.filter(op => op.center_dist_id === district))

                                    }
                                // InputProps={{
                                //   readOnly: true,
                                // }}
                                //onChange={handleChange}
                                >
                                </TextField>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div>
                                <TextField
                                    error={errhooksb}
                                    helperText={errhooks}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-select-district"
                                    label="Hooks"
                                    value={hooksedit}
                                    onChange={(e) =>
                                        sethooksedit(e.target.value)
                                        //setdistrict(centers.filter(op => op.center_dist_id === district))

                                    }
                                // InputProps={{
                                //   readOnly: true,
                                // }}
                                //onChange={handleChange}
                                >
                                </TextField>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div>
                                <TextField
                                    error={errdentorderidb}
                                    helperText={errdentorderid}
                                    margin="normal"
                                    required
                                    fullWidth
                                    select
                                    id="outlined-number"
                                    label="Drafting Order"
                                    value={dentorderidedit}
                                    onChange={(e) =>
                                        setdentorderidedit(e.target.value)
                                        //setdistrict(centers.filter(op => op.center_dist_id === district))
                                    }
                                // onInput={(e) => {
                                //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                // }}
                                // InputProps={{
                                //   readOnly: true,
                                // }}
                                //onChange={handleChange}
                                >
                                    {dentingorders.map((option) => (
                                        <MenuItem
                                            key={option.drafting_order_id}
                                            value={option.drafting_order_id}
                                        >
                                            {option.drafting_order_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEdittButtonModal}>Edit</Button>
                    <Button onClick={handleEditCancelButtonModal}>Cancel</Button>
                </DialogActions>
                <Backdrop
                    sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdrop}
                //onClick={handlebackdropClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

            </Dialog>
            {/* Edit Motif dialog end */}

            <Snackbar
                open={opensnack}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={snackmsg}
            //action={action}
            />

            {/* session expiration dialog */}
            <Dialog
                open={opensessiondialog}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Session Expired!!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Session has expired..Please Login Again to Continue
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOKButton}>OK</Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdrop}
            //onClick={handlebackdropClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
