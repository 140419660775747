import React from 'react'
import { Cookies } from "react-cookie";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Link, Navigate, useNavigate } from 'react-router-dom'
import config from "./config.json"

import { jwtDecode } from "jwt-decode";
import axios from 'axios';

const cookies = new Cookies();


const SECRET = config.SECRET;
const BASE_URL = config.SERVER_URL;
var CryptoJS = require("crypto-js");






export const encrypter = () => {

}


export const decrypter = (data) => {

    //////console.log("data ", data)

    if (data === undefined) {
        return "false"

    } else {
        try {
            let bytes = CryptoJS.AES.decrypt(data, SECRET);
            let originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            return originalText;
        } catch (error) {

            return "false"

        }

    }

}


export function decodeToken(token) {
    try {
        return jwtDecode(token);
    } catch (error) {
        console.error('Error decoding JWT token:', error);
        return null; // Or throw a custom error for handling
    }
}

export function getFullNameFromToken(token) {
    const decode = decodeToken(token)
    return decode.name
}

export function getRoleNameFromToken(token) {
    const decode = decodeToken(token)
    return decode.role
}

export function getUserActivated(token) {
    const decode = decodeToken(token)
    return decode.userActivated
}

export function CheckTokenValid() {

    const navigate = useNavigate()

    axios
        .post(
            BASE_URL + "/sessionChecker",
            {},
            { withCredentials: true }
        )
        .then(function (response) {

        })
        .catch(function (error) {
            cookies.remove("sessionid")
            sessionStorage.removeItem("sessionid")
            navigate('/')
        });
}

export function PrintLog(tag,print){
    let printer = false;

    if (printer) {
        return console.log(tag,print)
    }
}






