import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import config from '../config.json'
import NavBarMeity from '../NavBar/NavBarMeity'
import { useNavigate } from 'react-router-dom'

import { decrypter } from "../Util"

import Banner1 from '../images/banner1.jpg';
import Banner2 from '../images/banner2.jpg';
import Banner3 from '../images/banner3.jpg';
import tick from '../images/verified.png'
import upload from '../images/upload.png'
import load from '../images/loading.png'


import CountUp from "react-countup";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Grid, Box } from "@mui/material";
import { CardActionArea } from '@mui/material';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import axios from 'axios';
import SideBarSuperAdmin from "../SideBar/SideBarSuperAdmin";

import { useIdleTimer } from 'react-idle-timer'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from "@mui/material";

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;
const SECRET = config.SECRET;

export default function MeityDashboard() {
    const navigate = useNavigate()

    // let cookiedata = decrypter(cookies.get("sessionid"))


    // let userdata = cookiedata.userdata
    // let jwt = cookiedata.token

    // let role = cookiedata.userdata.user.roles.map(op => op.role_name)

    // let userrole = role[0]

    // let KEY = 'Bearer ' + jwt;

    const [opensessiondialog, setopensessiondialog] = useState(false)


    // const handleOKButton = () => {
    //     navigate('/')
    //     cookies.remove("userdata")
    //     cookies.remove("loginstatus")
    //     cookies.remove("token")
    // }


    // const onIdle = () => {

    //     ////console.log("Idle")

    //     const headers = {
    //         Authorization: KEY
    //     };


    //     axios
    //         .post(BASE_URL + "/sessionChecker",
    //             {},
    //             { headers: headers })
    //         .then(function (response) {
    //             ////console.log(response);
    //             if (response.status == 200) {


    //             }

    //         })
    //         .catch(function (errors) {
    //             if (errors.response.status == 401 || errors.response.status == 403) {

    //                 setopensessiondialog(true)

    //             } else {


    //             }
    //         });
    // }

    // const onActive = () => {
    //     ////console.log("Active")
    // }

    // const onAction = () => {

    // }

    // const abc = useIdleTimer({
    //     onIdle,
    //     onActive,
    //     onAction,
    //     timeout: 5000,
    //     throttle: 500
    // })



    const cardStyle = {
        background: "#e8d4e0"
    }


    const [approvedesign, setapprovedesign] = useState()
    const [rejectdesign, setrejectdesign] = useState()
    const [submitdesign, setsubmitdesign] = useState()
    const [totaldesign, settotaldesign] = useState()

    const [approvemotif, setapprovemotif] = useState()
    const [rejectmotif, setrejectmotif] = useState()
    const [submitmotif, setsubmitmotif] = useState()
    const [totalmotif, settotalmotif] = useState()

    useEffect(() => {

        // const headers = {
        //     Authorization: KEY
        // };



        const getdesignstats = axios.post(BASE_URL + '/design/meity/getStats',{}, { withCredentials: true })
        const getmotifstats = axios.post(BASE_URL + '/motif/meity/getStats', {}, { withCredentials: true })


        Promise.all([getdesignstats, getmotifstats])
            .then(function (responses) {

                //setunmapped(responses[0].data.Beneficiaries)
                //////console.log("design", responses[0].data.Status)

                setapprovedesign(responses[0].data.Approved)
                settotaldesign(responses[0].data.Uploaded)
                setsubmitdesign(responses[0].data.Pending)

                setapprovemotif(responses[1].data.Approved)
                setsubmitmotif(responses[1].data.Pending)
                settotalmotif(responses[1].data.Uploaded)



            }).catch(function (errors) {

            });

    }, [])



    //userdata.user.roles.map(op => )
    return (
        <>
            <div>
                <NavBarMeity
                    title={TITLE}

                />
            </div>
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={Banner1} className="d-block w-100" alt="image1" />
                            </div>
                            {/* <div className="carousel-item">
                                <img src={Banner2} className="d-block w-100" alt="image2" />
                            </div>
                            <div className="carousel-item">
                                <img src={Banner3} className="d-block w-100" alt="image2" />
                            </div> */}

                        </div>
                        {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button> */}
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="row-mx5 mb-5">
                <div className="container">
                    <Box
                        component="form"
                        sx={{
                            marginTop: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={1} sx={{ mt: 2 }}>
                            <Grid item xs={12} md={6} lg={4} >
                                <div className="card radius-10 " style={cardStyle}>
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <p className="mb-0 text" style={{ fontWeight: 'bold' }}>Total Designs Uploaded</p>
                                                <h4 className="my-1 text" style={{ color: 'black' }}><CountUp end={totaldesign} /></h4>

                                            </div>
                                            <div className="widgets-icons-2 rounded-circle ms-auto">
                                                <img src={upload} className="d-block w-32" alt="image2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} >
                                <div className="card radius-10 " style={cardStyle}>
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <p className="mb-0 text" style={{ fontWeight: 'bold' }} >Total Designs Pending for Approval</p>
                                                <h4 className="my-1 text " style={{ color: 'black' }} ><CountUp end={submitdesign} /></h4>

                                            </div>
                                            <div className="widgets-icons-2 rounded-circle ms-auto">
                                                <img src={load} className="d-block w-32" alt="image2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} >
                                <div className="card radius-10 " style={cardStyle}>
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <p className="mb-0 text" style={{ fontWeight: 'bold' }} >Total Designs Approved</p>
                                                <h4 className="my-1 text" style={{ color: 'black' }}><CountUp end={approvedesign} /></h4>

                                            </div>
                                            <div className="widgets-icons-2 rounded-circle ms-auto">
                                                <img src={tick} className="d-block w-32" alt="image2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6} lg={4} >
                                <div className="card radius-10 " style={cardStyle}>
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <p className="mb-0 text" style={{ fontWeight: 'bold' }}>Total Motifs Uploaded</p>
                                                <h4 className="my-1 text" style={{ color: 'black' }} ><CountUp end={totalmotif} /></h4>

                                            </div>
                                            <div className="widgets-icons-2 rounded-circle ms-auto">
                                                <img src={upload} className="d-block w-32" alt="image2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} >
                                <div className="card radius-10 " style={cardStyle}>
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <p className="mb-0 text" style={{ fontWeight: 'bold' }}>Total Motifs Pending for Approval</p>
                                                <h4 className="my-1 text" style={{ color: 'black' }}><CountUp end={submitmotif} /></h4>

                                            </div>
                                            <div className="widgets-icons-2 rounded-circle ms-auto">
                                                <img src={load} className="d-block w-32" alt="image2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <div className="card radius-10 " style={cardStyle}>
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <p className="mb-0 text" style={{ fontWeight: 'bold' }}>Total Motifs Approved</p>
                                                <h4 className="my-1 text" style={{ color: 'black' }}><CountUp end={approvemotif} /></h4>

                                            </div>
                                            <div className="widgets-icons-2 rounded-circle ms-auto">
                                                <img src={tick} className="d-block w-32" alt="image2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* session expiration dialog */}
                    {/* <Dialog
                        open={opensessiondialog}

                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Session Expired!!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Session has expired..Please Login Again to Continue
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleOKButton}>OK</Button>
                        </DialogActions>
                    </Dialog> */}
                    {/* session expiration dialog end */}

                </div>
            </div>


        </>
    )
}
