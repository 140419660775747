import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import config from '../../config.json'
import NavBarSuperAdmin from '../../NavBar/NavBarSuperAdmin'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import { decrypter } from "../../Util"
import regex from "../../regex.json"

import Banner1 from '../../images/banner1.jpg'

import axios from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
    DataGrid, GridToolbar,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { Grid } from "@mui/material";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import makeStyles from '@mui/styles/makeStyles';



const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;

const TITLE = config.TITLE;
const SECRET = config.SECRET;

const style2 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
}

const useStyle = makeStyles({
    root: {
        "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center",
            fontWeight: 'bold'
        },
        "& .MuiDataGrid-cellContent": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center"
        },
        "& .MuiTypography-string": {
            textOverflow: "ellipsis",
            whiteSpace: "break-spaces",
            lineHeight: 2,
            textAlign: "center"
        },
        boxShadow: 2,
        '& .MuiTablePagination-selectLabel': {
            marginTop: 'auto'
        },
        '& .MuiTablePagination-displayedRows': {
            marginTop: 'auto'
        }
    }
});


export default function ManageDistrict() {

    const navigate = useNavigate()
    // let cookiedata = decrypter(cookies.get("sessionid"))


    // let userdata = cookiedata.userdata
    // let jwt = cookiedata.token

    // let role = cookiedata.userdata.user.roles.map(op => op.role_name)

    // let userrole = role[0]

    // let KEY = 'Bearer ' + jwt;

     var count = 0;

    const classes = useStyle();

    const [districts, setdistricts] = useState([])
    const [states, setstates] = useState([])
    const [refreshdata, setrefreshdata] = useState(0)
    const [pageSize, setPageSize] = useState(10);
    const [opensnack, setopensnack] = useState(false);
    const [snackmsg, setsnackmsg] = useState();
    const [adddistrictmodal, setadddistrictmodal] = useState(false)
    const [editdistrictmodal, seteditdistrictmodal] = useState(false)
    const [backdrop, setbackdrop] = useState(false)
    const [opensessiondialog, setopensessiondialog] = useState(false)
    const [opendeletedialog, setopendeletedialog] = useState(false)


    const [districtid, setdistrictid] = useState("")
    const [stateid, setstateid] = useState("")
    const [errstatename, seterrstatename] = useState("")
    const [errstatenameb, seterrstatenameb] = useState(false)

    const [districtname, setdistrictname] = useState("")
    const [errdistrictname, seterrdistrictname] = useState("")
    const [errdistrictnameb, seterrdistrictnameb] = useState(false)

    const [districtcode, setdistrictcode] = useState("")
    const [errdistrictcode, seterrdistrictcode] = useState("")
    const [errdistrictcodeb, seterrdistrictcodeb] = useState(false)


    const columns = [
        {
            field: 'district_id', headerName: 'ID', flex: 0.5, align: 'center', headerAlign: 'center'
        },
        {
            field: 'state_name', headerName: 'State Name', flex: 1, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                // let result = "";
                // if (cellValues.row.state) {
                //     if (cellValues.row.state.state_name) {

                //         //result.push(cellValues.row.state.state_name);

                //         result = cellValues.row.state.state_name

                //     }
                // }
                // else {
                //     result = ""
                // }
                // return result;

                return cellValues.row.state.state_name;
            }
        },
        {
            field: 'district_name', headerName: 'District Name', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'district_code', headerName: 'District Code', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: "Action",
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleEditClick(event, cellValues);
                            }}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                        {/* <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleDeleteClick(event, cellValues);
                            }}
                        >
                            <DeleteIcon></DeleteIcon>
                        </IconButton> */}
                        {/* <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleFormEditOpen(event, cellValues.row);
                            }}
                        >
                            <FileOpenIcon></FileOpenIcon>
                        </IconButton> */}
                    </>
                );
            }, flex: 1, align: 'center', headerAlign: 'center'
        }

    ]

    useEffect(() => {



        const getstates = axios.post(BASE_URL + '/getAllStates', {}, { withCredentials: true })
        const getdistricts = axios.post(BASE_URL + '/getAllDistricts', {}, { withCredentials: true })

        Promise.all([getstates, getdistricts])
            .then(function (responses) {

                //setunmapped(responses[0].data.Beneficiaries)
                setstates(responses[0].data.states)
                setdistricts(responses[1].data.Districts)

            }).catch(function (errors) {

            });

        setrefreshdata(0)


    }, [refreshdata])

    const handleAddDistrict = () => {

        setstateid("")
        seterrstatename("")
        seterrstatenameb(false)
        setdistrictname("")
        seterrdistrictname("")
        seterrdistrictnameb(false)
        setdistrictcode("")
        seterrdistrictcode("")
        seterrdistrictcodeb(false)

        setadddistrictmodal(true)


    }

    const handleEditClick = (event, cellValues) => {


        setdistrictid(cellValues.row.district_id)
        setstateid(cellValues.row.state_id)
        setdistrictname(cellValues.row.district_name)
        setdistrictcode(cellValues.row.district_code)
        seteditdistrictmodal(true)

    }

    const handleDeleteClick = (event, cellValues) => {

        setdistrictid(cellValues.row.district_id)
        setopendeletedialog(true)

    }

    const handleCloseSnack = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setopensnack(false);

    }

    const handleadddistrictmodal = () => {
        setadddistrictmodal(false)
    }

    const handleeditdistrictmodal = () => {
        seteditdistrictmodal(false)
    }

    const handleadddistrictbuttonclick = (e) => {

        e.preventDefault();

        if (validation()) {

            // const headers = {
            //     Authorization: KEY
            // };


            setbackdrop(true)

            axios
                .post(BASE_URL + "/addDistrict",
                    {
                        state_id: stateid,
                        district_name: districtname,
                        district_code: districtcode
                    },
                    { withCredentials: true })
                .then(function (response) {
                    ////console.log(response);
                    if (response.data.status === "ok" && response.status == 200) {
                        setbackdrop(false)
                        setsnackmsg("District Added Successfully")
                        setopensnack(true);
                        setadddistrictmodal(false)

                        // setTimeout(function () {
                        //     window.location.reload(false);
                        // }, 3000);

                        setrefreshdata(count + 1)




                    }
                    else {
                        setbackdrop(false)
                        setadddistrictmodal(false)
                        setsnackmsg("District Addition Failed")
                        setopensnack(true);
                    }
                })
                .catch(function (errors) {



                    if (errors.response.status == 401 || errors.response.status == 403) {


                        setadddistrictmodal(false)

                        setopensessiondialog(true)



                    } else {

                        setbackdrop(false)
                        setadddistrictmodal(false)
                        setsnackmsg("District Addition Failed")
                        setopensnack(true);

                    }

                });

        }

    }

    const handleeditdistrictbuttonclick = (e) => {

        e.preventDefault();

        if (validation()) {

            // const headers = {
            //     Authorization: KEY
            // };


            setbackdrop(true)

            axios
                .post(BASE_URL + "/editDistrict",
                    {
                        district_id: districtid,
                        state_id: stateid,
                        district_name: districtname,
                        district_code: districtcode
                    },
                    { withCredentials: true })
                .then(function (response) {
                    ////console.log(response);
                    if (response.data.status === "ok" && response.status == 200) {
                        setbackdrop(false)
                        setsnackmsg("District Edited Successfully")
                        setopensnack(true);
                        seteditdistrictmodal(false)

                        // setTimeout(function () {
                        //     window.location.reload(false);
                        // }, 3000);

                        setrefreshdata(count + 1)




                    }
                    else {
                        setbackdrop(false)
                        seteditdistrictmodal(false)
                        setsnackmsg("District Edit Failed")
                        setopensnack(true);
                    }
                })
                .catch(function (errors) {



                    if (errors.response.status == 401) {


                        seteditdistrictmodal(false)

                        setopensessiondialog(true)



                    } else {


                        setbackdrop(false)
                        seteditdistrictmodal(false)
                        setsnackmsg("District Edit Failed")
                        setopensnack(true);

                    }

                });

        }

    }



    const handleYesButton = () => {

        // const headers = {
        //     Authorization: KEY
        // };


        axios
            .post(BASE_URL + "/deleteDistrict", { district_id: districtid }, { withCredentials: true })
            .then(function (response) {

                ////console.log(response);
                if (response.data.status === "ok" && response.status == 200) {
                    //setbackdrop(false)
                    setsnackmsg("District Deleted Successfully")
                    setopensnack(true);
                    setopendeletedialog(false)
                    //setaddproofmodal(false)

                    // setTimeout(function () {
                    //     window.location.reload(false);
                    // }, 3000);
                    setrefreshdata(count + 1)

                }
                else {
                    //setbackdrop(false)

                    setopendeletedialog(false)
                    setsnackmsg("District Delete Failed")
                    setopensnack(true);
                }
            })
            .catch(function (errors) {
                //setbackdrop(false)

                if (errors.response.status == 401) {

                    //setaddstatemodal(false)
                    setopendeletedialog(false)
                    setopensessiondialog(true)

                } else {

                    setopendeletedialog(false)
                    setsnackmsg("District Delete Failed")
                    setopensnack(true);

                }



            });

    }

    const handleNoButton = () => {
        setopendeletedialog(false)
    }

    function validation() {
        let isvalid = true;

        if (stateid === "") {
            seterrstatename("State Name Cannot be Empty")
            seterrstatenameb(true)
            isvalid = false
        } else {

            seterrstatename("")
            seterrstatenameb(false)
        }


        if (districtname === "") {
            seterrdistrictname("District Name Cannot be Empty")
            seterrdistrictnameb(true)
            isvalid = false
        } else if (!districtname.match(regex.letterandspace)) {
            seterrdistrictname("Please Enter Valid District Name")
            seterrdistrictnameb(true)
            isvalid = false

        } else {
            seterrdistrictname("")
            seterrdistrictnameb(false)
        }


        if (districtcode === "") {

            seterrdistrictcode("District Code Cannot be Empty")
            seterrdistrictcodeb(true)
            isvalid = false

        } else if (!districtcode.match(regex.letterandspace)) {

            seterrdistrictcode("Please Enter Valid District Name")
            seterrdistrictcodeb(true)
            isvalid = false

        } else {
            seterrdistrictcode("")
            seterrdistrictcodeb(false)
        }

        return isvalid;

    }

    const handleOKButton = () => {
        navigate('/')
        cookies.remove("sessionid")
    }

    return (
        <>
            <div>
                <NavBarSuperAdmin
                    title={TITLE}

                />
            </div>
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={Banner1} className="d-block w-100" alt="Banner1" />
                            </div>
                        </div>
                        {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button> */}
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="row mx-5 mb-5">
                <div className="container">
                    <h1>
                        <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<AddIcon></AddIcon>} onClick={handleAddDistrict} >Add New District</Button>
                        <div className="abc text-black text-center col 10 p-1 mt-1">
                            <strong><h2> Manage Districts </h2></strong>
                        </div>
                    </h1>
                    <Box
                        justifyContent='center'
                    //</div>sx={{ height: 700, width: '100%' }}
                    >
                        <DataGrid
                            className={classes.root}
                            sx={{
                                boxShadow: 2,
                                '& .MuiTablePagination-selectLabel': {
                                    marginTop: 'auto'
                                },
                                '& .MuiTablePagination-displayedRows': {
                                    marginTop: 'auto'
                                }
                            }}
                            rows={districts}
                            getRowId={(r) => r.district_id}
                            columns={columns}
                            components={{
                                Toolbar: GridToolbar,
                                //Pagination: CustomPagination
                            }}
                            disableSelectionOnClick
                            autoHeight
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            pagination
                            rowsPerPageOptions={[10, 25, 50, 100]}

                        //checkboxSelection={false}
                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setSelectedRows(newSelectionModel);
                        // }}

                        />
                    </Box>
                    <Snackbar
                        open={opensnack}
                        autoHideDuration={6000}
                        onClose={handleCloseSnack}
                        message={snackmsg}
                    //action={action}
                    />
                    {/* add district modal */}
                    <Modal
                        open={adddistrictmodal}
                        onClose={handleadddistrictmodal}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        <Box sx={style2}>
                            <Grid container spacing={1}>

                                <Grid item xs={4}>

                                    <Card sx={{ width: 500, alignItems: 'center', boxShadow: 10, borderRadius: 3, padding: 2 }}>
                                        <h1>
                                            <div className="abc text-black text-center col 10 p-1 mt-1">
                                                <strong><h2> Add New District </h2></strong>
                                            </div>
                                        </h1>
                                        <div>
                                            <TextField
                                                error={errstatenameb}
                                                helperText={errstatename}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="outlined-name"
                                                select
                                                label="Enter State Name"
                                                //defaultValue={userdata.state_name}
                                                value={stateid}
                                                onChange={(e) => setstateid(e.target.value)}
                                            >
                                                {states.map((option) => (
                                                    <MenuItem
                                                        key={option.state_id}
                                                        value={option.state_id}
                                                    >
                                                        {option.state_name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div>
                                            <TextField
                                                error={errdistrictnameb}
                                                helperText={errdistrictname}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="outlined-name"
                                                label="Enter District Name"
                                                //defaultValue={userdata.state_name}
                                                value={districtname}
                                                onChange={(e) => setdistrictname(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                error={errdistrictcodeb}
                                                helperText={errdistrictcode}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="outlined-name"
                                                label="Enter District Code"
                                                //defaultValue={userdata.state_name}
                                                value={districtcode}
                                                onChange={(e) => setdistrictcode(e.target.value)}
                                            />
                                        </div>

                                        <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
                                            <Button variant="contained" onClick={handleadddistrictbuttonclick}>
                                                Submit
                                            </Button>
                                        </Grid>

                                        <Backdrop
                                            sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                            open={backdrop}
                                        //onClick={handlebackdropClose}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>

                                    </Card>

                                </Grid>

                            </Grid>

                        </Box>

                    </Modal>
                    {/* add district modal end */}
                    {/* edit district modal */}
                    <Modal
                        open={editdistrictmodal}
                        onClose={handleeditdistrictmodal}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        <Box sx={style2}>
                            <Grid container spacing={1}>

                                <Grid item xs={4}>

                                    <Card sx={{ width: 500, alignItems: 'center', boxShadow: 10, borderRadius: 3, padding: 2 }}>
                                        <h1>
                                            <div className="abc text-black text-center col 10 p-1 mt-1">
                                                <strong><h2> Edit District </h2></strong>
                                            </div>
                                        </h1>
                                        <div>
                                            <TextField

                                                margin="normal"
                                                disabled
                                                fullWidth
                                                id="outlined-name"
                                                label="State ID"
                                                //defaultValue={userdata.state_name}
                                                value={districtid}
                                            //onChange={(e) => setproofname(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                error={errstatenameb}
                                                helperText={errstatename}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="outlined-name"
                                                select
                                                label="Enter State Name"
                                                //defaultValue={userdata.state_name}
                                                value={stateid}
                                                onChange={(e) => setstateid(e.target.value)}
                                            >
                                                {states.map((option) => (
                                                    <MenuItem
                                                        key={option.state_id}
                                                        value={option.state_id}
                                                    >
                                                        {option.state_name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div>
                                            <TextField
                                                error={errdistrictnameb}
                                                helperText={errdistrictname}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="outlined-name"
                                                label="Enter District Name"
                                                //defaultValue={userdata.state_name}
                                                value={districtname}
                                                onChange={(e) => setdistrictname(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                error={errdistrictcodeb}
                                                helperText={errdistrictcode}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="outlined-name"
                                                label="Enter District Code"
                                                //defaultValue={userdata.state_name}
                                                value={districtcode}
                                                onChange={(e) => setdistrictcode(e.target.value)}
                                            />
                                        </div>

                                        <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
                                            <Button variant="contained" onClick={handleeditdistrictbuttonclick}>
                                                Submit
                                            </Button>
                                        </Grid>

                                        <Backdrop
                                            sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                            open={backdrop}
                                        //onClick={handlebackdropClose}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>

                                    </Card>

                                </Grid>

                            </Grid>

                        </Box>

                    </Modal>
                    {/* edit district modal end */}
                    {/* delete dialog */}
                    <Dialog
                        open={opendeletedialog}
                        //onClose={handledeletedialogclose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"ARE YOU SURE YOU WANT TO DELETE?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Once deleted it cannot be undone
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleYesButton}>YES</Button>
                            <Button onClick={handleNoButton} autoFocus>
                                NO
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* delete dialog end */}
                    {/* session expiration dialog */}
                    <Dialog
                        open={opensessiondialog}

                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Session Expired!!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Session has expired..Please Login Again to Continue
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleOKButton}>OK</Button>
                        </DialogActions>
                    </Dialog>
                    {/* session expiration dialog end */}

                </div>
            </div>
        </>
    )
}
