import React from 'react'
import config from '../config.json'
import NavBarHome from '../NavBar/NavBarHome';
import Banner1 from '../images/banner1.jpg';
import Banner2 from '../images/banner2.jpg';
import Banner3 from '../images/banner3.jpg';
import ObjectiveBanner from '../images/objective.jpg'


import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import e1 from "../images/design.png"
import Typography from '@mui/material/Typography';
import Slide from 'react-reveal/Slide';

import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';

import ImageGallery from 'react-image-gallery';


const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

export default function Objective() {
  return (
    <>
      <NavBarHome title={TITLE}></NavBarHome>
      {/* carousel starts */}
      <div className="row">
        <div className="container ">
          <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={ObjectiveBanner} className="d-block w-100" alt="image1" />
              </div>

            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </button>
          </div>
        </div>
      </div>
      {/* carousel ends */}
      <div className="container-fluid">
        <div className=" text-black text-center col 10 p-1 mt-5">
          <h3> OBJECTIVES OF ONLINE DESIGN REPOSITORY </h3>
        </div>
        <Box
          component="form"
          display="flex"
          justifyContent="center"
          alignItems="center"

          sx={{
            marginTop: 2,
            // display: "flex",
            // flexDirection: "column"


          }}
          noValidate
          autoComplete="off"
        >
          <Slide left>

            <Grid container spacing={6} direction="row"
              alignItems='center'
              justifyContent='center'
              padding='4px'>


              <Grid item lg={4} md={6} xs={12} style={{
                alignItems: 'center',
                display: 'grid', justifyItems: 'center'
              }} >
                <img src={e1} className="w-20" alt="" />

              </Grid>

              <Grid item lg={8} md={6} xs={12} >
                
                <Typography sx={{ marginTop: 2 }} align='justify' style={{ padding: "30px" }}>
                  The objectives of Online Design Repository are as follows:
                </Typography>
                <Typography variant="subtitle1" align='justify' sx={{ marginTop: 2 }} style={{ marginLeft: "30px", display: "flex" }}>
                  <CheckCircleOutline color='success' sx={{ marginRight: 2, marginTop: 0.5 }} />
                  Preserve and promote traditional and contemporary designs.

                </Typography>
                <Typography variant="subtitle1" align='justify' sx={{ marginTop: 2 }} style={{ marginLeft: "30px", display: "flex" }}>
                  <CheckCircleOutline color='success' sx={{ marginRight: 2, marginTop: 0.5 }} />
                  Establish a centralized platform for creators, artists, and researchers.


                </Typography>
                <Typography variant="subtitle1" align='justify' sx={{ marginTop: 2 }} style={{ marginLeft: "30px", display: "flex" }}>
                  <CheckCircleOutline color='success' sx={{ marginRight: 2, marginTop: 0.5 }} />
                  Showcase designs from beneficiaries of CDAC's capacity-building program.


                </Typography>
              
                <Typography variant="subtitle1" align='justify' sx={{ marginTop: 2 }} style={{ marginLeft: "30px", display: "flex" }}>
                  <CheckCircleOutline color='success' sx={{ marginRight: 2, marginTop: 0.5 }} />
                  Boost economic development by integrating <br className='narrow'></br> traditional designs into modern applications.


                </Typography>


              </Grid>
            </Grid>

          </Slide>

        </Box>

        




      </div>
    </>
  )
}
