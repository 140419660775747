import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, Navigate, Link } from "react-router-dom";
import {
    ProSidebarProvider, Sidebar,
    Menu,
    MenuItem,
    SubMenu,
    useProSidebar,
    menuClasses,
    MenuItemStyles,
} from 'react-pro-sidebar';
import { Cookies } from 'react-cookie';
import config from '../config.json'
import { Box, IconButton, Typography, useTheme } from "@mui/material";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

import SADashboard2 from "../Super Admin/SADashboard2";
import ManageProofs from "../Super Admin/Master Management/ManageProofs";

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    //const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: "#212121",
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    );
};

var CryptoJS = require("crypto-js");
const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const SECRET = config.SECRET;
const TITLE = config.TITLE;

export default function SideBarSuperAdmin() {

    if (cookies.get("loginstatus") != undefined) {

        var bytes = CryptoJS.AES.decrypt(cookies.get("loginstatus"), SECRET);
        var status = bytes.toString(CryptoJS.enc.Utf8);

    }

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");


    return (
        <>
            <Box
                sx={{
                    "& .pro-sidebar-inner": {
                        background: "#e8d4e0",
                    },
                    "& .pro-icon-wrapper": {
                        backgroundColor: "transparent !important",
                    },
                    "& .pro-inner-item": {
                        padding: "5px 35px 5px 20px !important",
                    },
                    "& .pro-inner-item:hover": {
                        color: "#e8d4e0 !important",
                    },
                    "& .pro-menu-item.active": {
                        color: "#e8d4e0 !important",
                    },
                    background: "#e8d4e0"
                }}
            >
                <ProSidebarProvider collapsed={isCollapsed}>
                    <Menu iconShape="square">
                        {/* LOGO AND MENU ICON */}
                        <MenuItem
                            onClick={() => setIsCollapsed(!isCollapsed)}
                            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                            style={{
                                margin: "10px 0 20px 0",
                                color: "#212121",
                            }}
                        >
                            {!isCollapsed && (
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    ml="15px"
                                >
                                    <Typography variant="h6" color={"#212121"}>
                                        SUPER ADMIN PANNEL
                                    </Typography>
                                    <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                        <MenuOutlinedIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </MenuItem>

                        {!isCollapsed && (
                            <Box mb="25px">
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <img
                                        alt="profile-user"
                                        width="100px"
                                        height="100px"
                                        src={`../../assets/user.png`}
                                        style={{ cursor: "pointer", borderRadius: "50%" }}
                                    />
                                </Box>
                                <Box textAlign="center">
                                    <Typography
                                        variant="h4"
                                        color={"#212121"}
                                        fontWeight="bold"
                                        sx={{ m: "10px 0 0 0" }}
                                    >
                                        Indranil Ganguly
                                    </Typography>
                                    <Typography variant="h5" color={"#212121"}>
                                        Super Admin
                                    </Typography>
                                </Box>
                            </Box>
                        )}

                        <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                            <MenuItem
                                active={selected === "DashB"}
                                style={{
                                    color: "#212121",
                                }}
                                onClick={() => setSelected("DashB")}
                                icon={<HomeOutlinedIcon />}
                            >

                                <Link to="/adminbase" ><Typography>DashB</Typography></Link>
                            </MenuItem>

                            <Typography
                                variant="h6"
                                color={"#212121"}
                                sx={{ m: "15px 0 5px 20px" }}
                            >
                                Data
                            </Typography>
                            <MenuItem
                                active={selected === "ManageProofs"}
                                style={{
                                    color: "#212121",
                                }}
                                onClick={() => setSelected("ManageProofs")}
                                icon={<HomeOutlinedIcon />}
                            >

                                <Link to="/manageproofs" ><Typography>ManageProofs</Typography></Link>
                            </MenuItem>
                            <Item
                                title="Contacts Information"
                                to="/contacts"
                                icon={<ContactsOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Invoices Balances"
                                to="/invoices"
                                icon={<ReceiptOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />

                            <Typography
                                variant="h6"
                                color={"#212121"}
                                sx={{ m: "15px 0 5px 20px" }}
                            >
                                Pages
                            </Typography>
                            <Item
                                title="Profile Form"
                                to="/form"
                                icon={<PersonOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Calendar"
                                to="/calendar"
                                icon={<CalendarTodayOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="FAQ Page"
                                to="/faq"
                                icon={<HelpOutlineOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />

                            <Typography
                                variant="h6"
                                color={"#212121"}
                                sx={{ m: "15px 0 5px 20px" }}
                            >
                                Charts
                            </Typography>
                            <Item
                                title="Bar Chart"
                                to="/bar"
                                icon={<BarChartOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Pie Chart"
                                to="/pie"
                                icon={<PieChartOutlineOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Line Chart"
                                to="/line"
                                icon={<TimelineOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Geography Chart"
                                to="/geography"
                                icon={<MapOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </Box>
                    </Menu>
                </ProSidebarProvider>
            </Box>
            


        </>
    )
}
