import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import config from '../../config.json'

import { Link, Navigate, useNavigate } from 'react-router-dom'

import { decrypter } from "../../Util"

import { useTheme } from '@mui/material/styles';
import Banner1 from '../../images/banner1.jpg'

import axios from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
    DataGrid, GridToolbar,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { Grid } from "@mui/material";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import TextField from "@mui/material/TextField";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import { Tooltip } from '@mui/material';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import makeStyles from '@mui/styles/makeStyles';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import moment from 'moment';
import NavBarSubAdmin from "../../NavBar/NavBarSubAdmin";
import regex from "../../regex.json"
import ImageListMotifSubAdmin from "./ImageListMotifSubAdmin";
import TablePagination from '@mui/material/TablePagination';




const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;
const SECRET = config.SECRET;

const style2 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    maxWidth: '100%',
    maxHeight: '100vh',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    overflowX: 'auto',
    margin: 'auto'
}

const useStyle = makeStyles({
    root: {
        "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center",
            fontWeight: 'bold'
        },
        "& .MuiDataGrid-cellContent": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center"
        },
        "& .MuiTypography-string": {
            textOverflow: "ellipsis",
            whiteSpace: "break-spaces",
            lineHeight: 2,
            textAlign: "center"
        },
        boxShadow: 2,
        '& .MuiTablePagination-selectLabel': {
            marginTop: 'auto'
        },
        '& .MuiTablePagination-displayedRows': {
            marginTop: 'auto'
        }
    }
});

const useStylesCard = makeStyles({
    root: {

    },
    media: {
        "&:hover": {
            transform: "scale(3.5)"
        }
    }
});

export default function ViewMotifsSubAdmin() {
    const navigate = useNavigate()
    // let cookiedata = decrypter(cookies.get("sessionid"))


    // let userdata = cookiedata.userdata
    // let jwt = cookiedata.token

    // let role = cookiedata.userdata.user.roles.map(op => op.role_name)

    // let userrole = role[0]

    // let KEY = 'Bearer ' + jwt;

    const classes = useStyle()
    const classescard = useStylesCard()

    const [refresh, setrefresh] = useState(0)

    const [motifs, setmotifs] = useState([])

    const [viewformModal, setviewformModal] = useState(false)
    const [viewoption, setviewoption] = useState([])

    const [loading, setloading] = useState(true)
    const [pageSize, setPageSize] = useState(10);

    const [motifid, setmotifid] = useState('')
    const [cat, setcat] = useState('')
    const [subcat, setsubcat] = useState('')
    const [height, setheight] = useState('')
    const [width, setwidth] = useState('')
    const [hooks, sethooks] = useState('')
    const [ppi, setppi] = useState('')
    const [epi, setepi] = useState('')
    const [color, setcolor] = useState('')
    const [dentinch, setdentinch] = useState('')
    const [dentorderid, setdentorderid] = useState('')
    const [weaves, setweaves] = useState('')
    const [uploadedby, setuploadedby] = useState('')
    const [filename, setfilename] = useState('')
    const [status, setstatus] = useState('')
    const [remarks, setremarks] = useState('')
    const [viewMovementModal, setviewMovementModal] = useState(false)
    const [movementarr, setmovementarr] = useState([])

    const [us, setus] = useState('')
    const [zoom, setzoom] = useState(false)


    const [openapprovedialog, setopenapprovedialog] = useState(false)
    const [rejectreason, setrejectreason] = useState('')
    const [errrejectreasonb, seterrrejectreasonb] = useState(false)
    const [errrejectreason, seterrrejectreason] = useState('')
    const [openrejectmodal, setopenrejectmodal] = useState(false)
    const [opensnack, setopensnack] = useState(false)
    const [snackmsg, setsnackmsg] = useState(false)

    const [backdrop, setbackdrop] = useState(false);

    const [page, pagechange] = useState(0)
    const [rowPerPage, rowperpagechange] = useState(10)
    const [totalcount, settotalcount] = useState(0)


    useEffect(() => {
        const headers = {
            Authorization: KEY,

        };

        setbackdrop(true)

        const params1 = new URLSearchParams();
        //params1.append("centerId", userdata.memp.cent.center_id);
        params1.append("offset", page);
        params1.append("pageSize", rowPerPage);

        axios.post(BASE_URL + '/motif/subadmin/getallPendingMotifs', params1, { withCredentials: true })
            .then(function (responses) {

                ////console.log("Motifs", responses.data.Motifs)
                //setunmapped(responses.data.Beneficiaries)
                //setproofs(responses.data.ProofTypes)
                setmotifs(responses.data.Motifs.content)
                settotalcount(responses.data.Motifs.totalElements)
                setbackdrop(false)

            })
            .catch(function (errors) {

            })

        setrefresh(0)



    }, [refresh, page, rowPerPage])




    const theme = useTheme();

    const Img = styled('img')({
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',


    });

    const handleMovementClick = (option) => {

        setviewMovementModal(true)
        setmovementarr(option.motifDesignStatus)
        setus(option.user.full_name)
        ////console.log("movement", movementarr)

    }

    const handleviewMovementModalClose = () => {
        setviewMovementModal(false)
    }

    const handleviewclick = (option) => {


        ////console.log("clicked")
        setviewformModal(true)
        ////console.log("options:", option.filename)

        setheight(option.height)
        setwidth(option.width)

        setuploadedby(option.user.full_name)
        setfilename(option.filename)
        setstatus(option.approvalStatus)
        setremarks(option.remarks)
        setmotifid(option.sysMotifId)





    }

    const handleviewformModalClose = () => {

        setviewformModal(false)
    }


    const handleApprove = () => {

        setopenapprovedialog(true)

    }


    const handleReject = () => {
        setopenrejectmodal(true)
        setrejectreason("")
        seterrrejectreason("")
        seterrrejectreasonb(false)

    }

    const handleRejectButtonModal = () => {

        const headers = {
            Authorization: KEY,

        };

        setbackdrop(true)

        if (validation()) {
            const params1 = new URLSearchParams();
            params1.append("id", motifid);
            //params1.append("approve_rejected_by", userdata.memp.empId)
            params1.append("remarks", rejectreason)

            axios.post(BASE_URL + '/motif/subadmin/rejectmotif', params1, { withCredentials: true })
                .then(function (responses) {

                    ////console.log(responses);
                    if (responses.data.status === "OK" && responses.status == 200) {

                        setbackdrop(false)
                        setviewformModal(false)
                        setopenrejectmodal(false)
                        setopensnack(true)
                        setsnackmsg("Motif Rejected")
                        setrefresh(1)



                    }
                    else {

                    }

                })
                .catch(function (errors) {

                })
        }



    }

    const handlerejectmodalclose = () => {

        setopenrejectmodal(false)

    }

    function validation() {
        let isvalid = true;

        if (rejectreason === "") {
            seterrrejectreasonb(true)
            seterrrejectreason("Please Enter Reject Reason")
            isvalid = false;
        } else if (!rejectreason.match(regex.letterandspace)) {
            seterrrejectreasonb(true)
            seterrrejectreason("Please Enter Valid Reject Reason")
            isvalid = false;

        } else {
            seterrrejectreasonb(false)
            seterrrejectreason("")

        }

        return isvalid;
    }

    const handleapprovemodalclose = () => {
        setopenapprovedialog(false)

    }
    const handleApproveButtonModal = () => {
        const headers = {
            Authorization: KEY,

        };

        setbackdrop(true)
        const params1 = new URLSearchParams();
        params1.append("id", motifid);
        //params1.append("approve_rejected_by", userdata.memp.empId)

        axios.post(BASE_URL + '/motif/subadmin/approvemotif', params1, { withCredentials: true })
            .then(function (responses) {

                ////console.log(responses);
                if (responses.data.status === "OK" && responses.status == 200) {

                    setbackdrop(false)
                    setviewformModal(false)
                    setopenapprovedialog(false)
                    setopensnack(true)
                    setsnackmsg("Motif Approved")
                    setrefresh(1)
                    setbackdrop(false)


                }
                else {

                }

            })
            .catch(function (errors) {

            })

    }
    const handleApproveCancelButtonModal = () => {
        setopenapprovedialog(false)
    }

    const handleCancelButtonModal = () => {
        setopenrejectmodal(false)
    }

    const handleCloseSnack = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setopensnack(false);

    }

    const handleCallBack = () => {
        setrefresh(1)
    }

    const handleChangePage = (event, newpage) => {
        pagechange(newpage)
    }

    const handleRowsPerPage = (event) => {
        rowperpagechange(event.target.value)
        pagechange(0)
    }


    return (
        <>
            <div>
                <NavBarSubAdmin
                    title={TITLE}

                />

            </div>
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={Banner1} className="d-block w-100" alt="Banner1" />
                            </div>
                        </div>
                        {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button> */}
                    </div>
                </div>
            </div>
            <h1>
                {/* <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<AddIcon></AddIcon>} onClick={handleAddProof} >Add Proof Type</Button> */}
                <div className="abc text-black text-center col 10 p-1 mt-1">
                    <strong><h2> View Pending Motifs </h2></strong>
                </div>
            </h1>
            <div className="row mx-5 mb-5" >

                <div className="container " style={{ alignItems: 'center', justifyItems: 'center', display: 'grid' }}  >

                    <Box sx={{ alignItems: 'center', justifyItems: 'center', width: '100%' }}>

                        <ImageListMotifSubAdmin data={motifs} onCallBack={handleCallBack} button={true} />
                    </Box>
                    <TablePagination
                        sx={{
                            boxShadow: 2,
                            '& .MuiTablePagination-selectLabel': {
                                marginTop: 'auto'
                            },
                            '& .MuiTablePagination-displayedRows': {
                                marginTop: 'auto'
                            },
                            '& .MuiDataGrid-cell': {
                                padding: 0.5
                            },
                            overflowX: 'scroll'


                        }}
                        component="div"
                        count={totalcount}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        onRowsPerPageChange={handleRowsPerPage}
                    />
                    <Backdrop
                        sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={backdrop}
                    //onClick={handlebackdropClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>







                </div>
                <Snackbar
                    open={opensnack}
                    autoHideDuration={6000}
                    onClose={handleCloseSnack}
                    message={snackmsg}
                //action={action}
                />



            </div>

        </>
    )
}
