import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import config from '../config.json'

import { Link, Navigate, useNavigate } from 'react-router-dom'

import { decrypter } from "../Util"

import { useTheme } from '@mui/material/styles';
import Banner1 from '../images/banner1.jpg'

import axios from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
    DataGrid, GridToolbar,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { Grid } from "@mui/material";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import TextField from "@mui/material/TextField";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import { Tooltip } from '@mui/material';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import makeStyles from '@mui/styles/makeStyles';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import moment from 'moment';
import regex from "../regex.json"
import NavBarMeity from "../NavBar/NavBarMeity";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import InfoIcon from '@mui/icons-material/Info';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import Skeleton from '@mui/material/Skeleton';
import ImageZoom from "react-image-zooom";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';


const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;
const SECRET = config.SECRET;

const style2 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    maxWidth: '100%',
    maxHeight: '100vh',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    overflowX: 'auto',
    margin: 'auto'
}

const style3 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    maxWidth: '100%',
    maxHeight: '90%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    overflowX: 'auto',
    margin: 'auto'
}

const useStyle = makeStyles({
    root: {
        "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center",
            fontWeight: 'bold'
        },
        "& .MuiDataGrid-cellContent": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center"
        },
        "& .MuiTypography-string": {
            textOverflow: "ellipsis",
            whiteSpace: "break-spaces",
            lineHeight: 2,
            textAlign: "center"
        },
        boxShadow: 2,
        '& .MuiTablePagination-selectLabel': {
            marginTop: 'auto'
        },
        '& .MuiTablePagination-displayedRows': {
            marginTop: 'auto'
        }
    }
});

const useStylesCard = makeStyles({
    root: {

    },
    media: {
        "&:hover": {
            transform: "scale(3.5)"
        }
    }
});
export default function ImageListMotifMeity(props) {

    const navigate = useNavigate()
    // let cookiedata = decrypter(cookies.get("sessionid"))


    // let userdata = cookiedata.userdata
    // let jwt = cookiedata.token

    // let role = cookiedata.userdata.user.roles.map(op => op.role_name)

    // let userrole = role[0]

    // let KEY = 'Bearer ' + jwt;

    const [viewformModal, setviewformModal] = useState(false)
    const [viewimgModal, setviewimgModal] = useState(false)
    const [viewoption, setviewoption] = useState([])

    const [loading, setloading] = useState(true)
    const [pageSize, setPageSize] = useState(10);

    const [motifid, setmotifid] = useState('')
    const [cat, setcat] = useState('')
    const [subcat, setsubcat] = useState('')
    const [height, setheight] = useState('')
    const [width, setwidth] = useState('')
    const [hooks, sethooks] = useState('')
    const [ppi, setppi] = useState('')
    const [epi, setepi] = useState('')
    const [color, setcolor] = useState('')
    const [dentinch, setdentinch] = useState('')
    const [dentorderid, setdentorderid] = useState('')
    const [weaves, setweaves] = useState('')
    const [uploadedby, setuploadedby] = useState('')
    const [filename, setfilename] = useState('')
    const [status, setstatus] = useState('')
    const [remarks, setremarks] = useState('')
    const [viewMovementModal, setviewMovementModal] = useState(false)
    const [movementarr, setmovementarr] = useState([])

    const [us, setus] = useState('')
    const [zoom, setzoom] = useState(false)


    const [openapprovedialog, setopenapprovedialog] = useState(false)
    const [rejectreason, setrejectreason] = useState('')
    const [errrejectreasonb, seterrrejectreasonb] = useState(false)
    const [errrejectreason, seterrrejectreason] = useState('')
    const [openrejectmodal, setopenrejectmodal] = useState(false)
    const [opensnack, setopensnack] = useState(false)
    const [snackmsg, setsnackmsg] = useState(false)

    const [backdrop, setbackdrop] = useState(false);

    const [img, setimg] = useState()

    const [catid, setcatid] = useState('')
    const [subcatid, setsubcatid] = useState('')
    const [center, setcenter] = useState('')
    const [user, setuser] = useState('')

    const [opensessiondialog, setopensessiondialog] = useState(false)







    {/**column resizing */ }

    const breakpoints = {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
    }

    const getColumns = (width) => {
        if (width < breakpoints.sm) {
            return 1
        } else if (width < breakpoints.md) {
            return 2
        } else if (width < breakpoints.lg) {
            return 3
        } else if (width < breakpoints.xl) {
            return 4
        } else {
            return 5
        }
    }

    const [columns, setColumns] = useState(getColumns(window.innerWidth))
    const updateDimensions = () => {
        setColumns(getColumns(window.innerWidth))
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);


    {/**column resizing */ }

    const handleOKButton = () => {
        navigate('/')
        cookies.remove("sessionid")
    }

    const handleviewclick = (event, option) => {



        ////console.log("op", event)
        ////console.log("sysid", option.sysDesignId)
        // setfilename(option.filename)



        // const headers = {
        //     Authorization: KEY,

        // };

        setbackdrop(true)
        //setviewformModal(true)

        const params1 = new URLSearchParams();
        params1.append("id", option.sysMotifId);

        axios
            .post(BASE_URL + "/motif/meity/getMotifDetailsbyId",
                params1,
                { withCredentials: true })
            .then(function (response) {
                ////console.log(response);
                if (response.status == 200) {
                    setbackdrop(false)


                    setheight(option.height)
                    setwidth(option.width)
                    //sethooks(option.hooks)

                    //setdentorderid(option.draftingOrder.drafting_order_name)
                    setuploadedby(option.user.full_name)
                    setfilename(option.filename)
                    //setstatus(option.approvalStatus)
                    setremarks(option.remarks)

                    //setstatus(option.approvalStatus)

                    // ////console.log("filename:", option.approvalStatus)

                    // let subs = []
                    // option.designCategoryMapViewsList.map((op) => subs.push(op.subCategory.subcategory_name))

                    // setsubcat(subs.join(", "))

                    setmotifid(option.sysMotifId)

                    setbackdrop(false)

                    setviewformModal(true)


                }
                else {
                    setbackdrop(false)
                    setopensnack(true);
                    setsnackmsg("Something Went Wrong")
                }
            })
            .catch(function (errors) {



                if (errors.response.status == 401 || errors.response.status == 403) {


                    setbackdrop(false)

                    setopensessiondialog(true)



                } else {

                    setbackdrop(false)
                    setopensnack(true);
                    setsnackmsg("Something Went Wrong")

                }

            });


    }

    const handleviewimgModalClose = () => {

        setviewimgModal(false)
    }

    const handleviewformModalClose = () => {

        setviewformModal(false)
    }

    const handleImageclick = (option) => {
        setviewimgModal(true)
        ////console.log("clicked", option)
        setimg(option)

    }


    return (
        <>
            <ImageList variant="standard" cols={columns} gap={15} rowHeight={300} sx={{
                padding: '15px',
                borderStyle: 'solid', borderColor: 'rgb(95, 38, 73)',

            }}
            >
                {props.data.map((item) => (
                    <ImageListItem key={item.sysMotifId}
                        style={{ borderStyle: 'solid', borderRadius: '10px', borderWidth: '2px' }}>
                        <img
                            src={BASE_URL + "/motif/" + "images" + "/" + item.filename}
                            //srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={"image"}
                            loading="lazy"
                            style={{ borderRadius: '10px', height: '100%', width: '100%', objectFit: 'contain' }}
                            onClick={(event) => {
                                //handleViewOpen(event, cellValues);
                                handleImageclick(item.filename);

                            }}
                        />
                        <ImageListItemBar
                            title={item.user.full_name}
                            subtitle={""}
                            style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                            actionIcon={
                                <Tooltip title='Info'>
                                    <IconButton
                                        sx={{ color: 'rgb(255, 255, 255)' }}
                                        aria-label={`info about ${item.title}`}
                                        onClick={(e) => handleviewclick(e, item)}
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>


            {/**view modal */}
            <Modal
                open={viewformModal}
                onClose={handleviewformModalClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style2}>
                    <div style={{ alignItems: 'end', justifyItems: 'end', display: 'grid', marginBottom: '-40px' }}>
                        <Tooltip title='Close'>
                            <IconButton
                                sx={{
                                    color: 'rgb(0, 0, 0)'
                                }}
                                onClick={(event) => {
                                    handleviewformModalClose(event)


                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>

                    <Card sx={{ width: 'auto', height: 'auto', alignItems: 'center', justifyItems: 'center', boxShadow: 10, borderRadius: 3, padding: 2, display: 'grid', backgroundColor: "#f5f5f5" }}>

                        <CardMedia

                            component="img"
                            sx={{ width: '350px', height: '350px', padding: 2, objectFit: 'contain' }}
                            image={BASE_URL + "/motif/" + "images" + "/" + filename}
                            alt="image"

                        />
                        <Divider sx={{ bgcolor: "black" }} orientation="horizontal" />

                        <Box sx={{ display: 'contents' }}>
                            <CardContent >

                                <Box
                                    component="form"
                                    sx={{
                                        marginTop: 4,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",

                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid container spacing={1} >

                                        <Grid item xs={6} md={3} >

                                            <Typography fontWeight="bold" variant="subtitle1">
                                                Width:
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} md={3}>

                                            <Typography variant="subtitle1" >
                                                {width} pixels
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} md={3} >

                                            <Typography fontWeight="bold" variant="subtitle1">
                                                Height:
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} md={3}>

                                            <Typography variant="subtitle1" >
                                                {height} pixels
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} md={3} >

                                            <Typography fontWeight="bold" variant="subtitle1">
                                                Uploaded By:
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} md={3}>

                                            <Typography variant="subtitle1" >
                                                {uploadedby}
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                </Box>

                            </CardContent>

                        </Box>

                    </Card>


                </Box>
            </Modal>
            {/** imageview modal */}

            <Modal
                open={viewimgModal}
                onClose={handleviewimgModalClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style3}>
                    <div style={{ alignItems: 'end', justifyItems: 'end', display: 'grid' }}>
                        <Tooltip title='Close'>
                            <IconButton
                                sx={{
                                    color: 'rgb(255, 255, 255)'
                                }}
                                onClick={(event) => {
                                    handleviewimgModalClose(event)


                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>

                    <div>
                        <ImageZoom

                            src={BASE_URL + "/motif/" + "images" + "/" + img}
                            zoom="200"
                            width="100%"
                            height="100%"
                        />
                    </div>



                </Box>
            </Modal>
            {/* session expiration dialog */}
            <Dialog
                open={opensessiondialog}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Session Expired!!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Session has expired..Please Login Again to Continue
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOKButton}>OK</Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdrop}
            //onClick={handlebackdropClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
