import React from 'react'
import EmailIcon from '@mui/icons-material/Email';
import { Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { Grid } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom'


export default function Footer() {
  return (
    <>
      {/* Footer */}
      <footer className="foot text-center text-lg-start text-muted " >
        {/* <!-- Section: Links  --> */}
        <section className="">
          <div className="container text-center text-md-start mt-5">
            {/* <!-- Grid row --> */}
            <div className="row mt-3">
              {/* <!-- Grid column --> */}
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4" style={{ color: 'rgb(0, 0, 0)' }}>
                {/* <!-- Links --> */}
                <h6 className="text-uppercase fw-bold mb-4 mt-3">
                  Links
                </h6>
                <p>
                  <Link className="text-reset" aria-current="page" to="/"><strong>Home</strong></Link>
                </p>
                <p>
                  <Link className="text-reset" aria-current="page" to="/objective"><strong>Objective</strong></Link>
                </p>
                {/* <p>
                  <Link className="text-reset" aria-current="page" to="/news"><strong>News</strong></Link>
                </p> */}
                <p>
                  {/* <a href="#!" className="text-reset">Gallery</a> */}
                </p>
              </div>
              {/* <!-- Grid column --> */}
              {/* <!-- Grid column --> */}
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4" style={{ color: 'rgb(0, 0, 0)' }}>
                {/* <!-- Content --> */}
                <h6 className="text-uppercase fw-bold mb-4 mt-3 " style={{ justifyContent: "center" }} >
                  <CorporateFareIcon></CorporateFareIcon>CDAC, Kolkata
                </h6>
                <p><strong>
                  Plot - E-2/1, Block-GP, Sector-V, Salt Lake Electronics Complex, Bidhannagar, Kolkata, West Bengal 700091
                  </strong>
                </p>
              </div>
              {/* <!-- Grid column --> */}

              {/* <!-- Grid column --> */}
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4" style={{ color: 'rgb(0, 0, 0)' }}>
                {/* <!-- Links --> */}
                <h6 className="text-uppercase fw-bold mb-4 mt-3"><CorporateFareIcon></CorporateFareIcon> BAKSA (Main Center)</h6>
                {/* <Grid container spacing={0} sx={{marginBottom: 2}} >
                  <Grid item xs={2}><CorporateFareIcon></CorporateFareIcon></Grid>
                  <Grid item xs={10}><Typography align='justify' variant='h7'>BAKSA (Main Center)</Typography></Grid>
                </Grid> */}
                {/* <p><PersonIcon></PersonIcon> Mr. Uday Kumar Khakhlary</p> */}
                <Grid container spacing={0} sx={{ marginBottom: 2 }} >
                  <Grid item xs={2}><PersonIcon></PersonIcon></Grid>
                  <Grid item xs={10}><Typography align='justify'><strong>Mr. Uday Kumar Khakhlary</strong></Typography></Grid>
                </Grid>
                {/* <p>
                  <LocationOnIcon></LocationOnIcon> Handloom Training Institute, Mushalpur, P.O.-Mushalpur,Dist-Baksa, BTR, Assam,Pin-781372
                  
                </p> */}
                <Grid container spacing={0} sx={{ marginBottom: 2 }} >
                  <Grid item xs={2}><LocationOnIcon></LocationOnIcon></Grid>
                  <Grid item xs={10}><Typography align='justify'><strong>Handloom Training Institute, Mushalpur, P.O.-Mushalpur,Dist-Baksa, BTC, Assam,Pin-781372</strong></Typography></Grid>
                </Grid>
                {/* <p><PhoneIcon></PhoneIcon> + 91 9435644636</p> */}
                <Grid container spacing={0} sx={{ marginBottom: 2 }} >
                  <Grid item xs={2}><PhoneIcon></PhoneIcon></Grid>
                  <Grid item xs={10}><Typography align='justify'><strong>+ 91 9435644636</strong></Typography></Grid>
                </Grid>

              </div>
              {/* <!-- Grid column --> */}
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4" style={{ color: 'rgb(0, 0, 0)' }}>
                {/* <!-- Links --> */}
                <h6 className="text-uppercase fw-bold mb-4 mt-3"><CorporateFareIcon></CorporateFareIcon> Kokrajhar Center</h6>
                {/* <p><PersonIcon></PersonIcon> Ms. Hiranya Devi</p>
                <p>
                  <LocationOnIcon></LocationOnIcon> Directorate of Sericulture, Adabari, Kokrajhar, BTC

                </p>
                <p><PhoneIcon></PhoneIcon> + 91 9910672670</p> */}
                <Grid container spacing={0} sx={{ marginBottom: 2 }} >
                  <Grid item xs={2}><PersonIcon></PersonIcon></Grid>
                  <Grid item xs={10}><Typography align='justify'><strong>Ms. Hiranya Devi</strong></Typography></Grid>
                </Grid>
                <Grid container spacing={0} sx={{ marginBottom: 2 }} >
                  <Grid item xs={2}><LocationOnIcon></LocationOnIcon></Grid>
                  <Grid item xs={10}><Typography align='justify'><strong>Directorate of Sericulture, Adabari, Kokrajhar, BTC, Assam</strong></Typography></Grid>
                </Grid>
                <Grid container spacing={0} sx={{ marginBottom: 2 }} >
                  <Grid item xs={2}><PhoneIcon></PhoneIcon></Grid>
                  <Grid item xs={10}><Typography align='justify'><strong>+ 91 9910672670</strong></Typography></Grid>
                </Grid>

              </div>
            </div>
            {/* <!-- Grid row --> */}
          </div>
        </section>
        {/* <!-- Section: Links  --> */}

        {/* <!-- Copyright --> */}
        <div className="text-center p-3 " style={{ backgroundColor: 'rgb(95, 38, 73)' }}>
          <p className="text fw-bold " style={{ color: 'rgb(255, 255, 255)' }}>Designed and Developed by CDAC, Kolkata</p>

        </div>
        {/* <!-- Copyright --> */}
      </footer>
      {/* <!-- Footer --> */}
    </>
  )
}
