import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import config from '../config.json'
import NavbarUser from '../NavBar/NavbarUser'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import { decrypter } from "../Util"

import { useTheme } from '@mui/material/styles';
import Banner1 from '../images/banner1.jpg'

import axios from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
    DataGrid, GridToolbar,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { Grid } from "@mui/material";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import TextField from "@mui/material/TextField";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import { Tooltip } from '@mui/material';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import makeStyles from '@mui/styles/makeStyles';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import moment from 'moment';

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;
const SECRET = config.SECRET;

const style2 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
}

export default function ViewDesignDetailsModal(props) {

    ////console.log("modal", props.opencond)

    const [viewformModal, setviewformModal] = useState(props.opencond ? true : false);
    const [cat, setcat] = useState('')
    const [subcat, setsubcat] = useState([])
    const [height, setheight] = useState('')
    const [width, setwidth] = useState('')
    const [hooks, sethooks] = useState('')
    const [ppi, setppi] = useState('')
    const [epi, setepi] = useState('')
    const [color, setcolor] = useState('')
    const [dentinch, setdentinch] = useState('')
    const [dentorderid, setdentorderid] = useState('')
    const [weaves, setweaves] = useState('')
    const [uploadedby, setuploadedby] = useState('')
    const [filename, setfilename] = useState('')
    const [status, setstatus] = useState('')
    const [remarks, setremarks] = useState('')
    const [viewMovementModal, setviewMovementModal] = useState(false)
    const [movementarr, setmovementarr] = useState([])

    ////console.log("viewformModal", viewformModal)


    setcat(props.datalist.category.category_name)
    //setsubcat(option.subCategory.subcategory_name)
    setheight(props.datalist.height)
    setwidth(props.datalist.width)
    sethooks(props.datalist.hooks)
    
    setdentorderid(props.datalist.draftingOrder.drafting_order_name)
    setuploadedby(props.datalist.user.full_name)
    setfilename(props.datalist.filename)
    //setstatus(option.approvalStatus)
    setremarks(props.datalist.remarks)

    setstatus(props.datalist.approvalStatus)

    ////console.log("filename:", filename)


    props.datalist.designCategoryMapViewsList.map((op) => subcat.push(op.subCategory.subcategory_name))


    const handleviewformModalClose = () => {

        setviewformModal(false)
    }
    return (
        <>
            <Modal
                open={viewformModal}
                onClose={handleviewformModalClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style2}>

                    <Card sx={{ width: 'auto', height: 'auto', alignItems: 'center', justifyItems: 'center', boxShadow: 10, borderRadius: 3, padding: 2, display: 'flex', backgroundColor: "#f5f5f5" }}>

                        <CardMedia

                            component="img"
                            sx={{ width: '50%', height: '50%', padding: 2 }}

                            image={BASE_URL + "/design/" + "images" + "/" + filename}
                            alt="image"

                        />
                        <Divider sx={{ bgcolor: "secondary.light" }} orientation="vertical" variant="middle" flexItem />

                        <Box sx={{ display: 'contents' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>

                                <Typography gutterBottom variant="subtitle1" fontWeight='bold'>
                                    Category: {cat}
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" fontWeight='bold'>
                                    SubCategory: {subcat}
                                </Typography>
                                <Typography variant="body2" gutterBottom fontWeight='bold'>
                                    Width: {width} &nbsp;
                                </Typography>
                                <Typography variant="body2" gutterBottom fontWeight='bold'>
                                    Height: {height} &nbsp;
                                </Typography>
                                <Typography variant="body2" gutterBottom fontWeight='bold'>
                                    Hooks: {hooks} &nbsp;
                                </Typography>
                                <Typography variant="body2" gutterBottom fontWeight='bold'>
                                    Drafting Order: {dentorderid} &nbsp;
                                </Typography>
                                <Typography variant="body2" gutterBottom fontWeight='bold'>
                                    Uploaded By: {uploadedby}
                                </Typography>

                            </CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                {/* <IconButton aria-label="previous">
                                            {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
                                        </IconButton>
                                        <IconButton aria-label="play/pause">
                                            <PlayArrowIcon sx={{ height: 38, width: 38 }} />
                                        </IconButton>
                                        <IconButton aria-label="next">
                                            {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
                                        </IconButton> */}
                                <Typography variant="body2" gutterBottom fontWeight='bold'>
                                    {status === "Approved" ? <Typography sx={{ margin: 2 }} color="green">{status}</Typography> : ((status === "Rejected ") ? <Typography sx={{ margin: 2 }} color="error">{status}</Typography> : <Typography sx={{ margin: 2 }} color="primary">{status}</Typography>)}
                                </Typography>


                                {/* <Button sx={{margin: 2}} variant="text" color="success" >Approved</Button> */}
                                {/* <Button sx={{margin: 2}}variant="outlined" color="error">Rejected</Button> */}
                                {/* <Typography variant="body2" gutterBottom fontWeight='bold'>
                                            Approval Status: {uploadedby}
                                        </Typography> */}
                            </Box>
                        </Box>

                    </Card>


                </Box>
            </Modal>
        </>
    )
}
