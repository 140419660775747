import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
import { IdleTimerProvider } from 'react-idle-timer';
import config from './config.json'
import axios from "axios";
import { Backdrop, CircularProgress } from '@mui/material';


const BASE_URL = config.SERVER_URL;

const IdleTimeoutManager = ({ children }) => {

    const [backdropopen, setbackdropopen] = useState(false)

    const navigate = useNavigate()


    
    const location = useLocation();

    const IDLE_TIMEOUT = 1000 * 60 * 15



    // List of routes where idle timeout should not be applied
    const excludedRoutes = ['/', '/objective', '/login'];

    // Check if the current route is excluded
    const isExcludedRoute = excludedRoutes.some(route => location.pathname === route);

    const handleOnIdle = () => {
        // Redirect to login or perform logout actions
        //history.push('/login');
        //console.log("idle called")

        axios.post(
            BASE_URL + "/logoutUser",
            {}, { withCredentials: true }
        )
            .then(response => {
                if (response.status == 200) {

                    setbackdropopen(false)

                    // setsnackmsg("Logged Out Successfully")
                    // setopensnack(true)


                    
                    sessionStorage.removeItem("sessionid")
                    navigate('/')
                } else {
                    setbackdropopen(false)
                    // setsnackmsg("Something Went Wrong")
                    // setopensnack(true)


                }



            })
            .catch(error => {
                setbackdropopen(false)
                // setsnackmsg("Something Went Wrong")
                // setopensnack(true)

            });


    };

    // Render IdleTimerProvider only for non-excluded routes
    return !isExcludedRoute ? (
        <IdleTimerProvider timeout={IDLE_TIMEOUT} onIdle={handleOnIdle}>
            {children}
            <Backdrop
                sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropopen}
            //onClick={handlebackdropClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </IdleTimerProvider>
    ) : (
        // Render children directly for excluded routes
        <>{children}</>
    );
};

export default IdleTimeoutManager;