import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import config from '../config.json'
import NavbarUser from '../NavBar/NavbarUser'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import { decrypter } from "../Util"

import { useTheme } from '@mui/material/styles';
import Banner1 from '../images/banner1.jpg'

import axios from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
    DataGrid, GridToolbar,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { Grid } from "@mui/material";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import TextField from "@mui/material/TextField";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import { Tooltip } from '@mui/material';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import makeStyles from '@mui/styles/makeStyles';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import moment from 'moment';
import ViewDesignDetailsModal from "./ViewDesignDetailsModal";




const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;
const SECRET = config.SECRET;

const style2 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    maxWidth: '100%',
    maxHeight: '100vh',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    overflowX: 'auto',
    margin: 'auto'
}

const useStyle = makeStyles({
    root: {
        "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center",
            fontWeight: 'bold'
        },
        "& .MuiDataGrid-cellContent": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center"
        },
        "& .MuiTypography-string": {
            textOverflow: "ellipsis",
            whiteSpace: "break-spaces",
            lineHeight: 2,
            textAlign: "center"
        },
        boxShadow: 2,
        '& .MuiTablePagination-selectLabel': {
            marginTop: 'auto'
        },
        '& .MuiTablePagination-displayedRows': {
            marginTop: 'auto'
        }
    }
});

const useStylesCard = makeStyles({
    root: {

    },
    media: {
        "&:hover": {
            transform: "scale(3.5)"
        }
    }
});

export default function ViewUserMotifs() {

    const navigate = useNavigate()
    // let cookiedata = decrypter(cookies.get("sessionid"))


    // let userdata = cookiedata.userdata
    // let jwt = cookiedata.token

    // let role = cookiedata.userdata.user.roles.map(op => op.role_name)

    // let userrole = role[0]

    // let KEY = 'Bearer ' + jwt;

    const classes = useStyle()
    const classescard = useStylesCard()

    const [motifs, setmotifs] = useState([])

    const [designs, setdesigns] = useState([])

    const [viewformModal, setviewformModal] = useState(false)
    const [viewoption, setviewoption] = useState([])

    const [loading, setloading] = useState(true)
    const [pageSize, setPageSize] = useState(10);

    const [cat, setcat] = useState('')
    const [subcat, setsubcat] = useState([])
    const [height, setheight] = useState('')
    const [width, setwidth] = useState('')
    const [hooks, sethooks] = useState('')
    const [ppi, setppi] = useState('')
    const [epi, setepi] = useState('')
    const [color, setcolor] = useState('')
    const [dentinch, setdentinch] = useState('')
    const [dentorderid, setdentorderid] = useState('')
    const [weaves, setweaves] = useState('')
    const [uploadedby, setuploadedby] = useState('')
    const [filename, setfilename] = useState('')
    const [status, setstatus] = useState('')
    const [remarks, setremarks] = useState('')
    const [viewMovementModal, setviewMovementModal] = useState(false)
    const [movementarr, setmovementarr] = useState([])

    const [cellValues, setcellValues] = useState("");

    const [us, setus] = useState('')
    const [zoom, setzoom] = useState(false)


    useEffect(() => {
        // const headers = {
        //     Authorization: KEY,

        // };

        // const params1 = new URLSearchParams();
        // params1.append("uploadedBy", userdata.user.user_id);

        axios.post(BASE_URL + '/design/getUploadedDesignsById',{}, { withCredentials: true })
            .then(function (responses) {

                ////console.log("Motifs", responses.data.Uploaded)
                //setunmapped(responses.data.Beneficiaries)
                //setproofs(responses.data.ProofTypes)
                setdesigns(responses.data.Uploaded)
                setloading(false)

            })
            .catch(function (errors) {

            })



    }, [])


    const columns = [
        {
            field: 'sysDesignId', headerName: 'ID', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'category', headerName: 'Category', width: 200, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.category) {
                    if (cellValues.row.category) {

                        result.push(cellValues.row.category.category_name);
                    }

                }
                else {
                    result = ["Not Available"]
                }
                return result;
            }
        },
        {
            field: 'Image', headerName: 'Image', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.filename === null) {
                    return (
                        <Img alt="complex" style={{ padding: 4, border: '1px solid' }} src={BASE_URL + "/design/" + "images" + "/" + cellValues.row.filename} />
                    );
                }
                else {
                    return (
                        <Img alt="complex" style={{ padding: 4, border: '1px solid' }} src={BASE_URL + "/design/" + "images" + "/" + cellValues.row.filename} />
                    );
                }
            }
        },
        {
            field: 'dimension', headerName: 'Resolution (W x H) ', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (<Typography variant="string" >
                    {params.row.width}x{params.row.height} (in pixels)
                </Typography>)
            }
        },
        {
            field: 'uploadDate', headerName: 'Uploaded Date', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (<Typography variant="string" >
                    {moment(params.row.uploadDate).format('DD-MM-YYYY')}
                </Typography>)
            }
        },
        {
            field: 'uploadedBy', headerName: 'Uploaded By', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (<Typography variant="string" textTransform="uppercase">
                    {params.row.user.full_name}
                </Typography>)
            }
        },
        {
            field: "View",
            renderCell: (cellValues) => {
                return (
                    <IconButton
                        //   variant="contained"
                        //   color="primary"
                        //   size='medium'
                        //   startIcon={<EditIcon></EditIcon>}

                        onClick={(event) => {
                            //handleViewOpen(event, cellValues);
                            handleviewclick(event, cellValues.row);

                        }}
                    >
                        <DesktopWindowsOutlinedIcon></DesktopWindowsOutlinedIcon>
                    </IconButton>
                );
            }, flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'approvalStatus', headerName: 'Current Status', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                return (
                    (cellValues.row.approvalStatus === "Approved" ? <Typography sx={{ margin: 2 }} fontWeight='bold' color="green">{cellValues.row.approvalStatus}</Typography> : ((cellValues.row.approvalStatus === "Rejected") ? <Typography sx={{ margin: 2 }} fontWeight='bold' color="error">{cellValues.row.approvalStatus}</Typography> : <Typography sx={{ margin: 2 }} fontWeight='bold' color="primary">{cellValues.row.approvalStatus}</Typography>))
                );
            }
        }
    ]

    const theme = useTheme();

    const Img = styled('img')({
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',


    });

    const handleviewclick = (event, option) => {
        setviewformModal(true)
        setcat(option.category.category_name)
        //setsubcat(option.subCategory.subcategory_name)
        setheight(option.height)
        setwidth(option.width)
        sethooks(option.hooks)

        setdentorderid(option.draftingOrder.drafting_order_name)
        setuploadedby(option.user.full_name)
        setfilename(option.filename)
        //setstatus(option.approvalStatus)
        setremarks(option.remarks)

        setstatus(option.approvalStatus)

        ////console.log("filename:", filename)

        let subs = []
        option.designCategoryMapViewsList.map((op) => subs.push(op.subCategory.subcategory_name))

        setsubcat(subs.join(", "))


    }

    const handleviewformModalClose = () => {

        setviewformModal(false)
    }



    return (
        <>
            <div>
                <NavbarUser
                    title={TITLE}

                />

            </div>
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={Banner1} className="d-block w-100" alt="Banner1" />
                            </div>
                        </div>
                        {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button> */}
                    </div>
                </div>
            </div>
            <h1>
                {/* <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<AddIcon></AddIcon>} onClick={handleAddProof} >Add Proof Type</Button> */}
                <div className="abc text-black text-center col 10 p-1 mt-1">
                    <strong><h2> View Submitted Motifs/Designs </h2></strong>
                </div>
            </h1>
            <div className="row mx-5 mb-5" >

                <div className="container " >


                    <Box
                        justifyContent='center'
                    //sx={{width: '100%'}}


                    //</div>sx={{ height: 700, width: '100%' }}
                    >
                        <DataGrid
                            className={classes.root}
                            sx={{
                                boxShadow: 2,
                                '& .MuiTablePagination-selectLabel': {
                                    marginTop: 'auto'
                                },
                                '& .MuiTablePagination-displayedRows': {
                                    marginTop: 'auto'
                                },
                                '& .MuiDataGrid-cell': {
                                    padding: 0.5
                                },
                                overflowX: 'scroll'


                            }}
                            rows={designs}
                            getRowId={(r) => r.sysDesignId}
                            columns={columns}
                            components={{ Toolbar: GridToolbar }}
                            loading={loading}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            pagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            disableVirtualization
                            autoHeight
                            disableSelectionOnClick={true}
                            rowHeight={120}
                        //checkboxSelection={true}
                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setSelectedRows(newSelectionModel);
                        // }}

                        />
                    </Box>

                    <Modal
                        open={viewformModal}
                        onClose={handleviewformModalClose}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        <Box sx={style2}>
                            <div style={{ alignItems: 'end', justifyItems: 'end', display: 'grid', marginBottom: '-40px' }}>
                                <Tooltip title='Close'>
                                    <IconButton
                                        sx={{
                                            color: 'rgb(0, 0, 0)'
                                        }}
                                        onClick={(event) => {
                                            handleviewformModalClose(event)


                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>

                            <Card sx={{ width: 'auto', height: 'auto', alignItems: 'center', justifyItems: 'center', boxShadow: 10, borderRadius: 3, padding: 2, display: 'grid', backgroundColor: "#f5f5f5" }}>

                                <CardMedia

                                    component="img"
                                    sx={{ width: '350px', height: '350px', padding: 2, objectFit: 'contain' }}

                                    image={BASE_URL + "/design/" + "images" + "/" + filename}
                                    alt="image"

                                />
                                <Divider sx={{ bgcolor: "secondary.light" }} orientation="vertical" variant="middle" flexItem />

                                <Box sx={{ display: 'contents' }}>
                                    <CardContent>

                                        <Box
                                            component="form"
                                            sx={{
                                                marginTop: 4,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",

                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <Grid container spacing={1} >
                                                <Grid item xs={6} md={3} >

                                                    <Typography variant="subtitle1" fontWeight="bold">
                                                        Category:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={6} md={3}>

                                                    <Typography variant="subtitle1" >
                                                        {cat}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={6} md={3} >

                                                    <Typography fontWeight="bold" variant="subtitle1">
                                                        Products:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={6} md={3}>

                                                    <Typography variant="subtitle1" >
                                                        {subcat}
                                                    </Typography>

                                                </Grid>

                                                {/**-----------------------row 1 --------------------*/}
                                                <Grid item xs={6} md={3} >

                                                    <Typography fontWeight="bold" variant="subtitle1">
                                                        Width:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={6} md={3}>

                                                    <Typography variant="subtitle1" >
                                                        {width} pixels
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={6} md={3} >

                                                    <Typography fontWeight="bold" variant="subtitle1">
                                                        Height:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={6} md={3}>

                                                    <Typography variant="subtitle1" >
                                                        {height} pixels
                                                    </Typography>

                                                </Grid>

                                                {/**-----------------------row 2 --------------------*/}
                                                <Grid item xs={6} md={3} >

                                                    <Typography fontWeight="bold" variant="subtitle1">
                                                        Hooks:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={6} md={3}>

                                                    <Typography variant="subtitle1" >
                                                        {hooks}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={6} md={3} >

                                                    <Typography fontWeight="bold" variant="subtitle1">
                                                        Drafting Order:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={6} md={3}>

                                                    <Typography variant="subtitle1" >
                                                        {dentorderid}
                                                    </Typography>

                                                </Grid>
                                                {/**-----------------------row 3 --------------------*/}
                                                <Grid item xs={6} md={3} >

                                                    <Typography fontWeight="bold" variant="subtitle1">
                                                        Uploaded By:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={6} md={3}>

                                                    <Typography variant="subtitle1" >
                                                        {uploadedby}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={6} md={3} >

                                                    <Typography fontWeight="bold" variant="subtitle1">
                                                        Status:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={6} md={3}>

                                                    <Typography variant="subtitle1" >
                                                        {status === "Approved" ? <Typography color="green">{status}</Typography> : ((status === "Rejected ") ? <Typography color="error">{status}</Typography> : <Typography color="primary">{status}</Typography>)}
                                                    </Typography>

                                                </Grid>


                                            </Grid>
                                        </Box>

                                    </CardContent>
                                    {/* <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                        
                                        <Typography variant="body2" gutterBottom fontWeight='bold'>
                                            Status: {status === "Approved" ? <Typography sx={{ margin: 2 }} color="green">{status}</Typography> : ((status === "Rejected ") ? <Typography sx={{ margin: 2 }} color="error">{status}</Typography> : <Typography sx={{ margin: 2 }} color="primary">{status}</Typography>)}
                                        </Typography>
                                    </Box> */}
                                </Box>

                            </Card>


                        </Box>
                    </Modal>







                </div>


            </div>

        </>
    )
}
