import React from 'react'
import config from '../config.json'
import NavBarHome from '../NavBar/NavBarHome';


const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

export default function Register() {
  return (
    <>
      <NavBarHome title={TITLE}></NavBarHome>
      Register

    </>
  )
}
