import React from 'react'
import config from '../config.json'
import NavBarHome from '../NavBar/NavBarHome';
import Banner1 from '../images/banner1.jpg';
import Banner2 from '../images/banner2.jpg';
import Banner3 from '../images/banner3.jpg';
import g1 from '../images/gallery/1.jpg'
import g2 from '../images/gallery/2.jpg'
import g3 from '../images/gallery/3.jpg'
import g4 from '../images/gallery/4.jpg'
import g5 from '../images/gallery/5.jpg'
import ban2 from '../images/newgif.gif'


import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import e1 from "../images/design.png"
import Typography from '@mui/material/Typography';
import Slide from 'react-reveal/Slide';

import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';

import ImageGallery from 'react-image-gallery';


const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

var img = [
  {
    original: `${g1}`,
    thumbnail: `${g1}`,
    originalHeight: "500px",
    originalAlt: "",
    originalTitle: "",
    thumbnailTitle: "",
    description: "",
  },
  {
    original: `${g2}`,
    thumbnail: `${g2}`,
    originalHeight: "500px",
    originalAlt: "",
    originalTitle: "",
    thumbnailTitle: "",
    description: "",
  },
  {
    original: `${g3}`,
    thumbnail: `${g3}`,
    originalHeight: "500px",
    originalAlt: "",
    originalTitle: "",
    thumbnailTitle: "",
    description: "",
  },
  {
    original: `${g4}`,
    thumbnail: `${g4}`,
    originalHeight: "500px",
    originalAlt: "",
    originalTitle: "",
    thumbnailTitle: "",
    description: "",
  },
  {
    original: `${g5}`,
    thumbnail: `${g5}`,
    originalHeight: "500px",
    originalAlt: "",
    originalTitle: "",
    thumbnailTitle: "",
    description: "",
  }
]


export default function HomePage() {
  return (
    <>
      <NavBarHome title={TITLE}></NavBarHome>
      {/* carousel starts */}
      <div className="row">
        <div className="container ">
          <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
              {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={Banner1} className="d-block w-100" alt="image1" />
              </div>
              {/* <div className="carousel-item">
                <img src={Banner2} className="d-block w-100" alt="image2" />
              </div>
              <div className="carousel-item">
                <img src={Banner3} className="d-block w-100" alt="image2" />
              </div> */}

            </div>
            {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </button> */}
          </div>
        </div>
      </div>
      {/* carousel ends */}

      <div className="container-fluid">
        <div className=" text-black text-center col 10 p-1 mt-5">
          <h3> ABOUT ONLINE DESIGN REPOSITORY </h3>
        </div>
        <Box
          component="form"
          display="flex"
          justifyContent="center"
          alignItems="center"

          sx={{
            marginTop: 2,
            // display: "flex",
            // flexDirection: "column"


          }}
          noValidate
          autoComplete="off"
        >
          <Slide left>

            <Grid container spacing={6} direction="row"
              alignItems='center'
              justifyContent='center'
              padding='4px'>


              <Grid item lg={4} md={6} xs={12} style={{
                alignItems: 'center',
                display: 'grid', justifyItems: 'center'
              }} >
                <img src={e1} className="w-20" alt="" />

              </Grid>

              <Grid item lg={8} md={6} xs={12} >
                <Typography variant="subtitle1" alignItems='center' align='justify' style={{
                  padding: "30px",
                  justifyContent: "center",
                  display: "grid",
                  justifyItems: "center",
                  alignItems: "center",
                  alignContent: "center"
                }}>

                  Main purpose of the Online Design Repository is to bring up the
                  work of Weavers & Artisans scattered in different remote as well as
                  developed areas across the BTR region into a centralized & online
                  platform so that their work (Motif, Design & Simulations) can be
                  utilized directly by the Handloom Department for better production in
                  terms of quality & quantity. With this repository, there is no need to
                  visit and collect handmade designs. Instead, Weavers/Artisans can now
                  develop the computer-based design and upload their work into the
                  repository so that their creations can be used in the handloom
                  e-commerce sector.
                </Typography>
                <Typography sx={{ marginTop: 2 }} align='justify' style={{
                  padding: "30px",
                  justifyContent: "center",
                  display: "grid",
                  justifyItems: "center",
                  alignItems: "center",
                  alignContent: "center"
                }}>
                  The handloom Design Repository system consists of a web portal, which is
                  a File Repository System for storing and retrieving files created by
                  weavers/artisans of the BTR Region, Registered user can upload their
                  work like Motif, Design & Simulation into the repository, which may get
                  the administrator’s approval after proper verification.
                </Typography>
                <Typography sx={{ marginTop: 2 }} align='justify' style={{ padding: "30px" }}>
                  The Online Design Repository will broadly have  the following
                  functionalities:
                </Typography>
                <Typography variant="subtitle1" align='justify' sx={{ marginTop: 2 }} style={{ marginLeft: "30px", display: "flex"  }}>
                  <CheckCircleOutline color='success' sx={{ marginRight: 2 , marginTop: 0.5}} />
                  Registration of Weavers/Artisans.

                </Typography>
                <Typography variant="subtitle1" align='justify' sx={{ marginTop: 2 }} style={{ marginLeft: "30px", display: "flex"  }}>
                  <CheckCircleOutline color='success' sx={{ marginRight: 2 , marginTop: 0.5}} />
                  Approval of user by Administrator.


                </Typography>
                <Typography variant="subtitle1" align='justify' sx={{ marginTop: 2 }} style={{ marginLeft: "30px", display: "flex" }}>
                  <CheckCircleOutline color='success' sx={{ marginRight: 2 , marginTop: 0.5}} />
                  <p >Multilevel review, Approval/Rejection of <br className='narrow'></br> the work by the Sub-Administrator at the <br className='narrow'></br> district level.</p>


                </Typography>
                <Typography variant="subtitle1" align='justify' sx={{ marginTop: 2 }} style={{ marginLeft: "30px", display: "flex"  }}>
                  <CheckCircleOutline color='success' sx={{ marginRight: 2 , marginTop: 0.5}} />
                  Purchase of motifs & designs by Buyers


                </Typography>
                <Typography variant="subtitle1" align='justify' sx={{ marginTop: 2 }} style={{ marginLeft: "30px", display: "flex"  }}>
                  <CheckCircleOutline color='success' sx={{ marginRight: 2 , marginTop: 0.5}} />
                  Digitization of Traditional designs <br className='narrow'></br> available in different formats like <br className='narrow'></br> paper, textile,etc.


                </Typography>


              </Grid>
            </Grid>

          </Slide>

        </Box>

        <Slide up>
          <div className=" text-black text-center col 10 p-1 mt-5" style={{ marginBottom: 10 }}>
            <h3> GALLERY </h3>
          </div>

          <ImageGallery items={img} showPlayButton={false} autoPlay={true} />
        </Slide>





      </div>

    </>
  )
}
