import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import config from '../../config.json'
import NavBarSuperAdmin from '../../NavBar/NavBarSuperAdmin'
import { useNavigate } from 'react-router-dom'

import { decrypter } from "../../Util"
import regex from "../../regex.json"

import Banner1 from '../../images/banner1.jpg';
import Banner2 from '../../images/banner2.jpg';
import Banner3 from '../../images/banner3.jpg';
import tick from '../../images/verified.png'
import upload from '../../images/upload.png'
import load from '../../images/loading.png'


import CountUp from "react-countup";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Grid, Box, TextField, MenuItem, InputAdornment, Backdrop, CircularProgress, Alert } from "@mui/material";
import { CardActionArea } from '@mui/material';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import axios from 'axios';

import { useIdleTimer } from 'react-idle-timer'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import Chip from '@mui/material/Chip';

import Fade from '@mui/material/Fade';
import { CheckCircle, Cancel, VisibilityOff, Visibility, Close } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';

import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
    DataGrid, GridToolbar,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;
const SECRET = config.SECRET;

const style2 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
}

const useStyle = makeStyles({
    root: {
        "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center",
            fontWeight: 'bold'
        },
        "& .MuiDataGrid-cellContent": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center"
        },
        "& .MuiTypography-string": {
            textOverflow: "ellipsis",
            whiteSpace: "break-spaces",
            lineHeight: 2,
            textAlign: "center"
        },
        boxShadow: 2,
        '& .MuiTablePagination-selectLabel': {
            marginTop: 'auto'
        },
        '& .MuiTablePagination-displayedRows': {
            marginTop: 'auto'
        }
    }
});

export default function ManageUser() {

    const navigate = useNavigate()
    // let cookiedata = decrypter(cookies.get("sessionid"))


    // let userdata = cookiedata.userdata
    // let jwt = cookiedata.token

    // let role = cookiedata.userdata.user.roles.map(op => op.role_name)

    // let userrole = role[0]

    // let KEY = 'Bearer ' + jwt;



    const classes = useStyle();


    const [pageSize, setPageSize] = useState(10);
    const [refreshdata, setrefreshdata] = useState(0)

    const [state, setstate] = useState([])
    const [district, setdistrict] = useState([])
    const [filterdistrict, setfilterdistrict] = useState([])
    const [center, setcenter] = useState([])
    const [filtercenter, setfiltercenter] = useState([])
    const [userlist, setuserlist] = useState([])
    const [roles, setroles] = useState([])

    const [openadduserdialog, setopenadduserdialog] = useState(false)
    const [showPassword, setshowPassword] = useState(false)
    const [backdrop, setbackdrop] = useState(false)
    const [opensnack, setopensnack] = useState(false)
    const [snackmsg, setsnackmsg] = useState()

    const [opensessiondialog, setopensessiondialog] = useState(false)
    const [alertmsg, setalertmsg] = useState('')
    const [open, setopen] = useState(false)

    const [openedituserdialog, setopenedituserdialog] = useState(false)

    // user variables//

    const [userid, setuserid] = useState('')

    const [username, setusername] = useState('')
    const [errusernameb, seterrusernameb] = useState(false)
    const [errusername, seterrusername] = useState('')

    const [password, setpassword] = useState('')
    const [errpasswordb, seterrpasswordb] = useState(false)
    const [errpassword, seterrpassword] = useState('')

    const [fullname, setfullname] = useState('')
    const [errfullnameb, seterrfullnameb] = useState(false)
    const [errfullname, seterrfullname] = useState('')

    const [address, setaddress] = useState('')
    const [erruseraddressb, seterruseraddressb] = useState(false)
    const [erruseraddress, seterruseraddress] = useState('')

    const [userstate, setuserstate] = useState('')
    const [erruserstateb, seterruserstateb] = useState(false)
    const [erruserstate, seterruserstate] = useState('')

    const [userdistrict, setuserdistrict] = useState('')
    const [erruserdistrictb, seterruserdistrictb] = useState(false)
    const [erruserdistrict, seterruserdistrict] = useState('')

    const [mobile, setmobile] = useState('')
    const [errusermobileb, seterrusermobileb] = useState(false)
    const [errusermobile, seterrusermobile] = useState('')

    const [usercenter, setusercenter] = useState('')
    const [errusercenterb, seterrusercenterb] = useState(false)
    const [errusercenter, seterrusercenter] = useState()

    const [userrolenew, setuserrolenew] = useState('')
    const [erruserroleb, seterruserroleb] = useState(false)
    const [erruserrole, seterruserrole] = useState('')


    //user variables//



    useEffect(() => {

        // const headers = {
        //     Authorization: KEY
        // };

        const getstate = axios.post(BASE_URL + '/getAllStates', {}, { withCredentials: true })
        const getdistrict = axios.post(BASE_URL + '/getAllDistricts', {}, { withCredentials: true })
        const getcenter = axios.post(BASE_URL + '/getAllCenters', {}, { withCredentials: true })
        const getrole = axios.post(BASE_URL + '/getAllRole', {}, { withCredentials: true })

        Promise.all([getstate, getdistrict, getcenter, getrole])
            .then(function (responses) {

                ////console.log("response", responses)

                setstate(responses[0].data.states)
                setdistrict(responses[1].data.Districts)
                setfilterdistrict(responses[1].data.Districts)
                setfiltercenter(responses[2].data.Centers)
                setcenter(responses[2].data.Centers)
                setroles(responses[3].data)


            }).catch(function (errors) {

            });

    }, [])

    useEffect(() => {
        // const headers = {
        //     Authorization: KEY
        // };

        const getuserlist = axios.post(BASE_URL + '/user/getAllUser', {}, { withCredentials: true })

        Promise.all([getuserlist])
            .then(function (responses) {

                ////console.log("response2", responses)

                setuserlist(responses[0].data.User)

                setrefreshdata(0)


            }).catch(function (errors) {

            });


    }, [refreshdata])


    const columns = [
        {
            field: 'userId', headerName: 'ID', flex: 0.5, align: 'center', headerAlign: 'center'
        },
        {
            field: 'username', headerName: 'User Name', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'fullname', headerName: 'Full Name', flex: 1, align: 'center', headerAlign: 'center'
        },

        {
            field: 'center', headerName: 'Center', flex: 1, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = "";
                if (cellValues.row.center) {
                    if (cellValues.row.center.center_name) {

                        //result.push(cellValues.row.state.state_name);

                        result = cellValues.row.center.center_name

                    }
                }
                else {
                    result = ""
                }
                return result;


            }
        },
        {
            field: 'activationstatus', headerName: 'Status', flex: 0.5, align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.activationstatus === "Y") {
                    return (
                        // <IconButton
                        // //   variant="contained"
                        // //   color="primary"
                        // //   size='medium'
                        // //   startIcon={<EditIcon></EditIcon>}

                        // // onClick={(event) => {
                        // //     handleClickOpen(event, cellValues);
                        // // }}
                        // >
                        //     <CheckCircle sx={{ color: "green" }}></CheckCircle>
                        // </IconButton>
                        <>
                            <Chip icon={<CheckCircle></CheckCircle>} color="success" label="Active" variant="outlined" />
                        </>

                    );
                }
                else {
                    return (
                        // <IconButton
                        // //   variant="contained"
                        // //   color="primary"
                        // //   size='medium'
                        // //   startIcon={<EditIcon></EditIcon>}

                        // // onClick={(event) => {
                        // //     handleClickOpen(event, cellValues);
                        // // }}
                        // >
                        //     <Cancel sx={{color: "red"}}></Cancel>
                        // </IconButton>
                        <>
                            <Chip icon={<Cancel ></Cancel>} color="error" label="Inactive" variant="outlined" />

                        </>

                    );
                }
            }
        },
        {
            field: "Action", align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        <Tooltip title="Edit" arrow>
                            <IconButton
                                //   variant="contained"
                                //   color="primary"
                                //   size='medium'
                                //   startIcon={<EditIcon></EditIcon>}

                                onClick={(event) => {
                                    handleEditClick(event, cellValues);
                                }}
                            >
                                <EditIcon></EditIcon>
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }, flex: 0.2
        },
        {
            field: "Activate/Deactivate", align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        <Tooltip title="Activate" arrow>
                            <IconButton
                                //   variant="contained"
                                //   color="primary"
                                //   size='medium'
                                //   startIcon={<EditIcon></EditIcon>}

                                onClick={(event) => {
                                    handleactivate(event, cellValues);
                                }}
                            >
                                <CheckCircle sx={{ color: "green" }}></CheckCircle>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="De-Activate" arrow>
                            <IconButton
                                //   variant="contained"
                                //   color="primary"
                                //   size='medium'
                                //   startIcon={<EditIcon></EditIcon>}

                                onClick={(event) => {
                                    handledeactivate(event, cellValues);
                                }}
                            >
                                <Cancel sx={{ color: "red" }}></Cancel>
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }, flex: 0.5
        }

    ]


    const handleactivate = (event, cellValues) => {


        if (cellValues.row.activationstatus === "Y") {
            setopen(true)
            setalertmsg("User Already Activated")
        } else {
            // const headers = {
            //     Authorization: KEY,

            // };

            setbackdrop(true)

            ////console.log("cellvalues", cellValues)

            const params1 = new URLSearchParams();
            params1.append("id", cellValues.row.userId);
            //params1.append("activator", "Y")

            ////console.log("params", params1)

            axios
                .post(BASE_URL + "/user/userActivator",
                    params1,
                    { withCredentials: true })
                .then(function (response) {
                    ////console.log(response);
                    if (response.data.status === "OK" && response.status == 200) {
                        //setbackdrop(false)
                        setbackdrop(false)
                        setopensnack(true);
                        setsnackmsg("User Activated Successfully")


                        setrefreshdata(1)

                    }
                    else {
                        setbackdrop(false)
                        setopensnack(true);
                        setsnackmsg("User Activation Failed")
                    }
                })
                .catch(function (errors) {
                    ////console.log("err", errors)


                    if (errors.response.status == 401 || errors.response.status == 403) {


                        setbackdrop(false)

                        setopensessiondialog(true)



                    } else {

                        setbackdrop(false)
                        setopensnack(true);
                        setsnackmsg("User Activation Failed")

                    }

                });
        }






    }

    const handledeactivate = (event, cellValues) => {

        if (cellValues.row.activationstatus === 'N') {
            setopen(true)
            setalertmsg("User Already Deactivated")
        } else {

            // const headers = {
            //     Authorization: KEY,

            // };

            setbackdrop(true)

            ////console.log("cellvalues", cellValues)

            const params1 = new URLSearchParams();
            params1.append("id", cellValues.row.userId);
            //params1.append("activator", "N")

            ////console.log("params", params1)

            axios
                .post(BASE_URL + "/user/userRejector",
                    params1,
                    { withCredentials: true })
                .then(function (response) {
                    ////console.log(response);
                    if (response.data.status === "OK" && response.status == 200) {
                        //setbackdrop(false)
                        setbackdrop(false)
                        setopensnack(true);
                        setsnackmsg("User De-Activated Successfully")


                        setrefreshdata(1)

                    }
                    else {
                        setbackdrop(false)
                        setopensnack(true);
                        setsnackmsg("User De-Activation Failed")
                    }
                })
                .catch(function (errors) {
                    ////console.log("err", errors)


                    if (errors.response.status == 401 || errors.response.status == 403) {


                        setbackdrop(false)

                        setopensessiondialog(true)



                    } else {

                        setbackdrop(false)
                        setopensnack(true);
                        setsnackmsg("User De-Activation Failed")

                    }

                });

        }



    }

    const handleEditClick = (event, cellValues) => {
        setopenedituserdialog(true)
        ////console.log("cellValuesedit", cellValues)
        setuserid(cellValues.row.userId)
        setusername(cellValues.row.username)
        setfullname(cellValues.row.fullname)
        setuserstate(cellValues.row.state)
        setuserdistrict(cellValues.row.district)
        setusercenter(cellValues.row.centerId)
        setaddress(cellValues.row.address)
        setmobile(cellValues.row.mobile)

    }

    const handleAddUser = () => {
        setopenadduserdialog(true)
        setusername('')
        setpassword('')
        setfullname('')
        setuserstate('')
        setuserdistrict('')
        setusercenter('')
        setaddress('')
        setmobile('')
        setuserrolenew('')
    }

    const handleopenadduserdialogclose = () => {
        setopenadduserdialog(false)
    }


    const handleClickShowPassword = () => {
        if (showPassword === false) {

            setshowPassword(true)

        } else {

            setshowPassword(false)

        }
    }

    const handlestatechange = (e) => {

        setuserstate(e.target.value)
        setfilterdistrict(district.filter(op => op.state_id === e.target.value))

    }

    const handledistrictchange = (e) => {
        setuserdistrict(e.target.value)
        setfiltercenter(center.filter(op => op.district_id === e.target.value))
    }

    const handleadduserSaveButton = () => {
        if (validation()) {
            // const headers = {
            //     Authorization: KEY,

            // };

            setbackdrop(true)

            const md5 = require('md5')

            const userjson = {
                username: username.trim(),
                password: password,
                fullname: fullname.trim(),
                add_ress: address,
                district: userdistrict,
                state: userstate,
                email_id: username.trim(),
                mobile: mobile,
                centerId: usercenter

            }

            const role = {
                roleid: userrolenew
            }

            ////console.log("upload", userjson)
            ////console.log("upload2", role)

            const data = new FormData();
            data.append("userdetails", new Blob([JSON.stringify(userjson)], { type: 'application/json' }));
            data.append("role", new Blob([JSON.stringify(role)], { type: 'application/json' }))

            axios
                .post(BASE_URL + "/user/addUser",
                    data,
                    { withCredentials: true })
                .then(function (response) {
                    ////console.log(response);
                    if (response.data.status === "OK" && response.status == 200) {
                        //setbackdrop(false)
                        setbackdrop(false)
                        setopensnack(true);
                        setsnackmsg("User Added Successfully")
                        setopenadduserdialog(false)

                        setrefreshdata(1)

                    }
                    else {
                        setbackdrop(false)
                        setopensnack(true);
                        setsnackmsg("User Addition Failed")
                    }
                })
                .catch(function (errors) {
                    ////console.log("err", errors)


                    if (errors.response.status == 401 || errors.response.status == 403) {


                        setbackdrop(false)

                        setopensessiondialog(true)



                    } else {

                        setbackdrop(false)
                        setopensnack(true);
                        setsnackmsg("User Addition Failed")

                    }

                });


        }
    }


    const handleadduserCancelButton = () => {
        setopenadduserdialog(false)
    }

    function validation() {
        let isValid = true

        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

        if (username === '') {
            seterrusernameb(true)
            seterrusername("Email cannot be empty")
            isValid = false
        } else if (!username.match(regex.emailpattern)) {

            seterrusernameb(true)
            seterrusername("Please enter valid email")
            isValid = false
        } else {
            seterrusernameb(false)
            seterrusername("")
        }

        if (password === '') {
            seterrpasswordb(true)
            seterrpassword("Password cannot be Empty")
            isValid = false
        } else if (passwordRegex.test(password) === false) {
            seterrpasswordb(true)
            seterrpassword("Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.")
            isValid = false
        } else {
            seterrpasswordb(false)
            seterrpassword("")
        }

        if (fullname === '') {
            seterrfullnameb(true)
            seterrfullname("Full Name cannot be empty")
            isValid = false
        } else if (!fullname.match(regex.letterandspace)) {
            seterrfullnameb(true)
            seterrfullname("Please enter valid name")
            isValid = false
        } else {
            seterrfullnameb(false)
            seterrfullname()
        }

        if (address === '') {
            seterruseraddressb(true)
            seterruseraddress("Address cannot be Empty")
            isValid = false
        } else if (!address.match(regex.letternumberspacecoma)) {
            seterruseraddressb(true)
            seterruseraddress("Please Enter Valid Address")
            isValid = false
        } else {
            seterruseraddressb(false)
            seterruseraddress()
        }

        if (mobile === '') {
            seterrusermobileb(false)
            seterrusermobile()

        } else {
            if (!mobile.match(regex.mobile)) {
                seterrusermobileb(true)
                seterrusermobile("Please enter valid mobile")
                isValid = false
            } else {
                seterrusermobileb(false)
                seterrusermobile()
            }
        }

        if (userstate === '') {
            seterruserstateb(true)
            seterruserstate("Please Enter State")
            isValid = false
        } else {
            seterruserstateb(false)
            seterruserstate()
        }

        if (userdistrict === '') {
            seterruserdistrictb(true)
            seterruserdistrict("Please Enter District")
            isValid = false
        } else {
            seterruserdistrictb(false)
            seterruserdistrict()
        }

        if (usercenter === '') {
            seterrusercenterb(true)
            seterrusercenter("Please Enter State")
            isValid = false
        } else {
            seterrusercenterb(false)
            seterrusercenter()
        }

        if (userrolenew === '') {
            seterruserroleb(true)
            seterruserrole("Please Select Role")
            isValid = false
        } else {
            seterruserroleb(false)
            seterruserrole("")
        }


        return isValid



    }

    function validation2() {
        let isValid = true

        if (username === '') {
            seterrusernameb(true)
            seterrusername("Email cannot be empty")
            isValid = false
        } else if (!username.match(regex.emailpattern)) {

            seterrusernameb(true)
            seterrusername("Please enter valid email")
            isValid = false
        } else {
            seterrusernameb(false)
            seterrusername("")
        }


        if (fullname === '') {
            seterrfullnameb(true)
            seterrfullname("Full Name cannot be empty")
            isValid = false
        } else if (!fullname.match(regex.letterandspace)) {
            seterrfullnameb(true)
            seterrfullname("Please enter valid name")
            isValid = false
        } else {
            seterrfullnameb(false)
            seterrfullname()
        }

        if (address === '') {
            seterruseraddressb(true)
            seterruseraddress("Address cannot be Empty")
            isValid = false
        } else if (!address.match(regex.letternumberspacecoma)) {
            seterruseraddressb(true)
            seterruseraddress("Please Enter Valid Address")
            isValid = false
        } else {
            seterruseraddressb(false)
            seterruseraddress()
        }

        if (mobile === '') {
            seterrusermobileb(false)
            seterrusermobile()

        } else {
            if (!mobile.match(regex.mobile)) {
                seterrusermobileb(true)
                seterrusermobile("Please enter valid mobile")
                isValid = false
            } else {
                seterrusermobileb(false)
                seterrusermobile()
            }
        }

        if (userstate === '') {
            seterruserstateb(true)
            seterruserstate("Please Enter State")
            isValid = false
        } else {
            seterruserstateb(false)
            seterruserstate()
        }

        if (userdistrict === '') {
            seterruserdistrictb(true)
            seterruserdistrict("Please Enter District")
            isValid = false
        } else {
            seterruserdistrictb(false)
            seterruserdistrict()
        }

        if (usercenter === '') {
            seterrusercenterb(true)
            seterrusercenter("Please Enter State")
            isValid = false
        } else {
            seterrusercenterb(false)
            seterrusercenter()
        }




        return isValid



    }

    const handleCloseSnack = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setopensnack(false);

    }

    const handleOKButton = () => {
        navigate('/')
        cookies.remove("sessionid")
    }

    const handleopenedituserdialogclose = () => {
        setopenedituserdialog(false)
    }

    const handleedituserSaveButton = () => {

        if (validation2()) {
            // const headers = {
            //     Authorization: KEY,

            // };

            setbackdrop(true)

            const md5 = require('md5')

            const userjson = {
                user_id: userid,
                username: username.trim(),
                fullname: fullname.trim(),
                add_ress: address,
                district: userdistrict,
                state: userstate,
                email_id: username.trim(),
                mobile: mobile,
                centerId: usercenter

            }



            ////console.log("upload", userjson)

            const data = new FormData();
            data.append("userdetails", new Blob([JSON.stringify(userjson)], { type: 'application/json' }));

            axios
                .post(BASE_URL + "/user/editUser",
                    data,
                    { withCredentials: true })
                .then(function (response) {
                    ////console.log(response);
                    if (response.data.status === "OK" && response.status == 200) {
                        //setbackdrop(false)
                        setbackdrop(false)
                        setopensnack(true);
                        setsnackmsg("User Edited Successfully")
                        setopenedituserdialog(false)

                        setrefreshdata(1)

                    }
                    else {
                        setbackdrop(false)
                        setopensnack(true);
                        setsnackmsg("User Edit Failed")
                    }
                })
                .catch(function (errors) {
                    ////console.log("err", errors)


                    if (errors.response.status == 401 || errors.response.status == 403) {


                        setbackdrop(false)

                        setopensessiondialog(true)



                    } else {

                        setbackdrop(false)
                        setopensnack(true);
                        setsnackmsg("User Edit Failed")

                    }

                });


        }



    }
    const handleedituserCancelButton = () => {
        setopenedituserdialog(false)
    }

    const handlePreventDefault = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <div>
                <NavBarSuperAdmin
                    title={TITLE}

                />
            </div>
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={Banner1} className="d-block w-100" alt="image1" />
                            </div>
                            <div className="carousel-item">
                                <img src={Banner2} className="d-block w-100" alt="image2" />
                            </div>
                            <div className="carousel-item">
                                <img src={Banner3} className="d-block w-100" alt="image2" />
                            </div>

                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="row mx-5 mb-5">
                <div className="container">
                    <h1>
                        <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<AddIcon></AddIcon>} onClick={handleAddUser} >Add New User</Button>
                        <div className="abc text-black text-center col 10 p-1 mt-1">
                            <strong><h2> Manage Users (Weavers/Artisans) </h2></strong>
                        </div>
                    </h1>
                    <Collapse in={open}>
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setopen(false);
                                    }}
                                >
                                    <Close fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {alertmsg}
                        </Alert>
                    </Collapse>
                    <Box
                        justifyContent='center'
                    //</div>sx={{ height: 700, width: '100%' }}
                    >
                        <DataGrid
                            className={classes.root}
                            sx={{
                                boxShadow: 2,
                                '& .MuiTablePagination-selectLabel': {
                                    marginTop: 'auto'
                                },
                                '& .MuiTablePagination-displayedRows': {
                                    marginTop: 'auto'
                                }
                            }}
                            rows={userlist}
                            getRowId={(r) => r.userId}
                            columns={columns}
                            components={{
                                Toolbar: GridToolbar,
                                //Pagination: CustomPagination
                            }}
                            disableSelectionOnClick
                            autoHeight
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            pagination
                            rowsPerPageOptions={[10, 25, 50, 100]}

                        //checkboxSelection={false}
                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setSelectedRows(newSelectionModel);
                        // }}

                        />
                    </Box>
                </div>
                {/* add user dialog */}
                <Dialog open={openadduserdialog} onClose={handleopenadduserdialogclose}>
                    <DialogTitle>Add User</DialogTitle>
                    <DialogContent>
                        <form autoComplete="off">
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div>
                                        <TextField
                                            error={errusernameb}
                                            helperText={errusername}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="outlined-select-district"
                                            label="Email"
                                            value={username}
                                            onChange={(e) =>
                                                setusername(e.target.value)
                                            }
                                            autoComplete="cc-number"


                                        // InputProps={{
                                        //   readOnly: true,
                                        // }}
                                        //onChange={handleChange}
                                        >
                                        </TextField>
                                    </div>

                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div>
                                        <TextField
                                            error={errpasswordb}
                                            helperText={errpassword}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="outlined-select-district"
                                            label="Password"
                                            value={password}
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={(e) =>
                                                setpassword(e.target.value)
                                            }
                                             autoComplete="cc-number"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            //onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        // InputProps={{
                                        //   readOnly: true,
                                        // }}
                                        //onChange={handleChange}
                                        >
                                        </TextField>
                                    </div>

                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div>
                                        <TextField
                                            error={errfullnameb}
                                            helperText={errfullname}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="outlined-select-district"
                                            label="Full Name"
                                            value={fullname}
                                            onChange={(e) =>
                                                setfullname(e.target.value)
                                            }
                                        // InputProps={{
                                        //   readOnly: true,
                                        // }}
                                        //onChange={handleChange}
                                        >
                                        </TextField>
                                    </div>

                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div>
                                        <TextField
                                            error={erruserstateb}
                                            helperText={erruserstate}
                                            margin="normal"
                                            required
                                            fullWidth
                                            select
                                            id="outlined-select-district"
                                            label="State"
                                            value={userstate}
                                            // InputProps={{
                                            //   readOnly: true,
                                            // }}
                                            onChange={handlestatechange}
                                        >
                                            {state.map((option) => (
                                                <MenuItem
                                                    key={option.state_id}
                                                    value={option.state_id}
                                                >
                                                    {option.state_name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>

                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div>
                                        <TextField
                                            error={erruserdistrictb}
                                            helperText={erruserdistrict}
                                            margin="normal"
                                            required
                                            fullWidth
                                            select
                                            id="outlined-select-district"
                                            label="District"
                                            value={userdistrict}
                                            onChange={handledistrictchange
                                            }
                                        // InputProps={{
                                        //   readOnly: true,
                                        // }}
                                        //onChange={handleChange}
                                        >
                                            {filterdistrict.map((option) => (
                                                <MenuItem
                                                    key={option.district_id}
                                                    value={option.district_id}
                                                >
                                                    {option.district_name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>

                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div>
                                        <TextField
                                            error={errusercenterb}
                                            helperText={errusercenter}
                                            margin="normal"
                                            required
                                            fullWidth
                                            select
                                            id="outlined-select-district"
                                            label="Center"
                                            value={usercenter}
                                            onChange={(e) =>
                                                setusercenter(e.target.value)
                                            }
                                        // InputProps={{
                                        //   readOnly: true,
                                        // }}
                                        //onChange={handleChange}
                                        >
                                            {filtercenter.map((option) => (
                                                <MenuItem
                                                    key={option.center_id}
                                                    value={option.center_id}
                                                >
                                                    {option.center_name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>

                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div>
                                        <TextField
                                            error={erruseraddressb}
                                            helperText={erruseraddress}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="outlined-select-district"
                                            label="Address"
                                            value={address}
                                            onChange={(e) =>
                                                setaddress(e.target.value)
                                            }
                                        // InputProps={{
                                        //   readOnly: true,
                                        // }}
                                        //onChange={handleChange}
                                        >
                                        </TextField>
                                    </div>

                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div>
                                        <TextField
                                            error={errusermobileb}
                                            helperText={errusermobile}
                                            margin="normal"

                                            fullWidth
                                            id="outlined-select-district"
                                            label="Mobile"
                                            value={mobile}
                                            onChange={(e) =>
                                                setmobile(e.target.value)
                                            }
                                            type="number"
                                            InputLabelProps={{
                                                maxLength: 10,
                                            }}
                                            onInput={(e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                            }}
                                        // InputProps={{
                                        //   readOnly: true,
                                        // }}
                                        //onChange={handleChange}
                                        >
                                        </TextField>
                                    </div>

                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div>
                                        <TextField
                                            error={erruserroleb}
                                            helperText={erruserrole}
                                            margin="normal"
                                            required
                                            fullWidth
                                            select
                                            id="outlined-select-district"
                                            label="Role"
                                            value={userrolenew}
                                            onChange={(e) =>
                                                setuserrolenew(e.target.value)
                                            }
                                        // InputProps={{
                                        //   readOnly: true,
                                        // }}
                                        //onChange={handleChange}
                                        >
                                            {roles.map((option) => (
                                                <MenuItem
                                                    key={option.role_id}
                                                    value={option.role_id}
                                                >
                                                    {option.role_name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>

                                </Grid>
                            </Grid>
                        </form>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleadduserSaveButton}>Save</Button>
                        <Button onClick={handleadduserCancelButton}>Cancel</Button>
                    </DialogActions>
                    <Backdrop
                        sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={backdrop}
                    //onClick={handlebackdropClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Dialog>
                <Snackbar
                    open={opensnack}
                    autoHideDuration={6000}
                    onClose={handleCloseSnack}
                    message={snackmsg}
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                //action={action}
                />
                {/* add user dialog end */}
                {/* session expiration dialog */}
                <Dialog
                    open={opensessiondialog}

                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Session Expired!!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Session has expired..Please Login Again to Continue
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleOKButton}>OK</Button>
                    </DialogActions>
                </Dialog>
                {/* session expiration dialog end */}
                {/* edit user dialog */}
                <Dialog open={openedituserdialog} onClose={handleopenedituserdialogclose}>
                    <DialogTitle>Edit User</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div>
                                    <TextField

                                        margin="normal"
                                        required
                                        fullWidth
                                        disabled
                                        id="outlined-select-district"
                                        label="User Id"
                                        value={userid}
                                    // onChange={(e) =>
                                    //     setusername(e.target.value)
                                    // }
                                    // InputProps={{
                                    //   readOnly: true,
                                    // }}
                                    //onChange={handleChange}
                                    >
                                    </TextField>
                                </div>

                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div>
                                    <TextField
                                        error={errusernameb}
                                        helperText={errusername}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="outlined-select-district"
                                        label="Email"
                                        value={username}
                                        onChange={(e) =>
                                            setusername(e.target.value)
                                        }
                                    // InputProps={{
                                    //   readOnly: true,
                                    // }}
                                    //onChange={handleChange}
                                    >
                                    </TextField>
                                </div>

                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div>
                                    <TextField
                                        error={errfullnameb}
                                        helperText={errfullname}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="outlined-select-district"
                                        label="Full Name"
                                        value={fullname}
                                        onChange={(e) =>
                                            setfullname(e.target.value)
                                        }
                                    // InputProps={{
                                    //   readOnly: true,
                                    // }}
                                    //onChange={handleChange}
                                    >
                                    </TextField>
                                </div>

                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div>
                                    <TextField
                                        error={erruserstateb}
                                        helperText={erruserstate}
                                        margin="normal"
                                        required
                                        fullWidth
                                        select
                                        id="outlined-select-district"
                                        label="State"
                                        value={userstate}
                                        // InputProps={{
                                        //   readOnly: true,
                                        // }}
                                        onChange={handlestatechange}
                                    >
                                        {state.map((option) => (
                                            <MenuItem
                                                key={option.state_id}
                                                value={option.state_id}
                                            >
                                                {option.state_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>

                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div>
                                    <TextField
                                        error={erruserdistrictb}
                                        helperText={erruserdistrict}
                                        margin="normal"
                                        required
                                        fullWidth
                                        select
                                        id="outlined-select-district"
                                        label="District"
                                        value={userdistrict}
                                        onChange={handledistrictchange
                                        }
                                    // InputProps={{
                                    //   readOnly: true,
                                    // }}
                                    //onChange={handleChange}
                                    >
                                        {filterdistrict.map((option) => (
                                            <MenuItem
                                                key={option.district_id}
                                                value={option.district_id}
                                            >
                                                {option.district_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>

                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div>
                                    <TextField
                                        error={errusercenterb}
                                        helperText={errusercenter}
                                        margin="normal"
                                        required
                                        fullWidth
                                        select
                                        id="outlined-select-district"
                                        label="Center"
                                        value={usercenter}
                                        onChange={(e) =>
                                            setusercenter(e.target.value)
                                        }
                                    // InputProps={{
                                    //   readOnly: true,
                                    // }}
                                    //onChange={handleChange}
                                    >
                                        {filtercenter.map((option) => (
                                            <MenuItem
                                                key={option.center_id}
                                                value={option.center_id}
                                            >
                                                {option.center_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>

                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div>
                                    <TextField
                                        error={erruseraddressb}
                                        helperText={erruseraddress}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="outlined-select-district"
                                        label="Address"
                                        value={address}
                                        onChange={(e) =>
                                            setaddress(e.target.value)
                                        }
                                    // InputProps={{
                                    //   readOnly: true,
                                    // }}
                                    //onChange={handleChange}
                                    >
                                    </TextField>
                                </div>

                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div>
                                    <TextField
                                        error={errusermobileb}
                                        helperText={errusermobile}
                                        margin="normal"

                                        fullWidth
                                        id="outlined-select-district"
                                        label="Mobile"
                                        value={mobile}
                                        onChange={(e) =>
                                            setmobile(e.target.value)
                                        }
                                        type="number"
                                        InputLabelProps={{
                                            maxLength: 10,
                                        }}
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                        }}
                                    // InputProps={{
                                    //   readOnly: true,
                                    // }}
                                    //onChange={handleChange}
                                    >
                                    </TextField>
                                </div>

                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleedituserSaveButton}>Save</Button>
                        <Button onClick={handleedituserCancelButton}>Cancel</Button>
                    </DialogActions>
                    <Backdrop
                        sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={backdrop}
                    //onClick={handlebackdropClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Dialog>
                <Snackbar
                    open={opensnack}
                    autoHideDuration={6000}
                    onClose={handleCloseSnack}
                    message={snackmsg}
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                //action={action}
                />
                {/* edit user dialog end */}
                <Snackbar
                    open={opensnack}
                    autoHideDuration={6000}
                    onClose={handleCloseSnack}
                    message={snackmsg}
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                //action={action}
                />
                {/* add user dialog end */}

                <Backdrop
                    sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdrop}
                //onClick={handlebackdropClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    )
}
