import React, { useState, useEffect } from 'react'
import { Cookies } from "react-cookie";
import config from '../config.json'
import NavBarSuperAdmin from '../NavBar/NavBarSuperAdmin'
import NavbarUser from '../NavBar/NavbarUser'
import { useNavigate } from 'react-router-dom'

import { decrypter } from "../Util"

import Banner1 from '../images/banner1.jpg';
import Banner2 from '../images/banner2.jpg';
import Banner3 from '../images/banner3.jpg';
import EditIcon from '@mui/icons-material/Edit';
import ContentCut from '@mui/icons-material/ContentCut';

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Grid } from "@mui/material";
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';


import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography"

import axios from 'axios';

import GlobalStyles from '@mui/material/GlobalStyles';






import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


import EXIF from "exif-js";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import regex from "../regex.json"

import CircularProgressWithLabel from './CircularProgressWithLabel';




const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;

const TITLE = config.TITLE;
const SECRET = config.SECRET;

const XMP = require("xmp-js")

export default function UploadMotifNew() {
    const navigate = useNavigate()
    // let cookiedata = decrypter(cookies.get("sessionid"))


    // let userdata = cookiedata.userdata
    // let jwt = cookiedata.token

    // let role = cookiedata.userdata.user.roles.map(op => op.role_name)

    // let userrole = role[0]

    // let KEY = 'Bearer ' + jwt;

    var yarnList = [
        {
            id: 1,
            value: "Cotton"
        },
        {
            id: 2,
            value: "Silk"
        },
        {
            id: 3,
            value: "Mix"
        }
    ]

    var hookList = [
        {
            id: 1,
            value: "120"
        },
        {
            id: 2,
            value: "200"
        },
        {
            id: 3,
            value: "400"
        }
    ]

    var colorlist = [
        {
            id: 1,
            hex: '#0000FF',
            name: 'Blue'
        },
        {
            id: 2,
            hex: '#FFA500',
            name: 'Orange'
        },
        {
            id: 3,
            hex: '#FF0000',
            name: 'Red'
        },
        {
            id: 4,
            hex: '#008000',
            name: 'Green'
        },
        {
            id: 5,
            hex: '#FF00FF',
            name: 'Magenta'
        },
        {
            id: 6,
            hex: '#EE82EE',
            name: 'Violet'
        },
        {
            id: 7,
            hex: '#FFC0CB',
            name: 'Pink'
        },
        {
            id: 8,
            hex: '#808080',
            name: 'Gray'
        },
        {
            id: 9,
            hex: '#000000',
            name: 'Black'
        }
    ];

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },

        }
    };

    const [categories, setcategories] = useState([])
    const [subcategories, setsubcategories] = useState([])
    const [filtersubcat, setfiltersubcat] = useState([])
    const [weaves, setweaves] = useState([])
    const [designtypes, setdesigntypes] = useState([])
    const [dentingorders, setdentingorders] = useState([])

    const [filename, setFilename] = useState("");
    const [file, setfile] = useState();

    const [preview, setpreview] = useState()
    const [hiddenb, sethiddenb] = useState(true)

    const [xpixel, setxpixel] = useState();
    const [ypixel, setypixel] = useState();
    const [software, setsoftware] = useState();
    const [date, setdate] = useState();

    const [openalertform, setopenalertform] = useState(false)
    const [imagealert, setimagealert] = useState("")
    const [formalert, setformalert] = useState("")

    const [designtype, setdesigntype] = useState(1)
    const [errdesigntype, seterrdesigntype] = useState('')
    const [errdesigntypeb, seterrdesigntypeb] = useState(false)

    const [catid, setcatid] = useState('')
    const [errcatid, seterrcatid] = useState('')
    const [errcatidb, seterrcatidb] = useState(false)

    const [subcatid, setsubcatid] = useState('')
    const [errsubcatid, seterrsubcatid] = useState('')
    const [errsubcatidb, seterrsubcatidb] = useState(false)

    const [height, setheight] = useState('')
    const [errheight, seterrheight] = useState('')
    const [errheightb, seterrheightb] = useState(false)

    const [width, setwidth] = useState('')
    const [errwidth, seterrwidth] = useState('')
    const [errwidthb, seterrwidthb] = useState(false)

    const [hooks, sethooks] = useState('')
    const [errhooks, seterrhooks] = useState('')
    const [errhooksb, seterrhooksb] = useState(false)

    const [ppi, setppi] = useState('')
    const [errppi, seterrppi] = useState('')
    const [errppib, seterrppib] = useState(false)

    const [epi, setepi] = useState('')
    const [errepi, seterrepi] = useState('')
    const [errepib, seterrepib] = useState(false)

    const [color, setcolor] = useState('')
    const [errcolor, seterrcolor] = useState('')
    const [errcolorb, seterrcolorb] = useState(false)

    const [dentinch, setdentinch] = useState('')
    const [errdentinch, seterrdentinch] = useState('')
    const [errdentinchb, seterrdentinchb] = useState(false)

    const [dentorderid, setdentorderid] = useState('')
    const [errdentorderid, seterrdentorderid] = useState('')
    const [errdentorderidb, seterrdentorderidb] = useState(false)

    const [weaveid, setweaveid] = useState([])
    const [errweaveid, seterrweaveid] = useState('')
    const [errformcontrolweaveb, seterrformcontrolweaveb] = useState(false)
    const [errformhelperweaveb, seterrformhelperweaveb] = useState(false)

    const [erryarnb, seterryarnb] = useState(false)
    const [erryarn, seterryarn] = useState("")
    const [yarn, setyarn] = useState("")

    const [opensnack, setopensnack] = useState(false);
    const [snackmsg, setsnackmsg] = useState();

    const [backdrop, setbackdrop] = useState(false)
    const [opensessiondialog, setopensessiondialog] = useState(false)
    const [backdropstats, setbackdropstats] = useState(false)
    const [percent, setpercent] = useState()

    const [opensubmitdialog, setopensubmitdialog] = useState(false)






    const imageoutline = { display: 'flex', justifyContent: 'center', border: '2px solid #e8d4e0', padding: 5 }



    // useEffect(() => {

    //     const headers = {
    //         Authorization: KEY
    //     };

    //     const getcategory = axios.post(BASE_URL + '/getAllCategory', {}, { headers: headers })
    //     const getsubcategory = axios.post(BASE_URL + '/getAllSubCategory', {}, { headers: headers })
    //     const getweave = axios.post(BASE_URL + '/getAllWeave', {}, { headers: headers })
    //     const getdesigntype = axios.post(BASE_URL + '/getAllDesignType', {}, { headers: headers })
    //     const getdentingorder = axios.post(BASE_URL + '/getAllDentingOrder', {}, { headers: headers })

    //     Promise.all([getcategory, getsubcategory, getweave, getdesigntype, getdentingorder])
    //         .then(function (responses) {

    //             //setunmapped(responses[0].data.Beneficiaries)
    //             setcategories(responses[0].data.Category)
    //             setsubcategories(responses[1].data.SubCategory)
    //             setweaves(responses[2].data.Weave)
    //             setdesigntypes(responses[3].data.DesignType)
    //             setdentingorders(responses[4].data.DentingOrder)

    //         }).catch(function (errors) {

    //         });




    // }, [])



    useEffect(() => {
        if (!file) {
            setpreview(undefined)
            sethiddenb(true)
            return
        }

        const objectUrl = URL.createObjectURL(file)
        sethiddenb(false)
        setpreview(objectUrl)


        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [file])

    const handlefilechange = (e) => {
        //const [files] = e.target.files;
        //////console.log("data", files);
        setFilename(e.target.files[0].name);
        setfile(e.target.files[0]);

        var reader = new FileReader()
        reader.onload = async (e) => {
            let image = new Image()
            image.src = e.target.result
            await image.decode()

            ////console.log("imginfo", image)
            ////console.log("imginfowidth", image.width)
            ////console.log("imginfoheight", image.height)
            // now we can:
            const width = image.width
            const height = image.height
            //setwidth(width)
            //setheight(height)

            let xmp = new XMP(e.target.result),
                raw = xmp.find(),
                parsed = xmp.parse();

            ////console.log("xmpfind", xmp.find())
            ////console.log("xmpparse", xmp.parse())

            setwidth(width)
            setheight(height)
        }
        reader.readAsDataURL(e.target.files[0])

        EXIF.getData(e.target.files[0], function () {
            var exifData = EXIF.getAllTags(this);
            if (exifData) {
                //////console.log("exifdata", JSON.stringify(exifData, null, "\t"));
                // setwidth(EXIF.getTag(this, "PixelXDimension"))
                // setheight(EXIF.getTag(this, "PixelYDimension"))
                // setsoftware(EXIF.getTag(this, "Software"))
                // setdate(EXIF.getTag(this, "DateTime"))
                //////console.log(EXIF.getTag(this, "Orientation"));
                setwidth(EXIF.getTag(this, "PixelXDimension"))
                setheight(EXIF.getTag(this, "PixelYDimension"))
            } else {
                //////console.log("No EXIF data found in image '" + e.target.files[0].name + "'.");
            }
        });

        if (e.target.files[0] > 2097152) {
            setopenalertform(true)
            setformalert("Max Size 2 MB allowed")

        } else {
            setopenalertform(false)
            setformalert("")
        }

        //setFilename(e.target.files);
    };
    ////console.log("data from file value", file);

    const handlecatchange = (e) => {
        setcatid(e.target.value)
        setfiltersubcat(subcategories.filter(op => op.category_id === e.target.value))
    }

    const handlesubmit = () => {


        if (validation()) {

            setopensubmitdialog(true)

        }

    }

    const handleSubmitbuttonModal = () => {

        setopensubmitdialog(false)
        ////console.log("valid")
        // const headers = {
        //     Authorization: KEY,

        // };

        //setbackdrop(true)
        setbackdropstats(true)


        const uploadjson = {

            height: height,
            width: width

        }

        ////console.log("upload", JSON.stringify(uploadjson))

        const data = new FormData();
        data.append("image", file);
        //data.append("weaveid", new Blob([JSON.stringify(weaveids)], { type: 'application/json' }));
        data.append("motifupload", new Blob([JSON.stringify(uploadjson)], { type: 'application/json' }))


        axios
            .post(BASE_URL + "/motif/uploadMotif",
                data,
                {
                    withCredentials: true,
                    onUploadProgress: (event) => {
                        //console.log("prog event: ", event)
                        setpercent(Math.ceil(event.progress * 100))
                    }
                })
            .then(function (response) {
                ////console.log(response);
                if (response.data.status === "OK" && response.status == 200) {
                    //setbackdrop(false)
                    setbackdropstats(false)
                    setopensnack(true);
                    setsnackmsg("Motif Added Successfully")

                    // setadddistrictmodal(false)

                    setTimeout(function () {
                        window.location.reload(false);
                    }, 3000);

                    // setrefreshdata(count+1)






                }
                else {
                    setbackdropstats(false)
                    setbackdrop(false)
                    setopensnack(true);
                    setsnackmsg("Motif Addition Failed")
                }
            })
            .catch(function (errors) {



                if (errors.response.status == 401 || errors.response.status == 403) {


                    setbackdrop(false)

                    setopensessiondialog(true)



                } else {

                    setbackdropstats(false)
                    setbackdrop(false)
                    setopensnack(true);
                    setsnackmsg("Motif Addition Failed")

                }

            });


    }
    const handleCancelButtonModal = () => {
        setopensubmitdialog(false)
    }

    const handleCloseSnack = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setopensnack(false);

    }

    const handleOKButton = () => {
        navigate('/')
        cookies.remove("sessionid")
    }


    function validation() {
        let isvalid = true;

        if (file === undefined) {
            setopenalertform(true)
            setformalert("Please Select File")
            isvalid = false;
        } else if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png" || file.type === "image/bmp") {
            setopenalertform(false)
            setformalert("")
        } else {
            setopenalertform(true)
            setformalert("Please Select Valid File")
            isvalid = false;
        }

        if (file.size > 2097152) {
            setopenalertform(true)
            setformalert("Max Size 2 MB allowed")
            isvalid = false

        } else {
            setopenalertform(false)
            setformalert("")
        }

        if (height === "") {
            seterrheightb(true)
            seterrheight("Height Cannot be Empty")
            isvalid = false

        } else {
            seterrheightb(false)
            seterrheight("")
        }

        if (width === "") {
            seterrwidthb(true)
            seterrwidth("Width Cannot be Empty")
            isvalid = false

        } else {
            seterrwidthb(false)
            seterrwidth("")
        }
        return isvalid;
    }


    return (
        <>
            <div>
                <NavbarUser
                    title={TITLE}

                />

            </div>
            <GlobalStyles styles={{
                "& .MuiTextField-root": {

                    "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                        color: "red",
                        "&::after": {

                        }
                    }
                }
            }} />

            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={Banner1} className="d-block w-100" alt="Banner1" />
                            </div>
                        </div>
                        {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button> */}
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className='container'>
                <h1>
                    <div className="abc text-black text-center col 10 p-1 mt-2">
                        <strong><h2> Add Motifs </h2></strong>
                    </div>
                </h1>
                <Box
                    component="form"
                    sx={{
                        marginTop: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    margin="normal"

                                    required
                                    component="label"
                                    variant="outlined"
                                    sx={{ mt: 2 }}


                                >
                                    Select Image (jpg, jpeg ,png or bmp )

                                    <input
                                        type="file"
                                        accept=".jpg,.jpeg,.png,.bmp"
                                        name={file}
                                        hidden
                                        onChange={handlefilechange}
                                    />
                                </Button>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10, marginTop: 10 }}>
                                {filename}
                            </div>


                        </Grid>
                        <Grid item xs={12}>
                            <center>
                                <div style={imageoutline}>
                                    <img src={preview} hidden={hiddenb} alt="" width="10%" height="10%" />
                                </div>
                            </center>

                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={openalertform}>
                                <Alert severity="error"
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setopenalertform(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    sx={{ mb: 2 }}
                                >
                                    {formalert}
                                </Alert>
                            </Collapse>
                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                <TextField
                                    error={errheightb}
                                    helperText={errheight}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-number"
                                    type="number"
                                    label="Height (In Pixels)"
                                    value={height}
                                    onChange={(e) =>
                                        setheight(e.target.value)
                                        //setdistrict(centers.filter(op => op.center_dist_id === district))

                                    }
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                // InputProps={{
                                //   readOnly: true,
                                // }}
                                //onChange={handleChange}
                                >
                                </TextField>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                <TextField
                                    error={errwidthb}
                                    helperText={errwidth}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-number"
                                    type="number"
                                    label="Width (In Pixels)"
                                    value={width}
                                    onChange={(e) =>
                                        setwidth(e.target.value)
                                        //setdistrict(centers.filter(op => op.center_dist_id === district))

                                    }
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}

                                // InputProps={{
                                //   readOnly: true,
                                // }}
                                //onChange={handleChange}
                                >
                                </TextField>
                            </div>
                        </Grid>
                        <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
                            <Button variant="contained" onClick={handlesubmit}>
                                Submit
                            </Button>
                        </Grid>

                    </Grid>
                </Box>
                <Backdrop
                    sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdrop}
                //onClick={handlebackdropClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {/* <Backdrop
                    sx={{ color: '#f5f2f2', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdropstats}
                
                >
                    <CircularProgressWithLabel value={percent} />
                </Backdrop> */}
                <Dialog
                    open={backdropstats}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Submitting.... Please Wait!!"}
                    </DialogTitle>
                    <DialogContent sx={{ alignItems: 'center', justifyContent: 'center', display: 'grid' }}>
                        <CircularProgressWithLabel value={percent} />
                    </DialogContent>
                </Dialog>
                {/* session expiration dialog */}
                <Dialog
                    open={opensessiondialog}

                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Session Expired!!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Session has expired..Please Login Again to Continue
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleOKButton}>OK</Button>
                    </DialogActions>
                </Dialog>
                {/* session expiration dialog end */}
                <Snackbar
                    open={opensnack}
                    autoHideDuration={6000}
                    onClose={handleCloseSnack}
                    message={snackmsg}
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                //action={action}
                />
                {/* Submit Confirmation dialog */}
                <Dialog open={opensubmitdialog}>
                    <DialogTitle>Submit Motiff</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to Submit this Motif?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSubmitbuttonModal}>Submit</Button>
                        <Button onClick={handleCancelButtonModal}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                {/* Submit Confirmation dialog end */}

            </div>

        </>
    )
}
